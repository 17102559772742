import { VEHICLE_VAT } from "@finn/ua-constants";
export var getKmPackagePriceWithoutVat = function(price, isForBusiness) {
    return isForBusiness ? Math.round(Number(price) / VEHICLE_VAT) : Number(price);
};
export var isOnStock = function(vehicle) {
    return !!vehicle.product_stock_total;
};
// Available distance options in miles and kilometers.
export var mileageOptions = [
    850,
    1000,
    1250,
    1500
];
export var kmOptions = [
    500,
    1000,
    1500,
    2000,
    2500,
    3000,
    4000,
    5000
];
