import { Features, useIsFeatureEnabled } from '@finn/ua-featureflags';
import { isAllConsentTrue, UCConsentEvent } from '@finn/ui-utils';
import React, { useEffect } from 'react';

export const FullStory: React.FunctionComponent = () => {
  const isFullStoryEnabled = useIsFeatureEnabled(Features.FullStory);

  useEffect(() => {
    window.addEventListener('afterConsent', (consent: UCConsentEvent) => {
      const detail = { ...consent.detail };
      if (detail?.type === 'explicit' || isAllConsentTrue(detail)) {
        if (isFullStoryEnabled && !!detail.Fullstory === true) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({ event: 'FullStoryCookie' });
        }
      }
    });
  }, [isFullStoryEnabled]);

  return null;
};
