import { isMobileApp } from "@finn/ui-utils";
export var baseUrl = "api/self-service/api";
export var REGION;
(function(REGION) {
    REGION["DE"] = "de";
    REGION["US"] = "us";
})(REGION || (REGION = {}));
export var APIVersions;
(function(APIVersions) {
    APIVersions["V2"] = "v2";
    APIVersions["V3"] = "v3";
})(APIVersions || (APIVersions = {}));
export var DeprecatedOrigin;
(function(DeprecatedOrigin) {
    DeprecatedOrigin["ACCOUNT"] = "account";
    DeprecatedOrigin["CHECKOUT"] = "checkout";
    DeprecatedOrigin["CHECKOUT_THANK_YOU"] = "checkout_thank_you";
    DeprecatedOrigin["CUSTOMER_PORTAL"] = "customer_portal";
    DeprecatedOrigin["EMAIL"] = "email";
    DeprecatedOrigin["CHECKOUT_SIGNUP"] = "checkout_signup";
})(DeprecatedOrigin || (DeprecatedOrigin = {}));
export var generateUrl = function() {
    var version = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : APIVersions.V3, endpoint = arguments.length > 1 ? arguments[1] : void 0, region = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : REGION.DE;
    var origin = window.origin;
    if (!origin) {
        return null;
    }
    var urlParts = [
        origin
    ];
    if (version === APIVersions.V3) {
        urlParts.push(region);
    }
    urlParts.push(baseUrl, version, endpoint);
    return urlParts.join("/");
};
export var handleCSSError = function(router, status) {
    if (isMobileApp()) {
        return;
    }
    if (status === 403) {
        router.push({
            pathname: "/",
            query: "modal=unauthorised_access"
        });
    } else if (status >= 500) {
        router.push({
            pathname: "/",
            query: "modal=server_side_error"
        });
    } else {
        router.push("/");
    }
};
