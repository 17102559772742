import { capitalizeFirstLetter } from "@finn/ui-utils";
export var humanize = function(code) {
    if (!code) {
        return undefined;
    }
    switch(code){
        case "email":
            return "Email";
        case "password":
            return "Password";
        case "firstname":
        case "contact.firstname":
            return "First Name";
        case "lastname":
        case "contact.lastname":
            return "Last Name";
        case "birthday":
        case "contact.birthday":
            return "Birthday";
        case "phone":
        case "contact.phone":
            return "Phone";
        case "street":
        case "contact.street":
            return "Street";
        case "contact.housenumber":
        case "housenumber":
            return "House Number";
        case "zipcode":
        case "contact.zipcode":
            return "Zip Code";
        case "city":
        case "contact.city":
            return "City";
        case "extra":
        case "contact.extra":
            return "Address Extension";
        case "deliveryAddress.firstname":
            return "Delivery First Name";
        case "deliveryAddress.lastname":
            return "Delivery Last Name";
        case "deliveryAddress.street":
            return "Delivery Street";
        case "deliveryAddress.housenumber":
            return "Delivery House Number";
        case "deliveryAddress.zipcode":
            return "Delivery Zip Code";
        case "deliveryAddress.city":
            return "Delivery City";
        case "deliveryAddress.extra":
            return "Delivery Address Extension";
        case "b2bContact.organizationType":
            return "Organization Type";
        case "b2bContact.company":
            return "Company Name";
        case "preferences.storeData":
            return "Store Data";
        case "preferences.emailSubscription":
            return "Newsletter";
        case "preferences.sameDeliveryAddress":
            return "Same Delivery Address";
        case "code":
            return "Coupon Code";
        default:
            return capitalizeFirstLetter(code || "");
    }
};
