import { notifyAboutStripeModals } from "./stripe";
export var setupScrollListener = function(emitEvent) {
    if (!MutationObserver) {
        return;
    }
    // we are listening for a moment when some library adding overflow hidden
    // to body (for example modals) and share this info with native app
    var observer = new MutationObserver(function(mutations) {
        mutations.forEach(function() {
            emitEvent({
                type: "scroll",
                value: document.body.style.overflow !== "hidden"
            });
            notifyAboutStripeModals(emitEvent);
        });
    });
    observer.observe(document.body, {
        attributes: true,
        attributeFilter: [
            "style"
        ]
    });
};
