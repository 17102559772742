import { isServer } from "./server";
export var isMobileApp = function() {
    if (isServer()) {
        return undefined;
    }
    if ("object" === "undefined") {
        return false;
    }
    return !!window.nativeAppSDK;
};
