import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import { getGlobalObject } from "../helpers/globalObject";
export var setupLogging = function(emitEvent) {
    var globalObject = getGlobalObject();
    var originalConsoleLog = globalObject.console.log;
    var originalConsoleError = globalObject.console.error;
    var originalConsoleWarn = globalObject.console.warn;
    var originalConsoleInfo = globalObject.console.info;
    // /de-DE/mobile/checkout/payment/386674901/11943934902/72c74682cba79de2c2bad6e7597dcc69
    globalObject.console.log = function log() {
        for(var _len = arguments.length, obj = new Array(_len), _key = 0; _key < _len; _key++){
            obj[_key] = arguments[_key];
        }
        emitEvent({
            type: "log",
            message: JSON.stringify(obj)
        });
        originalConsoleLog.apply(void 0, _to_consumable_array(obj));
    };
    globalObject.console.error = function log() {
        for(var _len = arguments.length, obj = new Array(_len), _key = 0; _key < _len; _key++){
            obj[_key] = arguments[_key];
        }
        emitEvent({
            type: "log:error",
            message: JSON.stringify(obj)
        });
        originalConsoleError.apply(void 0, _to_consumable_array(obj));
    };
    globalObject.console.warn = function log() {
        for(var _len = arguments.length, obj = new Array(_len), _key = 0; _key < _len; _key++){
            obj[_key] = arguments[_key];
        }
        emitEvent({
            type: "log:warn",
            message: JSON.stringify(obj)
        });
        originalConsoleWarn.apply(void 0, _to_consumable_array(obj));
    };
    globalObject.console.info = function log() {
        for(var _len = arguments.length, obj = new Array(_len), _key = 0; _key < _len; _key++){
            obj[_key] = arguments[_key];
        }
        emitEvent({
            type: "log:info",
            message: JSON.stringify(obj)
        });
        originalConsoleInfo.apply(void 0, _to_consumable_array(obj));
    };
};
