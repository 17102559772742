// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
export var addSentryRenderPageBreadcrumb = function() {
    for(var _len = arguments.length, _ = new Array(_len), _key = 0; _key < _len; _key++){
        _[_key] = arguments[_key];
    }
// noop
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
export var addSentryScopeTag = function() {
    for(var _len = arguments.length, _ = new Array(_len), _key = 0; _key < _len; _key++){
        _[_key] = arguments[_key];
    }
// noop
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
export var addSentryScopeUser = function(_) {
// noop
};
export var captureMessage = function() {
    for(var _len = arguments.length, msg = new Array(_len), _key = 0; _key < _len; _key++){
        msg[_key] = arguments[_key];
    }
    console.error(msg);
};
export var captureException = function(error) {
    console.error(error);
};
