import { SERVER_DATE_FORMAT } from "@finn/ua-constants";
import { addBusinessDays } from "@finn/ui-utils";
import dayjs from "dayjs";
import { useRouter } from "next/router";
import { useEffect, useMemo } from "react";
import { useSWRConfig } from "swr";
import { useRemoteData } from "./useRemoteData";
var BufferWeeks = 4;
var WorkDaysToAdd = 4;
export var getSwapDateRange = function(endDate) {
    var startOfWeek = endDate.startOf("week");
    var endOfWeek = dayjs(addBusinessDays(startOfWeek, WorkDaysToAdd));
    var startDayWithBuffer = startOfWeek.subtract(BufferWeeks, "week");
    var isStartDayInPast = startDayWithBuffer.isBefore(dayjs().add(1, "day"));
    return {
        available_from: isStartDayInPast ? dayjs().add(1, "day").format(SERVER_DATE_FORMAT) : startDayWithBuffer.format(SERVER_DATE_FORMAT),
        available_to: endOfWeek.format(SERVER_DATE_FORMAT)
    };
};
export var parseSubscriptionEndDate = function(subscription) {
    var endDateInSubscription = (subscription === null || subscription === void 0 ? void 0 : subscription.preferred_return_date) || (subscription === null || subscription === void 0 ? void 0 : subscription.return_date) || (subscription === null || subscription === void 0 ? void 0 : subscription.end_date);
    var endDate = dayjs(endDateInSubscription);
    return {
        hasEndDate: !!endDateInSubscription,
        parsedEndDate: dayjs(endDate, {
            format: SERVER_DATE_FORMAT
        }),
        parsedEndDateString: endDate.format(SERVER_DATE_FORMAT)
    };
};
var useCachedDeal = function(id) {
    var ref, ref1, ref2;
    var cache = useSWRConfig().cache;
    var subscriptions = (ref = cache.get("deals")) === null || ref === void 0 ? void 0 : (ref1 = ref.data) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.data) === null || ref2 === void 0 ? void 0 : ref2.data;
    return useMemo(function() {
        return subscriptions === null || subscriptions === void 0 ? void 0 : subscriptions.find(function(item) {
            return item.id === id;
        });
    }, [
        id,
        subscriptions
    ]);
};
// we use function as useRemoteData may pass some params for us
var dealEndpoint = function(id) {
    return function() {
        return "portal/api/bff/deals/".concat(id);
    };
};
export var useDeal = function(id, onError) {
    var cacheDeal = useCachedDeal(id);
    var router = useRouter();
    var ref = useRemoteData(cacheDeal || !id || id === "no-deal" ? null : "deal/".concat(id), dealEndpoint(id)), data = ref.data, mutate = ref.mutate, status = ref.status, httpStatus = ref.httpStatus;
    useEffect(function() {
        if (router.isReady && onError && httpStatus && status === "error") {
            onError(httpStatus);
        }
    }, [
        httpStatus,
        onError,
        router,
        status
    ]);
    return useMemo(function() {
        return {
            mutate: mutate,
            data: data || cacheDeal,
            status: cacheDeal ? "success" : status
        };
    }, [
        mutate,
        data,
        cacheDeal,
        status
    ]);
};
