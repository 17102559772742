import _instanceof from "@swc/helpers/src/_instanceof.mjs";
export var validateData = function(response) {
    var data = response.objects[0];
    // For now, only validate landing pages
    if (data.type !== "web-pages") {
        return;
    }
    if (!data.metadata.header) {
        throw new Error("No header object");
    }
    if (_instanceof(data.metadata.header, String)) {
        throw new Error("Not a valid header object");
    }
    if (!data.metadata.header.metadata.links) {
        throw new Error("No links in header");
    }
    if (_instanceof(data.metadata.modules, String)) {
        throw new Error("No object");
    }
    if (data.metadata.modules.length === 0) {
        throw new Error("No modules");
    }
    data.metadata.modules.forEach(function(module) {
        if (!module.metadata) {
            throw new Error("Invalid module");
        }
    });
    // Landing pages should have a SEO Header
    if (data.metadata.seo_header) {
        if (_instanceof(data.metadata.seo_header, String)) {
            throw new Error("Not a valid seo header");
        }
        if (!data.metadata.seo_header.metadata) {
            throw new Error("Not a valid seo header");
        }
    }
    if (!data.metadata.footer) {
        throw new Error("No footer object");
    }
    if (_instanceof(data.metadata.footer, String)) {
        throw new Error("Not a valid header object");
    }
    if (!data.metadata.footer.slug.startsWith("b2b")) {
        if (!data.metadata.footer.metadata.columns) {
            throw new Error("No columns in footer");
        }
    }
};
