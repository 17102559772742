import { config } from '@finn/ui-utils';
import { useCallback, useState } from 'react';

const useCosmicS3 = () => {
  const [isCosmicS3] = useState(config.COSMIC_USE_S3 === 'yes');
  const toggleCosmicS3 = useCallback(() => {
    alert(
      "Can't set using browser as this is based on env variable and not with cookies to support getStaticProps.\nSet COSMIC_USE_S3=yes (or no) in your .env variable"
    );
  }, []);

  return { isCosmicS3, toggleCosmicS3 };
};

export default useCosmicS3;
