export var tracking = function(props) {
    return {
        getTrackingProps: function() {
            return {
                app_type: "native",
                app_locale: props.locale,
                app_platform: props.platform,
                app_version: props.version,
                app_build_number: props.buildNumber,
                app_codepush_id: props.codepushId,
                app_device_model: props.deviceModel,
                app_device_name: props.deviceName,
                app_device_type: props.deviceType,
                app_device_network: props.deviceNetwork,
                app_os: props.os,
                app_os_version: props.osVersion,
                app_screen_width: props.screenWidth,
                app_screen_height: props.screenHeight,
                app_feature_flags: props.features,
                // we also mimic native app behaviour
                locale: props.locale,
                app: {
                    build: props.buildNumber,
                    version: props.version,
                    codepushId: props.codepushId
                },
                device: {
                    model: props.deviceModel,
                    name: props.deviceName,
                    type: props.platform
                },
                network: {
                    cellular: props.deviceNetwork === "cellular",
                    wifi: props.deviceNetwork === "wifi"
                },
                os: {
                    name: props.os,
                    version: props.osVersion
                },
                screen: {
                    height: props.screenHeight,
                    width: props.screenWidth
                }
            };
        },
        setUserTrackingId: function(id) {
            var CHECK_ANALYTICS_TIMER = 50;
            var intervalId = setInterval(function() {
                var ref;
                if ((ref = window.analytics) === null || ref === void 0 ? void 0 : ref.user) {
                    var ref1, ref2;
                    (ref1 = window.analytics.user()) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.anonymousId) === null || ref2 === void 0 ? void 0 : ref2.call(ref1, id);
                    clearInterval(intervalId);
                }
            }, CHECK_ANALYTICS_TIMER);
        }
    };
};
