import _define_property from "@swc/helpers/src/_define_property.mjs";
import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import { VehicleOfferType } from "@finn/ui-utils";
import { z } from "zod";
import { FilterKey, SortKey, VehicleViewKey } from "./constants";
function arrayOf(zodType) {
    return zodType.or(z.array(zodType)).transform(function(value) {
        return Array.isArray(value) ? value : [
            value
        ];
    });
}
function date() {
    return z.string().regex(/\d{4}-\d{2}-\d{2}/);
}
var _obj;
var FilterValuesObjectSchema = z.object((_obj = {}, _define_property(_obj, FilterKey.BRANDS, arrayOf(z.string())), _define_property(_obj, FilterKey.CAR_TYPES, arrayOf(z.string())), _define_property(_obj, FilterKey.GEARSHIFTS, arrayOf(z.string())), _define_property(_obj, FilterKey.MODELS, arrayOf(z.string())), _define_property(_obj, FilterKey.FUELS, arrayOf(z.string())), _define_property(_obj, FilterKey.COLORS, arrayOf(z.string())), _define_property(_obj, FilterKey.TERMS, arrayOf(z.coerce.number().positive())), _define_property(_obj, FilterKey.MIN_PRICE, z.coerce.number().positive()), _define_property(_obj, FilterKey.MAX_PRICE, z.coerce.number().positive()), _define_property(_obj, FilterKey.MIN_PRICE_MSRP, z.coerce.number().positive()), _define_property(_obj, FilterKey.MAX_PRICE_MSRP, z.coerce.number().positive()), _define_property(_obj, FilterKey.SORT_BY_POPULARITY, z.coerce.boolean()), _define_property(_obj, FilterKey.HAS_HITCH, z.coerce.boolean()), _define_property(_obj, FilterKey.IS_FOR_BUSINESS, z.coerce.boolean()), _define_property(_obj, FilterKey.IS_YOUNG_DRIVER, z.coerce.boolean()), _define_property(_obj, FilterKey.AVAILABLE_FROM, date()), _define_property(_obj, FilterKey.AVAILABLE_TO, date()), _define_property(_obj, FilterKey.SORT, z.enum([
    SortKey.DEFAULT,
    SortKey.ASC,
    SortKey.DESC,
    SortKey.AVAILABILITY, 
])), _define_property(_obj, FilterKey.OFFER_TYPE, z.enum([
    VehicleOfferType.SUBSCRIPTION
])), _define_property(_obj, FilterKey.VIEW, z.enum([
    VehicleViewKey.AVAILABLE,
    VehicleViewKey.AVAILABLE_AND_COMING_SOON,
    VehicleViewKey.COMING_SOON,
    VehicleViewKey.DISPLAYED_CARS, 
])), _define_property(_obj, FilterKey.RETENTION, z.enum([
    FilterKey.RETENTION
])), _define_property(_obj, FilterKey.HAS_DEALS, z.coerce.boolean()), _define_property(_obj, FilterKey.PRODUCT_GROUP, arrayOf(z.string())), _define_property(_obj, FilterKey.HAS_RETENTION_DEALS, z.coerce.boolean()), _define_property(_obj, FilterKey.FEATURES, arrayOf(z.string())), _define_property(_obj, FilterKey.TOTAL_RESULTS, z.coerce.number().positive()), _obj)).partial().nullable();
export function parseFilterValues(input) {
    var parsed = {};
    var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
    try {
        for(var _iterator = Object.entries(input)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
            var _value = _sliced_to_array(_step.value, 2), filterKey = _value[0], filterValue = _value[1];
            var result = FilterValuesObjectSchema.safeParse(_define_property({}, filterKey, filterValue));
            if (!result.success || !result.data) {
                continue;
            }
            var parsedValue = result.data[filterKey];
            if (!parsedValue || Array.isArray(parsedValue) && parsedValue.length === 0 || typeof parsedValue === "object" && Object.keys(parsedValue).length === 0) {
                continue;
            }
            parsed[filterKey] = parsedValue;
        }
    } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
    } finally{
        try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
            }
        } finally{
            if (_didIteratorError) {
                throw _iteratorError;
            }
        }
    }
    return parsed;
}
