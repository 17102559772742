import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
export var notifyAboutStripeModals = function(emitEvent) {
    var stripeIframes = _to_consumable_array(document.querySelectorAll("iframe")).filter(function(iframe) {
        var ref, ref1;
        return (iframe === null || iframe === void 0 ? void 0 : (ref = iframe.src) === null || ref === void 0 ? void 0 : ref.includes("stripe")) && (iframe === null || iframe === void 0 ? void 0 : (ref1 = iframe.name) === null || ref1 === void 0 ? void 0 : ref1.includes("Frame"));
    });
    emitEvent({
        type: "event:stripe_modal",
        value: stripeIframes.length > 0
    });
};
