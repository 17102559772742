export var CheckoutStep;
(function(CheckoutStep) {
    CheckoutStep["CART"] = "cart";
    CheckoutStep["CONTACT"] = "contact";
    CheckoutStep["PAYMENT"] = "payment";
    CheckoutStep["CONFIRMATION"] = "confirmation";
    CheckoutStep["PROLONGATION"] = "prolongation";
    CheckoutStep["CREDITSCORE"] = "creditscore";
    CheckoutStep["THANK_YOU"] = "thank_you";
})(CheckoutStep || (CheckoutStep = {}));
var checkoutSteps = [
    CheckoutStep.CART,
    CheckoutStep.CONTACT,
    CheckoutStep.PAYMENT,
    CheckoutStep.CONFIRMATION,
    CheckoutStep.PROLONGATION,
    CheckoutStep.CREDITSCORE,
    CheckoutStep.THANK_YOU, 
];
export var getSlugsWithoutStep = function(slugs) {
    if (!slugs) {
        return [];
    }
    if (typeof slugs === "string") {
        slugs = slugs.split("/");
    }
    if (checkoutSteps.includes(slugs[0])) {
        return slugs.slice(1);
    }
    return slugs;
};
