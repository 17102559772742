import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import dayjs from "dayjs";
import * as German from "dayjs/locale/de";
import * as English from "dayjs/locale/en";
import CustomParseFormat from "dayjs/plugin/customParseFormat";
import isBetween from "dayjs/plugin/isBetween";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { getHolidays } from "feiertagejs";
dayjs.extend(isBetween);
dayjs.extend(CustomParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
// 1st August 2021 => 01.08.2021
export var COMMON_DATE_FORMAT = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric"
};
// 1st August 2021 => 1. August
export var DAY_LONG_MONTH_FORMAT = {
    day: "numeric",
    month: "long"
};
// 1st August 2021 => 1. August 2021
export var DAY_LONG_MONTH_YEAR_FORMAT = {
    day: "numeric",
    month: "long",
    year: "numeric"
};
// 1st August 2021 => August 2021
export var LONG_MONTH_YEAR_FORMAT = {
    month: "long",
    year: "numeric"
};
// 1st August 2021 => 1.Aug.2021
export var DAY_SHORT_MONTH_YEAR_FORMAT = {
    month: "short",
    day: "numeric",
    year: "numeric"
};
var getDateLocale = function(lang) {
    return lang === "de" ? German : English;
};
var addSeconds = function(date, seconds) {
    return dayjs(date).add(seconds, "second").toDate();
};
export var isBusinessDay = function(date) {
    var workingWeekdays = [
        1,
        2,
        3,
        4,
        5
    ];
    if (workingWeekdays.includes(dayjs(date).day())) return true;
    return false;
};
export var isWeekend = function(date) {
    return !isBusinessDay(date);
};
var toUTC = function(date) {
    return dayjs(date).add(-date.getTimezoneOffset(), "minute").toDate();
};
export var range = function(fromDate, toDate) {
    var iterateFn = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : addSeconds, interval = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : 1;
    var result = [];
    var currentDate = fromDate;
    while(currentDate <= toDate){
        result.push(currentDate);
        currentDate = iterateFn(currentDate, interval);
    }
    return result;
};
export var getGermanDateFormat = function(date) {
    return dayjs(date).format("DD.MM.YYYY");
};
export var fromEuropeFormatToDate = function(value) {
    return dayjs(value, "DD.MM.YYYY").toDate();
};
export var reverseAbilipayDate = function(date) {
    return dayjs(date, "DD.MM.YYYY").toDate();
};
export var parseUTCDate = function(date) {
    return dayjs(date, "YYYY-MM-DD").toDate();
};
// Get UTC date and ignore the hours
export var getUTCDate = function(date) {
    var format = "YYYY-MM-DD";
    var formattedDate = dayjs(date).format(format);
    var finalDate = toUTC(dayjs(formattedDate, format).toDate());
    return finalDate.toUTCString();
};
// Get the Diff between 2 dates in ms
export var dateDiff = function(firstDate, secondDate) {
    // Day in ms
    var day = 1000 * 60 * 60 * 24;
    var firstDateMs = firstDate.getTime();
    var secondDateMs = secondDate.getTime();
    var diff = secondDateMs - firstDateMs;
    return Math.round(diff / day);
};
export var getAgeInYears = function(birthday) {
    return dayjs().diff(birthday, "year");
};
export var getCurrentMonthInShort = function() {
    var lang = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : "de";
    try {
        return dayjs().locale(getDateLocale(lang)).format("MMM");
    } catch (e) {
        console.error(e);
        return "";
    }
};
export var getCurrentYear = function() {
    return dayjs().year();
};
export var getDateMonthWeekends = function(date) {
    var monthDays = dayjs(date).daysInMonth();
    var weekends = [];
    for(var i = 1; i <= monthDays; i++){
        var dayInMonth = new Date(date.getFullYear(), date.getMonth(), i);
        if (isWeekend(dayInMonth)) {
            weekends.push(dayInMonth);
        }
    }
    return weekends;
};
export var formatDate = function(date, dateFormat) {
    var lang = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : "de";
    try {
        return dayjs(date).locale(getDateLocale(lang)).format(dateFormat);
    } catch (e) {
        console.error(e);
        return "";
    }
};
export var getMinMaxAvailableDate = function(carHandoverAvailability) {
    var minDate = new Date();
    var maxDate = new Date();
    var availableDates = Object.keys(carHandoverAvailability).map(function(date) {
        return dayjs(date).toDate().getTime();
    });
    if (availableDates.length) {
        var earliestAvailableDate = new Date(Math.min.apply(null, availableDates));
        var latestAvailableDate = new Date(Math.max.apply(null, availableDates));
        minDate = earliestAvailableDate;
        maxDate = latestAvailableDate;
    }
    return {
        minDate: minDate,
        maxDate: maxDate
    };
};
export var isWithinRange = function(date, start, end) {
    return date >= start && date <= end;
};
export var addBusinessDays = function(date, number) {
    var numericDirection = number < 0 ? -1 : 1;
    var currentDay = dayjs(date);
    var daysRemaining = Math.abs(number);
    while(daysRemaining > 0){
        currentDay = currentDay.add(numericDirection, "d");
        if (isBusinessDay(currentDay)) daysRemaining -= 1;
    }
    return currentDay.toDate();
};
export var getAge = function(birthday) {
    return dayjs(new Date()).diff(birthday, "year", true);
};
export var toUniversalTime = function(date) {
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
};
export var isPublicHolidayDE = function(date) {
    var formattedDate = dayjs(date).format("YYYY-MM-DD");
    var year = dayjs(formattedDate).get("year");
    var publicHolidaysDE = getHolidays(year, "ALL");
    var isHoliday = false;
    publicHolidaysDE.forEach(function(day) {
        if (day.dateString === formattedDate) {
            isHoliday = true;
        }
    });
    return isHoliday;
};
export var getClosestWorkingDay = function(receivedDate) {
    var date = receivedDate;
    while(isPublicHolidayDE(date)){
        date = dayjs(date).add(1, "day");
    }
    return date;
};
// return min and max dates in an array of string dates
export var getMinMaxDates = function(dates) {
    var _Math, _Math1;
    var arrtime = dates.map(function(str) {
        return new Date(str).getTime();
    });
    var minDate = new Date((_Math = Math).min.apply(_Math, _to_consumable_array(arrtime)));
    var maxDate = new Date((_Math1 = Math).max.apply(_Math1, _to_consumable_array(arrtime)));
    return {
        minDate: minDate,
        maxDate: maxDate
    };
};
export var isAfter = function(date, dateToCompare, unit) {
    return dayjs(date).isAfter(dateToCompare, unit);
};
export function isBusinessHours() {
    var berlinTime = dayjs().tz("Europe/Berlin");
    var day = berlinTime.day();
    var hour = berlinTime.hour();
    if (isBusinessDay(berlinTime) && !(day === 5 && hour >= 18) && !(day === 1 && hour < 8)) {
        return true;
    }
    return false;
}
export var removeLocalTimezoneOffset = function(date) {
    return new Date("".concat(date.getFullYear(), "-").concat((date.getMonth() + 1).toString().padStart(2, "0"), "-").concat(date.getDate().toString().padStart(2, "0")));
};
