import { createContext, useContext } from "react";
export var App;
(function(App) {
    App["UA"] = "ua";
    App["B2B"] = "b2b";
    App["CP"] = "cp";
})(App || (App = {}));
export var CurrentAppContext = createContext(App.UA);
export var useCurrentApp = function() {
    return useContext(CurrentAppContext);
};
