import { getFinnSessionId } from "@finn/ui-utils";
import cookie from "cookie";
import Cookies from "js-cookie";
export var DEFAULT_COOKIE_EXPIRATION_DAYS = 1;
export var CookieKeys;
(function(CookieKeys) {
    CookieKeys["PAGE_NOT_AVAILABLE"] = "page_not_available";
    CookieKeys["VEHICLE_CART_NOT_AVAILABLE"] = "vehicle_cart_not_available";
    CookieKeys["DEAL_CLOSED"] = "deal_closed";
    CookieKeys["RIBBON_DISMISSED"] = "ribbon_dismissed";
    CookieKeys["CHECKOUT_TERM_NOT_AVAILABLE"] = "checkout_term_not_available";
    CookieKeys["CHECKOUT_PRICE_UPDATED"] = "checkout_price_updated";
    CookieKeys["IS_FOR_BUSINESS"] = "is_for_business";
    CookieKeys["CHECKOUT_DEAL_INFO"] = "checkout_deal_info";
    CookieKeys["FINN_AUTH"] = "finn_auth";
    CookieKeys["AUTH_EMAIL_VERIFIED"] = "auth_email_verified";
    CookieKeys["SIGNOUT_IN_PROGRESS"] = "finn-soip";
    CookieKeys["REFERRAL_VOUCHER_CODE"] = "referral_voucher_code";
    CookieKeys["VOUCHER_CODE"] = "voucherCode";
    CookieKeys["B2B_MULTIPLIER"] = "b2b_multiplier";
    CookieKeys["B2B_REFERRER_CODE"] = "referrer-code";
    CookieKeys["B2B_EMAIL_ADDRESS"] = "b2b_email_address";
    CookieKeys["UTM_SOURCE"] = "b2b_utm_source";
    CookieKeys["IS_MOBILE_FILTER_OPEN"] = "is_mobile_filter_open";
    CookieKeys["IS_REFERRAL_VOUCHER_TRACKED"] = "is_referral_voucher_tracked";
    CookieKeys["INVALID_PAYMENT_METHOD"] = "invalid_payment_method";
    CookieKeys["NEXT_LOCALE"] = "NEXT_LOCALE";
    CookieKeys["NETLIFY_LOCALE"] = "nf_lang";
    CookieKeys["E2E_TEST_IN_PROGRESS"] = "E2E-test-in-progress";
    CookieKeys["E2E_TEST_DL_VERIFIED"] = "E2E-test-dl-verified";
    CookieKeys["E2E_TEST_ID_VERIFIED"] = "E2E-test-id-verified";
    CookieKeys["E2E_TEST_EMAIL_VERIFIED"] = "E2E-test-email-verified";
    CookieKeys["BLACKLISTED"] = "blacklisted";
    CookieKeys["DISABLE_DIRECT_CHECKOUT_FOR_E2E"] = "E2E-disable-direct-checkout";
    CookieKeys["DEV_PASS"] = "dev-pass";
    CookieKeys["COSMIC_HELPER"] = "cosmic-helper";
    CookieKeys["LAST_CHECKOUT_STEP"] = "last-checkout-step";
    CookieKeys["X_FINN_SESSION_ID"] = "X-Finn-Session-Id";
    CookieKeys["STRIPE_ERROR_CODE"] = "Stripe-Error-Code";
    CookieKeys["HUBSPOT_UTK"] = "hubspotutk";
    CookieKeys["FULLSTORY_DE"] = "de-DE_fullstory";
    CookieKeys["FULLSTORY_US"] = "en-US_fullstory";
    CookieKeys["GCLID"] = "_gcl_aw";
    CookieKeys["DC_TRIGGER_DEAL_EVENT"] = "DC_Deal";
    CookieKeys["DC_CREDIT_SCORE"] = "DC_credit_score";
    CookieKeys["TEMP_DELIVERY_DATE"] = "temp_delivery_date";
    CookieKeys["TEMP_SWAP_DEAL_ID"] = "temp_swap_deal_id";
    CookieKeys["DELIVERY_MODAL_SHOWED"] = "deliveryModalShowed";
    CookieKeys["CLOSED_DEAL_ID"] = "closedDealId";
    CookieKeys["LAST_DEAL_INFO"] = "lastDealInfo";
    CookieKeys["LAST_DEAL_ID"] = "lastDealId";
    CookieKeys["RETURN_TO_CKO"] = "returnToCKO";
    CookieKeys["HUBSPOT_ID"] = "finn_hid";
    CookieKeys["E2E_SECRET"] = "e2e-secret";
    CookieKeys["LOYALTY_INTRO_MODAL"] = "finn_l_im";
    CookieKeys["LAST_URL"] = "finn_lu";
    CookieKeys["SESSION_DATA"] = "finn_sd";
})(CookieKeys || (CookieKeys = {}));
export var setLocaleCookie = function(locale) {
    Cookies.set(CookieKeys.NEXT_LOCALE, locale); // https://nextjs.org/docs/advanced-features/i18n-routing#leveraging-the-next_locale-cookie
    Cookies.set(CookieKeys.NETLIFY_LOCALE, locale); // netlify uses different cookie name for locale
};
export var setClientCookie = function(key, value) {
    var expires = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : DEFAULT_COOKIE_EXPIRATION_DAYS;
    Cookies.set(key, value, {
        expires: expires
    });
};
export var getClientCookie = function(key) {
    return Cookies.get(key);
};
export var isCookieExist = function(key) {
    return Boolean(Cookies.get(key));
};
export var setClientJSONCookie = function(key, jsonData) {
    var expires = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : DEFAULT_COOKIE_EXPIRATION_DAYS;
    try {
        Cookies.set(key, JSON.stringify(jsonData), {
            expires: expires
        });
    } catch (e) {
        console.error(e);
    }
};
export var getClientJSONCookie = function(key) {
    var defaultValue = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : "{}";
    try {
        return JSON.parse(Cookies.get(key) || defaultValue);
    } catch (e) {
        console.error(e);
    }
    return {};
};
export var setClientBooleanCookie = function(key, value) {
    var expires = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : DEFAULT_COOKIE_EXPIRATION_DAYS;
    return Cookies.set(key, (value === null || value === void 0 ? void 0 : value.toString()) || "false", {
        expires: expires
    });
};
export var getClientBooleanCookie = function(key) {
    return Cookies.get(key) === "true";
};
export function getServerCookie(key, requestHeaders) {
    var ref;
    if (!(requestHeaders === null || requestHeaders === void 0 ? void 0 : requestHeaders.cookie)) return false;
    return (ref = cookie.parse(requestHeaders.cookie)) === null || ref === void 0 ? void 0 : ref[key];
}
/**
 * Sets a cookie from the server side
 * @param expires The expiry time in days
 */ export function setServerCookie(res, key, value, expires) {
    var current = res.getHeader("Set-Cookie");
    var expiryInSeconds = expires ? expires * 24 * 60 * 60 : undefined;
    res.setHeader("Set-Cookie", [
        current,
        cookie.serialize(key, value, {
            maxAge: expiryInSeconds
        })
    ].filter(function(c) {
        return c;
    }).join(", "));
}
export var getServerJSONCookie = function(key, requestHeaders) {
    var defaultValue = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : "{}";
    return JSON.parse(getServerCookie(key, requestHeaders) || defaultValue);
};
export var clearClientCookie = function(key) {
    return Cookies.remove(key);
};
export var setFinnSessionCookie = function() {
    var finnSessionId = Cookies.get(CookieKeys.X_FINN_SESSION_ID);
    if (finnSessionId) return;
    var newSessionId = getFinnSessionId();
    Cookies.set(CookieKeys.X_FINN_SESSION_ID, newSessionId);
};
