export var SelfServiceRoutes = {
    MYACCOUNT: "/myaccount",
    CONTACT_DETAILS: "/myaccount/contactdetails",
    CHANGE_PASSWORD: "/myaccount/changepassword",
    DRIVER_MGMT: "/myaccount/drivers",
    ADD_DRIVER: "/myaccount/drivers/add",
    COMPANY_DETAILS: "/myaccount/companydetails",
    SERVICES: "/services",
    LOYALTY: "/loyalty",
    INVOICES: "/myaccount/invoices",
    MY_CARS: "/mycars"
};
export var AUTHENTICATED_ROUTES = [
    SelfServiceRoutes.MYACCOUNT,
    SelfServiceRoutes.CONTACT_DETAILS,
    SelfServiceRoutes.CHANGE_PASSWORD,
    SelfServiceRoutes.DRIVER_MGMT,
    SelfServiceRoutes.ADD_DRIVER,
    SelfServiceRoutes.COMPANY_DETAILS,
    SelfServiceRoutes.MY_CARS, 
];
