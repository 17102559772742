import { makeStyles } from "@material-ui/core";
export var useBenefitChipStyles = makeStyles(function(theme) {
    return {
        root: {
            cursor: "pointer",
            display: "flex",
            alignItems: "center"
        },
        rootNoHover: {
            display: "flex",
            alignItems: "center"
        },
        disabledBenefit: {
            display: "flex",
            alignItems: "center",
            pointerEvents: "none",
            opacity: 0.3
        },
        iconBlock: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minWidth: 76,
            minHeight: 76,
            borderRadius: "4px 0 0 4px",
            "& img": {
                height: 76,
                width: 76,
                minWidth: 76,
                minHeight: 76,
                borderRadius: "4px 0 0 4px"
            }
        },
        titleAndArrow: {
            minHeight: 76,
            flex: "1 0 auto",
            maxWidth: "calc(100% - 76px)",
            display: "flex",
            gap: theme.spacing(2),
            alignItems: "center",
            padding: theme.spacing(0, 2),
            borderRadius: "0 4px 4px 0"
        },
        title: {},
        titleBlock: {
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(2)
        },
        arrow: {
            lineHeight: 1,
            marginLeft: "auto"
        }
    };
});
