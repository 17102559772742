import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import { useEffect, useState } from "react";
import { useTrackingStore } from "../stores/useTrackingStore";
import { TrackingEventName } from "../types";
// find all indices of array which are different from previous array
var findChangedIndices = function(arr1, arr2) {
    return arr1.reduce(function(acc, value, index) {
        if (value !== (arr2 === null || arr2 === void 0 ? void 0 : arr2[index])) {
            acc.push(index);
        }
        return acc;
    }, []);
};
// usually we track when first character is entered, in case we want to override this behavior, we can define it here
var isValidForTrackingEnteredEvent = function(fieldName, value) {
    switch(fieldName){
        case "phone":
            return (value === null || value === void 0 ? void 0 : value.length) > 7;
        case "birthday":
            return !!value;
        default:
            var ref, ref1;
            return !!((ref = value || "") === null || ref === void 0 ? void 0 : (ref1 = ref.trim) === null || ref1 === void 0 ? void 0 : ref1.call(ref));
    }
};
var isValidForTrackingCheckedEvent = function(value) {
    return value === true;
};
// at the moment it tracks only when field is entered in a form made with react hook form
// can be extended to track other events (e.g. validation)
// for validation failures should be tracked in onSubmit handler
export var useHookFormTracking = function(form, location) {
    var track = useTrackingStore(function(state) {
        return state.track;
    });
    var ref = useState([]), lastValues = ref[0], setLastValues = ref[1];
    var allFieldValues = form.watch();
    var fieldNames = Object.keys(allFieldValues);
    var values = fieldNames === null || fieldNames === void 0 ? void 0 : fieldNames.map(function(fieldName) {
        return allFieldValues[fieldName];
    });
    useEffect(function() {
        var differentIndices = findChangedIndices(values, lastValues);
        fieldNames === null || fieldNames === void 0 ? void 0 : fieldNames.forEach(function(fieldName, index) {
            var ref;
            if (!(differentIndices === null || differentIndices === void 0 ? void 0 : (ref = differentIndices.includes) === null || ref === void 0 ? void 0 : ref.call(differentIndices, index))) {
                return;
            }
            var value = allFieldValues[fieldName];
            if (isValidForTrackingEnteredEvent(fieldName, value)) {
                track(TrackingEventName.FORM_FIELD_ENTERED, {
                    fieldName: fieldName,
                    once: true,
                    location: location
                });
            }
            if (isValidForTrackingCheckedEvent(value)) {
                track(TrackingEventName.CHECKBOX_CLICKED, {
                    fieldName: fieldName,
                    once: true,
                    location: location
                });
            }
        });
        setLastValues(values);
    }, _to_consumable_array(values));
};
