import _define_property from "@swc/helpers/src/_define_property.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
/* eslint-disable max-lines */ /* eslint-disable no-use-before-define */ /* eslint-disable @typescript-eslint/naming-convention */ // TODO delete this after typography and colors migrated for DS
// We use unsafe_colors because by default we should use tailwind classes
// but as MUI requires colors to be passed as object, we need to use unsafe_colors
// which is a simple js object, used to generate tailwind colors in the first place
import unsafeColors from "@finn/design-system/unsafe_colors";
import { alpha } from "@material-ui/core/styles";
import createTheme from "@material-ui/core/styles/createTheme";
import merge from "lodash/merge";
import { fontFamilyInter, fontFamilySuisse } from "./fonts";
export var MAX_SCREEN_SIZE = "4000px";
// Change default breakpoints values
export var breakpointValues = {
    xs: 0,
    mobile: 375,
    sm: 600,
    md: 1120,
    lg: 1440,
    xl: 1920,
    max: 2100
};
var theme = createTheme({
    breakpoints: {
        values: breakpointValues
    }
});
var additionalConfig = {
    customTypography: {
        cta: {
            cursor: "pointer",
            fontFamily: fontFamilyInter,
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: 16,
            lineHeight: "24px"
        },
        ctaUnderline: {
            cursor: "pointer",
            fontFamily: fontFamilyInter,
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: 16,
            lineHeight: "24px",
            textDecoration: "underline",
            "&:hover": {
                color: unsafeColors.iron
            }
        },
        ctaUnderlineOnHover: {
            cursor: "pointer",
            fontFamily: fontFamilyInter,
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: 16,
            lineHeight: "24px",
            "&:hover": {
                textDecoration: "underline"
            }
        },
        label: {
            fontFamily: fontFamilyInter,
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "12px",
            lineHeight: "16px"
        },
        body3: {
            fontFamily: fontFamilyInter,
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "24px"
        }
    },
    customZIndices: {
        floatingBar: 1600,
        mobileMenuBar: 1250,
        pdpStickyBar: 4,
        pdpFullscreenGallery: 2
    },
    customStyles: {
        clickableItem: {
            border: "1px solid ".concat(unsafeColors.pearl),
            "&:hover": {
                boxShadow: "0px 0px 15px ".concat(alpha(unsafeColors.iron, 0.08)),
                cursor: "pointer"
            }
        },
        gallery: {
            rowHeight: {
                sm: 214,
                md: 320,
                lg: 466
            },
            rowHeightSingle: {
                lg: 388
            }
        },
        stickyNav: {
            height: "88px",
            iconWidth: "110px",
            widthBasis: "100px"
        }
    }
};
var paletteType = "light";
export var palette = {
    common: {
        white: unsafeColors.white,
        black: unsafeColors.black
    },
    primary: {
        main: unsafeColors.trustedBlue,
        dark: unsafeColors.trustedBluePressed
    },
    error: {
        main: unsafeColors.red
    },
    type: paletteType
};
var typography = _object_spread_props(_object_spread({}, theme.typography), {
    fontFamily: fontFamilySuisse,
    h1: _define_property({
        fontFamily: fontFamilySuisse,
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 40,
        lineHeight: "48px"
    }, theme.breakpoints.up("md"), {
        fontSize: 56,
        lineHeight: "64px"
    }),
    h2: _define_property({
        fontFamily: fontFamilySuisse,
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 36,
        lineHeight: "44px"
    }, theme.breakpoints.up("md"), {
        fontSize: 46,
        lineHeight: "48px"
    }),
    h3: _define_property({
        fontFamily: fontFamilySuisse,
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 32,
        lineHeight: "40px"
    }, theme.breakpoints.up("md"), {
        fontSize: 40,
        lineHeight: "48px"
    }),
    h4: _define_property({
        fontFamily: fontFamilySuisse,
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 28,
        lineHeight: "36px"
    }, theme.breakpoints.up("md"), {
        fontSize: 32,
        lineHeight: "40px"
    }),
    h5: {
        fontFamily: fontFamilySuisse,
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 24,
        lineHeight: "32px"
    },
    h6: {
        fontFamily: fontFamilySuisse,
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 16,
        lineHeight: "24px"
    },
    c1: {
        fontFamily: fontFamilyInter,
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 16,
        lineHeight: "24px"
    },
    c2: {
        fontFamily: fontFamilyInter,
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 14,
        lineHeight: "20px"
    },
    c3: {
        fontFamily: fontFamilyInter,
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 12,
        lineHeight: "16px"
    },
    label: {
        fontFamily: fontFamilyInter,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 12,
        lineHeight: "16px"
    },
    body1: {
        fontFamily: fontFamilyInter,
        fontStyle: "normal",
        fontWeight: 300,
        fontSize: 16,
        lineHeight: "24px"
    },
    body2: {
        fontFamily: fontFamilyInter,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 16,
        lineHeight: "24px"
    }
});
// TODO-REBRANDING: Update the overried when implementing the components
var overrides = {
    MuiCssBaseline: {
        "@global": {
            sub: {
                lineHeight: 0
            },
            small: {
                fontSize: "0.6em"
            },
            body: {
                backgroundColor: unsafeColors.white,
                color: unsafeColors.black
            },
            a: {
                textDecoration: "none",
                color: unsafeColors.black
            },
            p: {
                wordBreak: "break-word"
            },
            button: {
                fontFamily: fontFamilySuisse
            },
            div: {
                "&:focus": {
                    outline: "none"
                }
            },
            strong: {
                fontWeight: 600
            },
            b: {
                fontWeight: 600
            },
            // disabling custom scrollbar for inner div scrolls on chrome
            // todo: check on windows how it looks on a dialog and check where else it's shown
            // 'div *::-webkit-scrollbar': {
            //   width: '0.4em',
            // },
            // 'div *::-webkit-scrollbar-track': {
            //   '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
            // },
            // 'div *::-webkit-scrollbar-thumb': {
            //   backgroundColor: 'rgba(0,0,0,.1)',
            //   outline: '1px solid slategrey',
            // },
            "#nprogress .bar": {
                background: palette.primary.main,
                height: "4px"
            },
            "#nprogress .peg": {
                boxShadow: "0 0 10px ".concat(palette.primary.main, ", 0 0 5px ").concat(palette.primary.main)
            }
        }
    },
    MuiTypography: {
        root: {
            "&>p": {
                margin: 0
            }
        }
    },
    MuiButton: {
        root: _object_spread_props(_object_spread({}, additionalConfig.customTypography.cta), {
            padding: theme.spacing(0, 4),
            textTransform: "none",
            height: theme.spacing(7),
            borderRadius: 2
        }),
        text: {
            textTransform: "none",
            "&:hover": {
                backgroundColor: "inherit"
            },
            "&:focus": {
                textDecoration: "underline"
            }
        },
        outlined: {
            fontSize: 16,
            fontWeight: 600,
            borderRadius: "2px",
            textTransform: "none"
        },
        containedPrimary: {
            color: unsafeColors.white,
            position: "relative",
            "&:disabled": {
                backgroundColor: palette.primary.main
            },
            "&:hover": {
                backgroundColor: palette.primary.dark
            },
            "&:focus": {
                outline: "2px solid ".concat(unsafeColors.black)
            }
        },
        containedSecondary: {
            color: palette.primary.main,
            backgroundColor: unsafeColors.white,
            borderRadius: "2px",
            fontSize: 16,
            textTransform: "none",
            padding: "16px 48px",
            "&:hover": {
                backgroundColor: alpha(unsafeColors.white, 0.7)
            },
            "&:active": {
                backgroundColor: alpha(unsafeColors.white, 0.9)
            },
            "&.Mui-disabled": {
                backgroundColor: unsafeColors.pewter
            },
            "@media (hover: none)": {
                "&&:hover": {
                    backgroundColor: alpha(unsafeColors.white, 0.7)
                }
            }
        }
    },
    MuiStepIcon: {
        text: {
            fill: "white"
        }
    },
    MuiPickersBasePicker: {
        pickerView: {
            marginBottom: "12px"
        }
    },
    MuiPickersStaticWrapper: {
        staticWrapperRoot: {
            alignItems: "center"
        }
    },
    MuiPickersDay: {
        day: {
            "&:focus": {
                background: alpha(unsafeColors.black, 0.1)
            }
        },
        daySelected: {
            background: "".concat(palette.primary.main, " !important")
        }
    },
    MuiPickersCalendarHeader: {
        iconButton: {
            "&:focus": {
                background: alpha(unsafeColors.black, 0.1)
            }
        }
    },
    MuiDivider: {
        root: {
            backgroundColor: unsafeColors.pewter
        }
    },
    MuiFormLabel: {
        root: {
            fontWeight: 400
        }
    },
    MuiInputLabel: {
        root: {
            color: unsafeColors.black,
            "&$focused": {
                color: unsafeColors.black
            }
        }
    },
    MuiFilledInput: {
        root: {
            color: unsafeColors.black,
            backgroundColor: unsafeColors.white,
            "&$focused": {
                borderColor: unsafeColors.black,
                backgroundColor: unsafeColors.white
            },
            "&:hover": {
                borderColor: unsafeColors.black,
                backgroundColor: unsafeColors.white
            }
        }
    },
    MuiInputBase: {
        root: {
            color: unsafeColors.black,
            backgroundColor: unsafeColors.white,
            "&$focused": {
                borderColor: unsafeColors.black,
                backgroundColor: unsafeColors.white
            },
            "&:hover": {
                borderColor: unsafeColors.black,
                backgroundColor: unsafeColors.white
            }
        }
    },
    MuiSelect: {
        root: {
            "&:focus": {
                backgroundColor: "".concat(unsafeColors.white, " !important")
            }
        }
    }
};
var defaultSettings = {
    overrides: overrides,
    palette: palette,
    typography: typography
};
export function createMUITheme(settings) {
    return createTheme(_object_spread({}, theme, defaultSettings, additionalConfig, merge(settings, {
        props: {
            MuiButtonBase: {
                disableRipple: true
            }
        }
    })));
}
