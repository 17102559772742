import { getGlobalObject } from "../helpers/globalObject";
var getViewportMetaTag = function() {
    var viewportTagEl = document.createElement("meta");
    viewportTagEl.name = "viewport";
    viewportTagEl.content = "initial-scale=1.0, maximum-scale=1.0";
    return viewportTagEl;
};
export var injectStyles = function(param) {
    var isNativeNavigationFirst = param.isNativeNavigationFirst, bottom = param.bottom;
    var globalObject = getGlobalObject();
    // we inject some variables for css
    var rootElement = document.querySelector(":root");
    rootElement === null || rootElement === void 0 ? void 0 : rootElement.style.setProperty("--bottom-inset", "".concat(bottom, "px"));
    // we need to "fix" the window height because it changes if keyboard
    // is toggled which causes some content to jump
    rootElement === null || rootElement === void 0 ? void 0 : rootElement.style.setProperty("--webview-height", "".concat(globalObject.innerHeight, "px"));
    if (!isNativeNavigationFirst) {
        var styleEl = document.createElement("style");
        styleEl.innerHTML = '\n      [data-appid="sticky-bar"], [data-appid="direct-checkout-button-group"] {\n        box-sizing: content-box;\n        padding-bottom: '.concat(bottom, 'px;\n      }\n      [data-appid="close-icon"] {\n        visibility: hidden;\n      }\n    ');
        document.head.appendChild(styleEl);
    }
    document.head.appendChild(getViewportMetaTag());
};
