import { useEffect, useState } from "react";
import tailwindConfig from "../../tailwind.config";
var ref;
var sizesMap = tailwindConfig === null || tailwindConfig === void 0 ? void 0 : (ref = tailwindConfig.theme) === null || ref === void 0 ? void 0 : ref.screens;
export var useHasHover = function() {
    try {
        return matchMedia("(hover: hover)").matches;
    } catch (e) {
        // Assume that if browser too old to support matchMedia it's likely not a touch device
        return true;
    }
};
// https://github.com/shadcn-ui/ui/blob/main/apps/www/hooks/use-media-query.tsx
export var useMediaQuery = function(size) {
    var ref = useState(false), value = ref[0], setValue = ref[1];
    useEffect(function() {
        try {
            var ref;
            var onChange = function(event) {
                return setValue(event.matches);
            };
            var result = matchMedia("(max-width: ".concat(sizesMap[size], ")"));
            result === null || result === void 0 ? void 0 : (ref = result.addEventListener) === null || ref === void 0 ? void 0 : ref.call(result, "change", onChange);
            setValue(result.matches);
            return function() {
                var ref;
                return result === null || result === void 0 ? void 0 : (ref = result.removeEventListener) === null || ref === void 0 ? void 0 : ref.call(result, "change", onChange);
            };
        } catch (e) {
            setValue(false);
            return function() {
            // empty
            };
        }
    }, [
        size
    ]);
    return value;
};
