import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { trackEvent } from "@finn/ui-utils";
var LOGIN_ACTIVITY_EVENTS = {
    ACCOUNT_LOGGED_IN: "Account Logged In",
    ACCOUNT_LOGGED_OUT: "Account Logged Out",
    ACCOUNT_CREATED: "Account Created"
};
var VERIFICATION_EVENTS = {
    DRIVER_LICENSE_STEP_CLICKED: "Driver's License Verification Step Clicked",
    DRIVER_LICENSE_STEP_VIEWED: "Driver's License Verification Step Viewed",
    ID_STEP_CLICKED: "ID Verification Step Clicked",
    ID_STEP_VIEWED: "ID Verification Step Viewed",
    POA_STEP_CLICKED: "Proof of Address Step Clicked",
    POA_STEP_VIEWED: "Proof of Address Step Viewed"
};
var SUBSCRIPTION_CANCELATION = {
    SUBSCRIPTION_CANCELATION_CLICKED: "Cancel Subscription Clicked",
    SUBSCRIPTION_CANCELATION_CONFIRMATION_DOWNLOADED: "Cancel Subscription Confirmation Downloaded"
};
export var accountLoggedIn = function(props) {
    return trackEvent(LOGIN_ACTIVITY_EVENTS.ACCOUNT_LOGGED_IN, _object_spread({}, props));
};
export var accountLoggedOut = function(props) {
    return trackEvent(LOGIN_ACTIVITY_EVENTS.ACCOUNT_LOGGED_OUT, _object_spread({}, props));
};
export var accountCreated = function(props) {
    return trackEvent(LOGIN_ACTIVITY_EVENTS.ACCOUNT_CREATED, _object_spread({}, props));
};
export var driverLicenseStepClicked = function(props) {
    return trackEvent(VERIFICATION_EVENTS.DRIVER_LICENSE_STEP_CLICKED, _object_spread({}, props));
};
export var driverLicenseStepViewed = function(props) {
    return trackEvent(VERIFICATION_EVENTS.DRIVER_LICENSE_STEP_VIEWED, _object_spread({}, props));
};
export var trackIdStepClicked = function(props) {
    return trackEvent(VERIFICATION_EVENTS.ID_STEP_CLICKED, _object_spread({}, props));
};
export var trackPoAStepClicked = function(props) {
    return trackEvent(VERIFICATION_EVENTS.POA_STEP_CLICKED, _object_spread({}, props));
};
export var PoAStepViewed = function(props) {
    return trackEvent(VERIFICATION_EVENTS.POA_STEP_VIEWED, _object_spread({}, props));
};
export var idStepViewed = function(props) {
    return trackEvent(VERIFICATION_EVENTS.ID_STEP_VIEWED, _object_spread({}, props));
};
export var subscriptionCancelationClicked = function(props) {
    return trackEvent(SUBSCRIPTION_CANCELATION.SUBSCRIPTION_CANCELATION_CLICKED, _object_spread({}, props));
};
export var subscriptionCancelationConfirmationDownloaded = function(props) {
    return trackEvent(SUBSCRIPTION_CANCELATION.SUBSCRIPTION_CANCELATION_CONFIRMATION_DOWNLOADED, _object_spread({}, props));
};
var RETENTION = {
    BUY_CAR_OPTION_CLICKED: "Buy Car Option Clicked",
    RETENTION_OPTION_CLICKED: "Retention Option Clicked",
    RETENTION_OFFER_VIEWED: "Retention Offer Viewed",
    RETENTION_OFFER_CONFIRMED: "Retention Offer Confirmed",
    PROLONGATION_OPTION_CLICKED: "Prolongation Option Clicked",
    RETENTION_SHOP_ACCESSED: "Retention Shop Accessed",
    SWAP_OPTION_CLICKED: "Swap Option Clicked",
    RETURN_OPTION_CLICKED: "Return Option Clicked"
};
export var retentionOfferConfimed = function(props) {
    return trackEvent(RETENTION.RETENTION_OFFER_CONFIRMED, _object_spread({}, props));
};
