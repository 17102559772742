import { WINTER } from "@finn/ua-constants";
import { COMMON_DATE_FORMAT, LONG_MONTH_YEAR_FORMAT, toUniversalTime } from "@finn/ui-utils";
import createSeasonResolver from "date-season";
import dayjs from "dayjs";
import { FUEL } from "../types/vehicle";
export var SOURCES = {
    NOCODB: "nocodb",
    AIRTABLE: "airtable",
    BLUE_DRAGON: "blue_dragon"
};
export var isElectricVehicle = function(fuel) {
    return fuel === FUEL.ElectricDE || fuel === FUEL.ElectricEN;
};
export var isVehicleComingSoon = function(vehicle) {
    // availability overrides coming soon status
    return !vehicle.availability && !!(vehicle === null || vehicle === void 0 ? void 0 : vehicle.coming_soon);
};
var seasonNorth = createSeasonResolver();
export var getAvailabilityText = function(param) {
    var i18n = param.i18n, vehicle = param.vehicle;
    var isComingSoon = isVehicleComingSoon(vehicle);
    var availableFrom = (vehicle === null || vehicle === void 0 ? void 0 : vehicle.available_from) || "";
    var earliestAvailability = (vehicle === null || vehicle === void 0 ? void 0 : vehicle.earliest_expected_available_from) || "";
    if (!availableFrom && !earliestAvailability) {
        return "";
    }
    if (isComingSoon) {
        var monthDiff = dayjs(earliestAvailability).diff(dayjs(), "month");
        if (monthDiff < 3) {
            var comingSoonMonthAndYear = i18n.formatDate(earliestAvailability, LONG_MONTH_YEAR_FORMAT);
            return i18n.formatMessage({
                id: "plp.availableFromComingSoon"
            }, {
                date: comingSoonMonthAndYear
            });
        } else {
            var comingSoonSeason = seasonNorth(earliestAvailability);
            var textIdComingSeason = "plp.comingNext".concat(comingSoonSeason);
            var month = dayjs(earliestAvailability).month();
            var year = (earliestAvailability || "").split("-")[0];
            var yearComingSoon = comingSoonSeason === WINTER && month <= 2 ? "".concat(parseInt(year) - 1, "/").concat(year.slice(-2)) : year;
            return i18n.formatMessage({
                id: textIdComingSeason
            }, {
                year: yearComingSoon
            });
        }
    } else {
        var availableFromDate = i18n.formatDate(toUniversalTime(new Date(availableFrom)), COMMON_DATE_FORMAT);
        return i18n.formatMessage({
            id: "plp.availableFrom"
        }, {
            date: availableFromDate
        });
    }
};
export var getEquipmentLine = function(vehicle) {
    var equipmentLine = vehicle.source === SOURCES.BLUE_DRAGON ? vehicle.trim_name : vehicle.equipment_line;
    return equipmentLine !== null && equipmentLine !== void 0 ? equipmentLine : "";
};
export var getConfigLineLabel = function(vehicle) {
    var _equipment_line, _engine, _equipment_line1;
    return vehicle.source === SOURCES.BLUE_DRAGON ? (_equipment_line = vehicle.equipment_line) !== null && _equipment_line !== void 0 ? _equipment_line : "" : "".concat((_engine = vehicle.engine) !== null && _engine !== void 0 ? _engine : "", " ").concat((_equipment_line1 = vehicle.equipment_line) !== null && _equipment_line1 !== void 0 ? _equipment_line1 : "");
};
