import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import { getSlugsWithoutStep } from "./pathHelper";
export var getHubspotDealInfo = function(router) {
    var query = router.query;
    if (query.contactId) {
        // should be a dead code block when all checkout pages are converted to CSR
        return {
            contactId: parseInt(query.contactId),
            dealId: parseInt(query.dealId),
            hash: query.dealHash
        };
    } else {
        var ref = _sliced_to_array(getSlugsWithoutStep(query === null || query === void 0 ? void 0 : query.slug), 3), contactId = ref[0], dealId = ref[1], hash = ref[2];
        return {
            contactId: parseInt(contactId),
            dealId: parseInt(dealId),
            hash: hash
        };
    }
};
