export var UserAccountModal;
(function(UserAccountModal) {
    UserAccountModal["EMAIL_VERIFICATION"] = "email_verification";
    UserAccountModal["RESET_PASSWORD_EMAIL"] = "reset_password_email";
    UserAccountModal["PASSWORD_RESET_SUCCESSFULLY"] = "password_reset_successfully";
    UserAccountModal["PASSWORD_CHANGED_SUCCESSFULLY"] = "password_changed_successfully";
    UserAccountModal["EMAIL_VERIFICATION_CHECKOUT"] = "email_verification_checkout";
    UserAccountModal["EMAIL_VERIFIED_SUCCESSFULLY"] = "email_verified_successfully";
    UserAccountModal["ERROR"] = "error";
    UserAccountModal["REGISTRATION_CERTIFICATE_ACEEPTANCE_CRITERIA"] = "registration_certificate_acceptance_criteria";
})(UserAccountModal || (UserAccountModal = {}));
