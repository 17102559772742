import { CookieKeys } from '@finn/ui-utils';
import Cookies from 'js-cookie';
import { useCallback, useState } from 'react';

const useCosmicHelperActivation = () => {
  const [isCosmicHelperActive, setIsCosmicHelperActive] = useState(
    Cookies.get(CookieKeys.DEV_PASS) === Cookies.get(CookieKeys.COSMIC_HELPER)
  );
  const toggleCosmicHelper = useCallback(() => {
    if (isCosmicHelperActive) {
      Cookies.set(CookieKeys.COSMIC_HELPER, 'no');
      setIsCosmicHelperActive(false);
    } else {
      Cookies.set(CookieKeys.COSMIC_HELPER, Cookies.get(CookieKeys.DEV_PASS));
      setIsCosmicHelperActive(true);
    }
  }, [isCosmicHelperActive]);

  return { isCosmicHelperActive, toggleCosmicHelper };
};

export default useCosmicHelperActivation;
