import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { adjustLinkTargetsforWebView } from "../helpers/webViewHelpers";
import { connectWebToApp } from "./connectors";
import { getGlobalObject } from "./helpers/globalObject";
import { dom } from "./services/dom";
import { tracking } from "./services/tracking";
var dummy = function() {
// empty function used as a placeholder till web overrides it
};
var createWebToAppSDK = function(props) {
    var listeners = [];
    var emitEvent = function(message) {
        var ref, ref1;
        window === null || window === void 0 ? void 0 : (ref = window.ReactNativeWebView) === null || ref === void 0 ? void 0 : (ref1 = ref.postMessage) === null || ref1 === void 0 ? void 0 : ref1.call(ref, JSON.stringify(message));
    };
    var emitSDKEvent = function(message) {
        switch(message){
            case "checkout_ready":
                if (props.platform === "android") {
                    adjustLinkTargetsforWebView();
                }
                break;
        }
    };
    var addListener = function(name, cb) {
        var listenerItem = {
            name: name,
            cb: cb
        };
        listeners.push(listenerItem);
        return function() {
            listeners = listeners.filter(function(item) {
                return item !== listenerItem;
            });
        };
    };
    var domMethods = dom(emitEvent);
    var trackingMethods = tracking(props);
    var withSlash = function(path) {
        return path.startsWith("/") ? path : "/".concat(path);
    };
    var sdk = {
        init: function() {
            return connectWebToApp(_object_spread_props(_object_spread({}, props), {
                addListener: addListener,
                emitEvent: emitEvent
            }));
        },
        navigate: function(path, app) {
            var globalObject = getGlobalObject();
            var currentUrl = globalObject.location.href;
            // if path includes http, means that this is an external link and we just change it, for example support links
            if (path.startsWith("http")) {
                globalObject.location.href = path;
            // we are navigating to portal, from not portal, means another app and we need to change location
            } else if (app === "portal" && !(currentUrl === null || currentUrl === void 0 ? void 0 : currentUrl.includes("/portal"))) {
                globalObject.location.href = "".concat(props.baseUrl, "/portal/").concat(props.locale).concat(withSlash(path));
            // we are navigating to not portal, from portal, means another app and we need to change location
            } else if (!app && (currentUrl === null || currentUrl === void 0 ? void 0 : currentUrl.includes("/portal"))) {
                globalObject.location.href = "".concat(props.baseUrl, "/").concat(props.locale).concat(withSlash(path));
            // other cases we can just to client side routing as we are inside the same app
            } else {
                var ref;
                (ref = globalObject.next) === null || ref === void 0 ? void 0 : ref.router.push(path, undefined, {
                    appFirst: true
                });
            }
        },
        isReady: function() {
            return true;
        },
        sendMessageToApp: emitEvent,
        sendMessageToAppSDK: emitSDKEvent,
        on: addListener,
        showContinueFooter: domMethods.pdp.showContinueFooter,
        hideContinueFooter: domMethods.pdp.hideContinueFooter,
        closeTooltip: domMethods.all.closeTooltip,
        closeDialog: domMethods.all.closeDialog,
        closeFullScreenGallery: domMethods.pdp.closeFullScreenGallery,
        fecthCachedZipcode: domMethods.all.fecthCachedZipcode,
        fetchZipcode: domMethods.all.fetchZipcode,
        getTrackingProps: trackingMethods.getTrackingProps,
        setUserTrackingId: trackingMethods.setUserTrackingId,
        updatePLPFilter: dummy,
        fetchFilterValues: dummy,
        getAuthenticationStatus: dummy,
        getCurrentUrl: function() {
            var ref;
            return sdk.sendMessageToApp({
                type: "event:current_url",
                value: window === null || window === void 0 ? void 0 : (ref = window.location) === null || ref === void 0 ? void 0 : ref.href
            });
        },
        // method called from app
        callListener: function(name, params) {
            var ref;
            (ref = listeners.find(function(listener) {
                return listener.name === name;
            })) === null || ref === void 0 ? void 0 : ref.cb(params);
        },
        push: function(url) {
            var ref, ref1;
            (ref = getGlobalObject()) === null || ref === void 0 ? void 0 : (ref1 = ref.next) === null || ref1 === void 0 ? void 0 : ref1.router.push(url, undefined);
        },
        goToURL: function(url) {
            var globalObject = getGlobalObject();
            if (globalObject) {
                globalObject.location.href = url;
            }
        },
        verifyEmail: dummy,
        prefetchUrl: function(path) {
            var id = "prefetch-link-".concat(path);
            if (!document.getElementById(id)) {
                var link = document.createElement("link");
                link.rel = "prefetch";
                link.href = path;
                link.id = id;
                document.head.appendChild(link);
            }
        }
    };
    return sdk;
};
export { createWebToAppSDK };
