import { captureMessage } from "@finn/ui-utils";
import { isServer } from "@finn/ui-utils/lib/server";
import { getGlobalObject } from "../sdk/helpers/globalObject";
var DELAY_BEOFORE_CHECKING_SDK = 1000;
var MAX_ATTEMPTS = 30;
export var getAppSDK = function(cb) {
    if (isServer()) {
        return undefined;
    }
    if (getGlobalObject().nativeAppSDK) {
        return getGlobalObject().nativeAppSDK;
    }
    if (cb) {
        var callsCounter = 0;
        var timer = setInterval(function() {
            callsCounter++;
            if (callsCounter > MAX_ATTEMPTS) {
                clearInterval(timer);
            }
            if (getGlobalObject().nativeAppSDK) {
                clearInterval(timer);
                cb(getGlobalObject().nativeAppSDK);
            } else {
                captureMessage(new Error("Native SDK not found in callback"));
            }
        }, DELAY_BEOFORE_CHECKING_SDK);
    }
    return undefined;
};
