import difference from "lodash/difference";
import { useCallback, useEffect } from "react";
import { useStore } from "zustand";
export function createModalHooks(modalStore) {
    return {
        useModalStore: function useModalStore() {
            return useStore(modalStore);
        },
        useOpenModal: function useOpenModal() {
            return useCallback(function(modalKey) {
                modalStore.getState().openModal(modalKey);
            }, []);
        },
        useCloseModal: function useCloseModal() {
            return useCallback(function(modalKey) {
                modalStore.getState().closeModal(modalKey);
            }, []);
        },
        useCloseAllModals: function useCloseAllModals() {
            return useCallback(function() {
                return modalStore.getState().closeAllModals();
            }, []);
        },
        useIsModalOpen: function useIsModalOpen(modalKey) {
            return Boolean(useStore(modalStore).openModalKeys[modalKey]);
        },
        useIsAnyModalOpen: function useIsAnyModalOpen() {
            return Boolean(Object.keys(useStore(modalStore).openModalKeys).length > 0);
        },
        useOnModalOpen: function useOnModalOpen(callback) {
            useEffect(function() {
                return modalStore.subscribe(function(state, prevState) {
                    var addedKeys = difference(Object.keys(state.openModalKeys), Object.keys(prevState.openModalKeys));
                    var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
                    try {
                        for(var _iterator = addedKeys[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
                            var key = _step.value;
                            callback(key);
                        }
                    } catch (err) {
                        _didIteratorError = true;
                        _iteratorError = err;
                    } finally{
                        try {
                            if (!_iteratorNormalCompletion && _iterator.return != null) {
                                _iterator.return();
                            }
                        } finally{
                            if (_didIteratorError) {
                                throw _iteratorError;
                            }
                        }
                    }
                });
            }, [
                callback
            ]);
        },
        useOnModalClose: function useOnModalClose(callback) {
            useEffect(function() {
                return modalStore.subscribe(function(state, prevState) {
                    var removedKeys = difference(Object.keys(prevState.openModalKeys), Object.keys(state.openModalKeys));
                    var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
                    try {
                        for(var _iterator = removedKeys[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
                            var key = _step.value;
                            callback(key);
                        }
                    } catch (err) {
                        _didIteratorError = true;
                        _iteratorError = err;
                    } finally{
                        try {
                            if (!_iteratorNormalCompletion && _iterator.return != null) {
                                _iterator.return();
                            }
                        } finally{
                            if (_didIteratorError) {
                                throw _iteratorError;
                            }
                        }
                    }
                });
            }, [
                callback
            ]);
        }
    };
}
