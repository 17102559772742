import _define_property from "@swc/helpers/src/_define_property.mjs";
import { makeStyles } from "@material-ui/core";
export var useLoyaltyBenefitsStyles = makeStyles(function(theme) {
    return {
        root: _define_property({
            marginBottom: theme.spacing(7)
        }, theme.breakpoints.up("md"), {
            marginBottom: theme.spacing(15)
        }),
        rootStandalone: {
            marginBottom: 0
        },
        title: _define_property({
            margin: theme.spacing(0, 0, 2, 0)
        }, theme.breakpoints.up("md"), {
            fontSize: 24,
            margin: theme.spacing(0, 0, 4, 0)
        }),
        benefitGrid: _define_property({
            display: "grid",
            gridAutoFlow: "row",
            gridTemplateColumns: "repeat(1, 1fr)",
            gap: theme.spacing(2)
        }, theme.breakpoints.up("md"), {
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: theme.spacing(3)
        })
    };
});
