import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import { getGlobalObject } from "../helpers/globalObject";
/* eslint no-implicit-globals: "error" */ export var setupAuth = function(emitEvent) {
    var globalObject = getGlobalObject();
    var originalFetch = globalObject.fetch;
    globalObject.fetch = function() {
        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
            args[_key] = arguments[_key];
        }
        var url = args === null || args === void 0 ? void 0 : args[0];
        if (typeof url === "string" && (url === null || url === void 0 ? void 0 : url.includes("/login"))) {
            emitEvent({
                type: "event:login"
            });
        }
        if (typeof url === "string" && (url === null || url === void 0 ? void 0 : url.includes("/register"))) {
            // we doing register, after that sending message that will allow native app to login
            return originalFetch.apply(void 0, _to_consumable_array(args)).then(function(res) {
                emitEvent({
                    type: "event:login"
                });
                return res;
            });
        }
        return originalFetch.apply(void 0, _to_consumable_array(args));
    };
};
