export var CosmicMetafieldType;
(function(CosmicMetafieldType) {
    CosmicMetafieldType["text"] = "text";
    CosmicMetafieldType["textarea"] = "textarea";
    CosmicMetafieldType["htmlTextarea"] = "html-textarea";
    CosmicMetafieldType["selectDropdown"] = "select-dropdown";
    CosmicMetafieldType["object"] = "object";
    CosmicMetafieldType["objectList"] = "objects";
    CosmicMetafieldType["file"] = "file";
    CosmicMetafieldType["date"] = "date";
    CosmicMetafieldType["radioButtonList"] = "radio-buttons";
    CosmicMetafieldType["checkboxList"] = "check-boxes";
    CosmicMetafieldType["repeater"] = "repeater";
    CosmicMetafieldType["parent"] = "parent";
    CosmicMetafieldType["markdown"] = "markdown";
    CosmicMetafieldType["json"] = "json";
    CosmicMetafieldType["switch"] = "switch";
    CosmicMetafieldType["number"] = "number";
})(CosmicMetafieldType || (CosmicMetafieldType = {}));
export var SocialPlatforms;
(function(SocialPlatforms) {
    SocialPlatforms["Facebook"] = "Facebook";
    SocialPlatforms["LinkedIn"] = "LinkedIn";
    SocialPlatforms["Instagram"] = "Instagram";
    SocialPlatforms["YouTube"] = "YouTube";
})(SocialPlatforms || (SocialPlatforms = {}));
