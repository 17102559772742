import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { identifyEvent, trackEvent } from "@finn/ui-utils";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { humanize } from "../humanize";
import { getEventProperties } from "./getEventProperties";
// the idea is to separate setting the properties and tracking the event
// most of the time the property we track with an event are set somewhere/sometime else
// we have context and prop drilling for solving this, but it can be simplified with store
export var useTrackingStore = create()(devtools(immer(function(set, get) {
    return {
        properties: {},
        trackedCount: {},
        setProperty: function(key, value) {
            set(function(state) {
                state.properties[key] = value;
            });
        },
        resetTrackedCount: function() {
            set(function(state) {
                state.trackedCount = {};
            });
        },
        identify: function(userId, traits, location, once) {
            set(function(state) {
                var eventKey = "identify-".concat(location || "");
                if (once && state.trackedCount[eventKey]) {
                    return;
                }
                try {
                    identifyEvent(userId, traits);
                } catch (e) {
                    console.error(e);
                }
                if (once) {
                    state.trackedCount[eventKey] = (state.trackedCount[eventKey] || 0) + 1;
                }
            });
        },
        track: function(eventName, param) {
            var fieldName = param.fieldName, name = param.name, location = param.location, once = param.once, additionalProps = param.additionalProps;
            set(function(state) {
                var nameOrFieldName = fieldName || name;
                var eventKey = "".concat(eventName).concat(nameOrFieldName || "").concat(location || "");
                if (once && state.trackedCount[eventKey]) {
                    return;
                }
                try {
                    trackEvent(eventName, _object_spread({
                        name: humanize(nameOrFieldName),
                        location: location
                    }, getEventProperties(eventName, get().properties), additionalProps || {}));
                } catch (e) {
                    console.error(e);
                }
                if (once) {
                    state.trackedCount[eventKey] = (state.trackedCount[eventKey] || 0) + 1;
                }
            });
        }
    };
}), {
    name: "CheckoutTrackingStore"
}));
