import { ageBoundaries, LIMIT_VEHICLE_POWER_FOR_YOUNG_AGE } from "@finn/ua-constants";
import { getAge } from "@finn/ui-utils";
export var EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export var validateEmail = function(email) {
    return EMAIL_REGEX.test(String(email).toLowerCase());
};
export var validatePhoneNumber = function(phoneNumber) {
    // simple regex for fake-door validation, not doing country/detailed validation
    var phoneRegex = /^(\+)?[0-9]{8,15}$/;
    return phoneRegex.test(phoneNumber);
};
export var validatePassword = function(password) {
    var re = /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/;
    /*
        * Here is an explanation:

          /^
            (?=.*\d)          // should contain at least one digit
            (?=.*[a-zA-Z])    // should contain at least one letter
            [a-zA-Z0-9!@#$%^&*)(+=._-]{8,}   // should contain at least 8 from the mentioned characters
          $/
         */ return re.test(password);
};
export var isValidAgeToBookACar = function(birthday, power) {
    var age = getAge(birthday);
    return age >= ageBoundaries.MIN_NORMAL || power <= LIMIT_VEHICLE_POWER_FOR_YOUNG_AGE;
};
