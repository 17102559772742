import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { compareVersions } from "compare-versions";
import { setupWebRouting } from "../deprecated/routing";
import { setupAnalytics } from "./analytics";
import { setupAuth } from "./auth";
import { getCurrentPageType } from "./constants";
import { PageHandlersWhenComplete, PageHandlersWhenInteractive } from "./handlers";
import { setupLogging } from "./logging";
import { setupNavigation } from "./navigation";
import { setupScrollListener } from "./scroll";
import { setupStorage } from "./storage";
import { injectStyles } from "./styles";
var isReady = function() {
    return Boolean((document === null || document === void 0 ? void 0 : document.readyState) === "complete" && (document === null || document === void 0 ? void 0 : document.getElementById("__next")));
};
var setupConnectors = function(param) {
    var screenName = param.screenName, allowSamePageWebNavigation = param.allowSamePageWebNavigation, isNativeNavigationFirst = param.isNativeNavigationFirst, withWebPDPColorChange = param.withWebPDPColorChange, locale = param.locale, version = param.version, emitEvent = param.emitEvent;
    var ref, ref1, ref2, ref3;
    var pageType = getCurrentPageType(screenName);
    document.documentElement.classList.add("native-app-screen");
    (ref = document.body) === null || ref === void 0 ? void 0 : (ref1 = ref.classList) === null || ref1 === void 0 ? void 0 : ref1.add(pageType, "region-".concat(locale));
    setupAuth(emitEvent);
    setupAnalytics(emitEvent);
    setupScrollListener(emitEvent);
    // TODO setupWebRouting is deprecated, feel free to delete condition and deprecated code after 1.55.0 release
    if (isNativeNavigationFirst) {
        setupWebRouting(emitEvent, {
            screenName: screenName,
            allowSamePageWebNavigation: allowSamePageWebNavigation,
            withWebPDPColorChange: withWebPDPColorChange,
            version: version
        });
    } else {
        setupNavigation(emitEvent);
    }
    (ref2 = PageHandlersWhenInteractive[pageType]) === null || ref2 === void 0 ? void 0 : ref2.call(PageHandlersWhenInteractive);
    (ref3 = PageHandlersWhenComplete[pageType]) === null || ref3 === void 0 ? void 0 : ref3.call(PageHandlersWhenComplete);
    emitEvent({
        type: "ready"
    });
};
export var connectWebToApp = function(props) {
    var handleDocumentReadyStateChange = function() {
        return connectWebToApp(props);
    };
    var isNativeNavigationFirst = compareVersions(props.version, "1.47.0") < 0;
    // we need to setup storage earlier
    setupStorage(props.emitEvent, props.localState);
    injectStyles({
        isNativeNavigationFirst: isNativeNavigationFirst,
        bottom: props.bottom || "0"
    });
    if (isReady()) {
        setupLogging(props.emitEvent);
        setupConnectors(_object_spread_props(_object_spread({}, props), {
            isNativeNavigationFirst: isNativeNavigationFirst
        }));
    } else {
        document.addEventListener("readystatechange", handleDocumentReadyStateChange);
    }
    return function() {
        document.removeEventListener("readystatechange", handleDocumentReadyStateChange);
    };
};
