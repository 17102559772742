import { CookieKeys } from '@finn/ui-utils';
import Cookies from 'js-cookie';
import { useCallback, useState } from 'react';

const useActivateTestStripe = () => {
  const [isTestStripeEnabled, setIsTestStripeEnabled] = useState(
    Cookies.get(CookieKeys.E2E_TEST_IN_PROGRESS) === 'yes'
  );
  const toggleTestStripe = useCallback(() => {
    if (isTestStripeEnabled) {
      Cookies.set(CookieKeys.E2E_TEST_IN_PROGRESS, 'no');
      setIsTestStripeEnabled(false);
    } else {
      Cookies.set(CookieKeys.E2E_TEST_IN_PROGRESS, 'yes');
      setIsTestStripeEnabled(true);
    }
  }, [isTestStripeEnabled]);

  return { isTestStripeEnabled, toggleTestStripe };
};

export default useActivateTestStripe;
