import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import { getGlobalObject } from "./globalObject";
export var isColorChangedFromTheSameVehicle = function(params) {
    var ref, ref1, ref2;
    var globalObject = getGlobalObject();
    var url = typeof params === "string" ? params : params === null || params === void 0 ? void 0 : params.pathname;
    var currentUrl = globalObject === null || globalObject === void 0 ? void 0 : (ref = globalObject.next) === null || ref === void 0 ? void 0 : (ref1 = ref.router) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.state) === null || ref2 === void 0 ? void 0 : ref2.asPath;
    if (!url || !currentUrl) {
        return false;
    }
    var ref3 = _sliced_to_array([
        url,
        currentUrl
    ].map(function(id) {
        var urlWithoutColor = id.split("-");
        urlWithoutColor.pop();
        return urlWithoutColor.join("-").replace("/mobile", "");
    }), 2), url1WithoutColor = ref3[0], url2WithoutColor = ref3[1];
    return url1WithoutColor === url2WithoutColor;
};
