import { CountryCode, Locale } from "../types/localization";
import { removeSpacesAndDashes } from "./text";
export var twoDecimalPlaces = function(num) {
    return Math.round((num + Number.EPSILON) * 100) / 100;
};
export var isNumberBetweenRange = function(num, upper, lower) {
    return lower <= num && num <= upper;
};
export var formatUSPhoneNumber = function(phoneNumber) {
    var formattedNumber = (phoneNumber === null || phoneNumber === void 0 ? void 0 : phoneNumber.replace(/[^0-9]/g, "")) || "";
    var first2Elements = formattedNumber.substring(0, 2);
    // If the phone number starts with '00', replace '00' with '+'
    if (first2Elements === "00") {
        return "+".concat(formattedNumber.substring(2));
    }
    if (formattedNumber.length === 10) {
        formattedNumber = "+1".concat(formattedNumber);
    }
    // if someone inputs country code without + then add the +
    if (formattedNumber.length === 11 && formattedNumber[0] === "1") {
        formattedNumber = "+".concat(formattedNumber);
    }
    // it's a German number from legecy input (without country code)
    if (formattedNumber.length > 10 && formattedNumber[0] === "0") {
        formattedNumber = "+49".concat(formattedNumber.substring(1));
    }
    return formattedNumber;
};
export var formatDEPhoneNumber = function(phoneNumber) {
    var finalePhoneNumber = removeSpacesAndDashes(phoneNumber);
    if (!finalePhoneNumber) {
        return finalePhoneNumber;
    }
    var first2Elements = finalePhoneNumber.substring(0, 2);
    // If the phone number starts with '00', replace '00' with '+'
    if (first2Elements === "00") {
        return "+".concat(finalePhoneNumber.substring(2));
    }
    //if the phone number does not start with a '+' or '00', but with a '0', replace '0' with '+49'
    if (finalePhoneNumber[0] === "0") {
        return "+49".concat(finalePhoneNumber.substring(1));
    }
    //If the phone number starts with '+' do nothing
    return finalePhoneNumber;
};
export var formatPhoneNumber = function(phoneNumber, country) {
    // if it already has country code, don't do country specific formatting. Just return cleaned up number
    var hasCountryCode = (phoneNumber === null || phoneNumber === void 0 ? void 0 : phoneNumber[0]) === "+";
    if (hasCountryCode) {
        return "+".concat(phoneNumber.replace(/[^0-9]/g, ""));
    }
    switch(country){
        case Locale.FAKE_DEFAULT:
        case Locale.GERMAN_GERMANY:
        case CountryCode.DE:
            {
                return formatDEPhoneNumber(phoneNumber);
            }
        case Locale.ENGLISH_USA:
        case CountryCode.US:
            {
                return formatUSPhoneNumber(phoneNumber);
            }
        default:
            return phoneNumber;
    }
};
export var formatNumberParts = function(parts) {
    return parts.map(function(param) {
        var type = param.type, value = param.value;
        switch(type){
            case "literal":
                return "";
            default:
                return value;
        }
    }).reduce(function(string, part) {
        return string + part;
    });
};
// small JS trick to ensure that the date is valid
export var isNumberValid = function(num) {
    return !isNaN(Number(num));
};
export var formatEuroDecimal = function(value, intl) {
    return intl.formatNumber(value, {
        currency: "EUR",
        style: "currency",
        maximumFractionDigits: 2
    });
};
