import { useMemo } from "react";
import { getKmPackagePriceWithoutVat } from "../pdp";
import { getForBusinessCookie } from "../ProductCard/forBusiness";
import { getLongestTerm } from "./getLongestTerm";
var getPriceList = function(param) {
    var vehicle = param.vehicle, isDownPayment = param.isDownPayment, isExperimentDownPayment = param.isExperimentDownPayment;
    var ref;
    if (isDownPayment && !isExperimentDownPayment) {
        var ref1;
        return vehicle === null || vehicle === void 0 ? void 0 : (ref1 = vehicle.downpayment_prices) === null || ref1 === void 0 ? void 0 : ref1.available_price_list;
    }
    return vehicle === null || vehicle === void 0 ? void 0 : (ref = vehicle.price) === null || ref === void 0 ? void 0 : ref.available_price_list;
};
var getBasePrice = function(param) {
    var term = param.term, priceList = param.priceList, isForBusiness = param.isForBusiness;
    var priceKey = "".concat(isForBusiness ? "b2b" : "b2c", "_").concat(term);
    var ref;
    return typeof priceList === "object" ? (ref = priceList === null || priceList === void 0 ? void 0 : priceList[priceKey]) !== null && ref !== void 0 ? ref : 0 : 0;
};
var getKilometerPackagePrice = function(param) {
    var vehicle = param.vehicle, isForBusiness = param.isForBusiness, kilometerPackage = param.kilometerPackage;
    var ref;
    var ref1;
    var packagePrice = (ref1 = vehicle === null || vehicle === void 0 ? void 0 : (ref = vehicle.price) === null || ref === void 0 ? void 0 : ref["extra_".concat(kilometerPackage)]) !== null && ref1 !== void 0 ? ref1 : 0;
    return getKmPackagePriceWithoutVat(packagePrice, isForBusiness);
};
var calculateDownpaymentDiscount = function(param) {
    var term = param.term, downPaymentAmount = param.downPaymentAmount;
    if (term === 0 || downPaymentAmount === 0) {
        return 0;
    }
    return downPaymentAmount / term;
};
export var calculateTotalPrice = function(params) {
    var term = params.term, vehicle = params.vehicle, _kilometerPackage = params.kilometerPackage, kilometerPackage = _kilometerPackage === void 0 ? 0 : _kilometerPackage, _downPaymentAmount = params.downPaymentAmount, downPaymentAmount = _downPaymentAmount === void 0 ? 0 : _downPaymentAmount, _isDownPayment = params.isDownPayment, isDownPayment = _isDownPayment === void 0 ? false : _isDownPayment, _isExperimentDownPayment = params.isExperimentDownPayment, isExperimentDownPayment = _isExperimentDownPayment === void 0 ? false : _isExperimentDownPayment;
    if (!vehicle) {
        return 0;
    }
    var isForBusiness = getForBusinessCookie();
    var adjustedTerm = term || getLongestTerm(vehicle);
    var isFlexibleTerm = adjustedTerm === 1;
    var priceList = getPriceList({
        vehicle: vehicle,
        isDownPayment: isDownPayment,
        downPaymentAmount: downPaymentAmount,
        isExperimentDownPayment: isExperimentDownPayment,
        isFakedoorDownpayment: false
    });
    var basePrice = getBasePrice({
        priceList: priceList,
        isForBusiness: isForBusiness,
        term: adjustedTerm
    });
    var kmPackagePrice = getKilometerPackagePrice({
        vehicle: vehicle,
        isForBusiness: isForBusiness,
        kilometerPackage: kilometerPackage
    });
    if (isDownPayment && downPaymentAmount > 0 && isExperimentDownPayment && !isFlexibleTerm) {
        var downpaymentDiscount = calculateDownpaymentDiscount({
            term: adjustedTerm,
            downPaymentAmount: downPaymentAmount
        });
        var discountOnBasePrice = basePrice * 0.03;
        return basePrice - downpaymentDiscount + kmPackagePrice - discountOnBasePrice;
    }
    return basePrice + kmPackagePrice;
};
export var useCalculatePrice = function(params) {
    return useMemo(function() {
        return calculateTotalPrice(params);
    }, [
        params
    ]);
};
