import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import regeneratorRuntime from "/opt/build/repo/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { getOrigin, isServer } from "@finn/ui-utils";
import axios from "axios";
export var maskPaymentInfo = function(last4) {
    return "•••• ".concat(last4);
};
export var replaceJSRenderTemplate = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(template, data) {
        var replaceJSRenderTemplateOnServer, options, axiosResult;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.prev = 0;
                    if (!isServer()) {
                        _ctx.next = 8;
                        break;
                    }
                    _ctx.next = 4;
                    return import("./server/text");
                case 4:
                    replaceJSRenderTemplateOnServer = _ctx.sent.replaceJSRenderTemplateOnServer;
                    return _ctx.abrupt("return", replaceJSRenderTemplateOnServer(template, data));
                case 8:
                    if (!(!template || template.length === 0)) {
                        _ctx.next = 10;
                        break;
                    }
                    return _ctx.abrupt("return", null);
                case 10:
                    options = {
                        method: "POST",
                        data: {
                            template: template,
                            data: data
                        },
                        url: "".concat(getOrigin(), "/api/jsrender")
                    };
                    _ctx.next = 13;
                    return axios(options);
                case 13:
                    axiosResult = _ctx.sent;
                    return _ctx.abrupt("return", axiosResult.data.result);
                case 15:
                    _ctx.next = 20;
                    break;
                case 17:
                    _ctx.prev = 17;
                    _ctx.t0 = _ctx["catch"](0);
                    return _ctx.abrupt("return", "");
                case 20:
                case "end":
                    return _ctx.stop();
            }
        }, _callee, null, [
            [
                0,
                17
            ]
        ]);
    }));
    return function replaceJSRenderTemplate(template, data) {
        return _ref.apply(this, arguments);
    };
}();
export var convertToEnglish = function(str) {
    var lettersMap = {
        ä: "ae",
        ë: "e",
        ü: "ue",
        é: "e",
        ö: "oe",
        ß: "ss"
    };
    return str.replace(/ä|ë|é|ü|ö|ß/gi, function(matched) {
        return lettersMap[matched];
    });
};
export var slugify = function(str) {
    return convertToEnglish(str).replace(/[\s.#]/g, "-").toLowerCase();
};
export var getUnderscoreCount = function(str) {
    return (str.match(/_/g) || []).length;
};
export var hashStr = function(str) {
    var hashedStr = isServer() ? Buffer.from(str).toString("base64") : window.btoa(str);
    return hashedStr;
};
export var parseBoolean = function(value) {
    if (value === "true") {
        return true;
    }
    if (value === "false") {
        return false;
    }
    return value;
};
export var replaceStrKeys = function(str, hash) {
    var replacedStr = str;
    for(var key in hash){
        replacedStr = replacedStr === null || replacedStr === void 0 ? void 0 : replacedStr.replace(new RegExp("\\{".concat(key, "\\}"), "gm"), hash[key]);
    }
    return replacedStr;
};
export var removeSpacesAndDashes = function(str) {
    var spacesAndDashes = /[\s-]/g;
    return str === null || str === void 0 ? void 0 : str.replace(spacesAndDashes, "");
};
