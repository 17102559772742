import { clearClientCookie, CookieKeys, DEFAULT_COOKIE_EXPIRATION_DAYS } from "@finn/ui-utils";
import { removeCookies, setCookies } from "cookies-next";
import Cookies from "js-cookie";
var DEFAULT_MOBILE_FILTERS_EXPIRATION_IN_SECONDS = 60 * 10;
export { getClientCookie, setClientCookie, isCookieExist, setClientJSONCookie, getClientJSONCookie, clearClientCookie } from "@finn/ui-utils";
export var setNextServerCookie = function(ctx, key, value) {
    var expires = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : DEFAULT_COOKIE_EXPIRATION_DAYS;
    setCookies(ctx, key, value, {
        expires: expires
    });
};
// todo: replace usage with the copy on ui-utils and then delete this
export var setClientBooleanCookie = function(key, value) {
    var expires = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : DEFAULT_COOKIE_EXPIRATION_DAYS;
    return Cookies.set(key, (value === null || value === void 0 ? void 0 : value.toString()) || "false", {
        expires: expires
    });
};
// todo: replace usage with the copy on ui-utils and then delete this
export var getClientBooleanCookie = function(key) {
    return Cookies.get(key) === "true";
};
export var clearServerCookie = function(ctx, key) {
    removeCookies(ctx, key);
};
export var setNextServerBooleanCookie = function(ctx, key, value) {
    var expires = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : DEFAULT_COOKIE_EXPIRATION_DAYS;
    setCookies(ctx, key, value.toString(), {
        expires: expires
    });
};
export var setIsFiltersDialogOpenCookie = function() {
    var seconds = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : DEFAULT_MOBILE_FILTERS_EXPIRATION_IN_SECONDS;
    var expiration = new Date(new Date().getTime() + seconds * 1000);
    setClientBooleanCookie(CookieKeys.IS_MOBILE_FILTER_OPEN, true, expiration);
};
export var refreshIsMobileFilterOpenCookie = function() {
    var seconds = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : DEFAULT_MOBILE_FILTERS_EXPIRATION_IN_SECONDS;
    clearClientCookie(CookieKeys.IS_MOBILE_FILTER_OPEN);
    setIsFiltersDialogOpenCookie(seconds);
};
