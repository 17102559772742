import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
// We use unsafe_colors because by default we should use tailwind classes
// but as stripe API requires colors to be passed as object, we need to use unsafe_colors
// which is a simple js object, used to generate tailwind colors in the first place
import unsafeColors from "@finn/design-system/unsafe_colors";
import { typography as UnsafeFonts } from "@finn/design-system/unsafe_fonts";
import { createTheme, makeStyles } from "@material-ui/core/styles";
var unsafeFonts = UnsafeFonts();
export var useStyles = makeStyles(function() {
    return {
        inputAdornmentRoot: {
            display: "none"
        }
    };
});
var overrides = {
    MuiPickersCalendarHeader: {
        iconButton: {
            padding: "0px"
        },
        switchHeader: {
            marginTop: "0px",
            marginBottom: "56px"
        },
        daysHeader: {
            justifyContent: "space-between"
        },
        dayLabel: _object_spread_props(_object_spread({}, unsafeFonts[".body-14-regular"]), {
            color: unsafeColors.black
        })
    },
    MuiPickersSlideTransition: {
        transitionContainer: {
            "& > *": unsafeFonts[".global-t6-semibold"],
            "& > div": {
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around"
            }
        }
    },
    MuiSvgIcon: {
        root: {
            color: unsafeColors.black
        }
    },
    MuiIconButton: {
        root: {
            "&:disabled": {
                "& > span > svg": {
                    color: "".concat(unsafeColors.pearl)
                }
            }
        }
    },
    MuiPickersCalendar: {
        week: {
            justifyContent: "space-between"
        }
    },
    MuiPickersDay: {
        day: unsafeFonts[".body-14-regular"],
        daySelected: {
            backgroundColor: "".concat(unsafeColors.black),
            "&:hover": {
                backgroundColor: "".concat(unsafeColors.black)
            }
        },
        dayDisabled: {
            color: unsafeColors.pewter
        }
    },
    MuiPickersBasePicker: {
        containerLandscape: {
            width: "100%"
        },
        pickerView: {
            maxWidth: "100%",
            width: "100%"
        }
    }
};
export var defaultMaterialTheme = createTheme({
    overrides: overrides
});
