import { create } from "zustand";
import { devtools } from "zustand/middleware";
export var useAppStore = create()(devtools(function(set) {
    return {
        isReady: false,
        setReady: function setReady() {
            set(function() {
                return {
                    isReady: true
                };
            }, false, "app/setReady");
        }
    };
}, {
    name: "app"
}));
