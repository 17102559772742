import { CookieKeys } from '@finn/ui-utils';
import Cookies from 'js-cookie';
import { useCallback, useState } from 'react';

const useDisableDirectCheckout = () => {
  const [isDirectCheckoutDisabled, setIsDirectCheckoutDisabled] = useState(
    Cookies.get(CookieKeys.DISABLE_DIRECT_CHECKOUT_FOR_E2E) === 'yes'
  );
  const toggleDirectCheckoutDisabling = useCallback(() => {
    if (isDirectCheckoutDisabled) {
      Cookies.set(CookieKeys.DISABLE_DIRECT_CHECKOUT_FOR_E2E, 'no');
      setIsDirectCheckoutDisabled(false);
    } else {
      Cookies.set(CookieKeys.DISABLE_DIRECT_CHECKOUT_FOR_E2E, 'yes');
      setIsDirectCheckoutDisabled(true);
    }
  }, [isDirectCheckoutDisabled]);

  return {
    isDirectCheckoutDisabled,
    toggleDirectCheckoutDisabling,
  };
};

export default useDisableDirectCheckout;
