export var dom = function(emitEvent) {
    return {
        all: {
            closeTooltip: function() {
                var ref, ref1;
                (ref = document.querySelector("[role=tooltip] button")) === null || ref === void 0 ? void 0 : (ref1 = ref.click) === null || ref1 === void 0 ? void 0 : ref1.call(ref);
            },
            closeDialog: function() {
                var ref, ref1, ref2, ref3;
                (ref = document.querySelector('div:not([style*="visibility: hidden"]) [role=dialog] button')) === null || ref === void 0 ? void 0 : (ref1 = ref.click) === null || ref1 === void 0 ? void 0 : ref1.call(ref);
                (ref2 = document.querySelector('[data-appid="image-gallery-mobile-close-button"]')) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.click) === null || ref3 === void 0 ? void 0 : ref3.call(ref2);
            },
            fecthCachedZipcode: function() {
                try {
                    var ref, ref1;
                    var text = (ref = JSON.parse(window.localStorage.getItem("location") || "")) === null || ref === void 0 ? void 0 : (ref1 = ref.state) === null || ref1 === void 0 ? void 0 : ref1.zipCode;
                    if (text) {
                        var zipcode = parseInt(text, 10);
                        emitEvent({
                            type: "cached_zipcode",
                            value: zipcode
                        });
                    }
                } catch (e) {
                    emitEvent({
                        type: "cached_zipcode",
                        value: undefined
                    });
                // ignore
                }
            },
            fetchZipcode: function() {
                var ref, ref1;
                var text = document === null || document === void 0 ? void 0 : (ref = document.querySelector) === null || ref === void 0 ? void 0 : (ref1 = ref.call(document, "button p")) === null || ref1 === void 0 ? void 0 : ref1.innerHTML;
                if (text) {
                    var zipcode = parseInt(text, 10);
                    emitEvent({
                        type: "zipcode",
                        value: zipcode
                    });
                } else {
                    emitEvent({
                        type: "zipcode"
                    });
                }
            }
        },
        pdp: {
            showContinueFooter: function() {
                var el = document.querySelector('[data-appid="sticky-bar"]');
                if (el) {
                    el.style.display = "block";
                }
            },
            hideContinueFooter: function() {
                var el = document.querySelector('[data-appid="sticky-bar"]');
                if (el) {
                    el.style.display = "none";
                }
            },
            closeFullScreenGallery: function() {
                var ref, ref1;
                (ref = document.querySelector("[data-appid='image-gallery-mobile-close-button']")) === null || ref === void 0 ? void 0 : (ref1 = ref.click) === null || ref1 === void 0 ? void 0 : ref1.call(ref);
            }
        }
    };
};
