import _define_property from "@swc/helpers/src/_define_property.mjs";
import { makeStyles } from "@material-ui/core";
export var useModalStyles = makeStyles(function(theme) {
    return {
        paperRoot: _define_property({}, theme.breakpoints.up("sm"), {
            maxWidth: "548px"
        }),
        iconButtonRoot: {
            position: "absolute",
            right: theme.spacing(2),
            top: theme.spacing(1.5)
        },
        headline: {
            textAlign: "center",
            marginTop: theme.spacing(4.5)
        },
        dialogContent: _define_property({
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: theme.spacing(9.5, 3, 0)
        }, theme.breakpoints.up("sm"), {
            padding: theme.spacing(4.5, 9, 15),
            minWidth: 548
        }),
        dialogContentEmission: _define_property({
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: theme.spacing(9.5, 3, 0)
        }, theme.breakpoints.up("sm"), {
            padding: theme.spacing(0, 6, 6),
            minWidth: 548
        }),
        dialogHeaderEmission: _define_property({
            display: "flex",
            justifyContent: "center",
            padding: theme.spacing(3, 2)
        }, theme.breakpoints.up("sm"), {
            minWidth: 548
        }),
        dialogHeader: _define_property({
            display: "flex",
            justifyContent: "center",
            padding: theme.spacing(3, 2, 8, 2)
        }, theme.breakpoints.up("sm"), {
            minWidth: 548
        }),
        button: _define_property({
            width: "100%",
            padding: theme.spacing(2, 0)
        }, theme.breakpoints.up("sm"), {
            width: 200
        }),
        buttonWrapper: _define_property({
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: theme.spacing(7)
        }, theme.breakpoints.up("sm"), {
            marginTop: theme.spacing(4)
        }),
        icon: {
            width: 56,
            height: 56,
            maxWidth: 155,
            maxHeight: 155
        },
        successMessage: _define_property({
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            marginTop: theme.spacing(7)
        }, theme.breakpoints.up("sm"), {
            marginTop: theme.spacing(4)
        }),
        checkedIcon: {
            marginBottom: theme.spacing(0.5)
        }
    };
});
