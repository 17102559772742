import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { BASE_SUBSCRIPTION_PATH } from "@finn/ua-constants";
import set from "lodash/set";
// Adjusting the subscriptions links to take to the b2b product listing page when user is logged in as b2b client (so that b2b clients see personalised prices):
export var updateNavLinkBlockForB2B = function(linkBlock) {
    var ref, ref1;
    var updatedPLPLink = "/".concat(BASE_SUBSCRIPTION_PATH);
    var updatedLinkBlock = _object_spread({}, linkBlock);
    var navSections = linkBlock === null || linkBlock === void 0 ? void 0 : (ref = linkBlock.menu) === null || ref === void 0 ? void 0 : (ref1 = ref.metadata) === null || ref1 === void 0 ? void 0 : ref1.sections;
    var updatedNavSections = navSections === null || navSections === void 0 ? void 0 : navSections.map(function(section) {
        if (section.metadata.type === "benefits") set(section, "metadata.content.cta_button.href", updatedPLPLink);
        return section;
    });
    set(updatedLinkBlock, "menu.metadata.sections", updatedNavSections);
    set(updatedLinkBlock, "href", updatedPLPLink);
    return updatedLinkBlock;
};
