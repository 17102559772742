import { useEffect, useState } from "react";
require("intersection-observer");
export var MAX_SCREEN_SIZE = "4000px"; // not importing from finn atom as it's creating a circular dependency
export var useIntersectionObserver = function(ref) {
    var threshold = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 0, rootMargin = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : "".concat(MAX_SCREEN_SIZE, " ").concat(MAX_SCREEN_SIZE, " ").concat(MAX_SCREEN_SIZE, " ").concat(MAX_SCREEN_SIZE), unobserve = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : false;
    var _$ref = useState(false), isInView = _$ref[0], setInView = _$ref[1];
    useEffect(function() {
        var current = ref.current;
        if (!current) {
            return;
        }
        var intersectionObserver = new IntersectionObserver(function(entries) {
            var isIntersecting = entries.length > 0 && entries[0].isIntersecting;
            setInView(isIntersecting);
            if (entries.length > 0 && !entries[0].isIntersecting) return;
            if (unobserve) intersectionObserver.unobserve(current);
        }, {
            threshold: threshold,
            rootMargin: rootMargin
        });
        intersectionObserver.observe(current);
        return function() {
            if (current) {
                intersectionObserver.unobserve(current);
            }
        };
    }, [
        ref,
        threshold,
        rootMargin
    ]);
    return isInView;
};
