import _define_property from "@swc/helpers/src/_define_property.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import { getAppSDK } from "../../helpers";
import { getGlobalObject } from "../helpers/globalObject";
import { Pages } from "./constants";
var handleTelephoneLinkPress = function(element) {
    return function(event) {
        var ref;
        event.preventDefault();
        (ref = getAppSDK()) === null || ref === void 0 ? void 0 : ref.sendMessageToApp({
            type: "event:tel_link_pressed",
            value: element.href
        });
    };
};
var handleEmailLinkPress = function(element) {
    return function(event) {
        var ref;
        event.preventDefault();
        (ref = getAppSDK()) === null || ref === void 0 ? void 0 : ref.sendMessageToApp({
            type: "event:email_link_pressed",
            value: element.href
        });
    };
};
var handleHelplinePress = function(divId) {
    var telephoneLink = document.querySelector("[data-appid=".concat(divId, "] a[href^=tel]"));
    if (telephoneLink) {
        telephoneLink.onclick = handleTelephoneLinkPress(telephoneLink);
    }
};
var handleHelpEmailPress = function() {
    var emailLink = document.querySelector("[data-appid='flipper-module-content'] > p:nth-child(6) > a:nth-child(1)");
    if (emailLink) {
        emailLink.onclick = handleEmailLinkPress(emailLink);
    }
};
var _obj;
export var PageHandlersWhenInteractive = (_obj = {}, _define_property(_obj, Pages.PDP, function() {
    handleHelplinePress("flipper-module-content");
    handleHelpEmailPress();
}), _define_property(_obj, Pages.Checkout, function() {
    handleHelplinePress("checkout-header");
}), _obj);
// fallback to hide close modal button for the browsers, which don't support :has() pseudo-class
var hideCloseDialogButton = function() {
    var dialog = document.querySelector("[role='dialog']");
    if (dialog) {
        var closeIcon = document.querySelectorAll(".menu-close-icon");
        // if modal is open and the close icon is present
        if (closeIcon.length > 0) {
            var button = dialog.querySelectorAll("button:not([type='submit'])")[0];
            button.style.visibility = "hidden";
        }
    }
};
var setupPDPHandler = function() {
    var observer = new MutationObserver(function(mutationList) {
        var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
        try {
            for(var _iterator = mutationList[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
                var mutation = _step.value;
                var ref, ref1;
                var containerDivEl = (ref = mutation.addedNodes) === null || ref === void 0 ? void 0 : ref[0];
                var childElement = containerDivEl === null || containerDivEl === void 0 ? void 0 : (ref1 = containerDivEl.childNodes) === null || ref1 === void 0 ? void 0 : ref1[0];
                if (childElement) {
                    hideCloseDialogButton();
                }
            }
        } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
        } finally{
            try {
                if (!_iteratorNormalCompletion && _iterator.return != null) {
                    _iterator.return();
                }
            } finally{
                if (_didIteratorError) {
                    throw _iteratorError;
                }
            }
        }
    });
    // Start observing the target node for configured mutations
    var bodyEl = document.querySelector("body");
    if (bodyEl) {
        observer.observe(bodyEl, {
            childList: true
        });
    }
};
var setupPaymentHandler = function() {
    var MAX_DEPTH = 5;
    var isContinueButtuonClicked = function(target) {
        var depth = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 0;
        var ref;
        if (((ref = target.dataset) === null || ref === void 0 ? void 0 : ref.cy) === "submit") {
            return true;
        }
        if (depth > MAX_DEPTH || !target.parentElement) {
            return false;
        }
        return isContinueButtuonClicked(target.parentElement, depth + 1);
    };
    document.addEventListener("click", function(e) {
        if (e.target && isContinueButtuonClicked(e.target)) {
            var isACHSelected = _to_consumable_array(document.querySelectorAll('[data-cy="payment-method-stripe_debit_ach"] circle')).length > 1;
            if (isACHSelected) {
                var ref, ref1;
                e.stopPropagation();
                e.preventDefault();
                var globalObject = getGlobalObject();
                (ref = getAppSDK()) === null || ref === void 0 ? void 0 : ref.sendMessageToApp({
                    type: "event:continue_payment",
                    value: "ACH",
                    href: (ref1 = globalObject.location) === null || ref1 === void 0 ? void 0 : ref1.href
                });
            }
        }
    });
};
var containerDivElRef;
var iframeTypeRef;
var getIframeType = function(url) {
    if (url.match(/.*stripe.com.*linked-accounts-inner.*/)) {
        return "ACH";
    }
    if (url.match(/.*stripe.com.*three-ds-2.*/)) {
        return "3DS";
    }
    return "unknown";
};
var setupCheckoutHandler = function() {
    var observer = new MutationObserver(function(mutationList) {
        var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
        try {
            for(var _iterator = mutationList[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
                var mutation = _step.value;
                var ref, ref1, ref2;
                var containerDivEl = (ref = mutation.addedNodes) === null || ref === void 0 ? void 0 : ref[0];
                var childElement = containerDivEl === null || containerDivEl === void 0 ? void 0 : (ref1 = containerDivEl.childNodes) === null || ref1 === void 0 ? void 0 : ref1[0];
                if (childElement) {
                    hideCloseDialogButton();
                }
                if ((childElement === null || childElement === void 0 ? void 0 : childElement.nodeName) === "IFRAME") {
                    var ref3;
                    var iframeType = getIframeType(childElement.src);
                    if (iframeType === "unknown") {
                        return;
                    }
                    containerDivElRef = containerDivEl;
                    iframeTypeRef = iframeType;
                    (ref3 = getAppSDK()) === null || ref3 === void 0 ? void 0 : ref3.sendMessageToApp({
                        type: "event:iframe_opened",
                        value: iframeType
                    });
                }
                var removedDivEl = (ref2 = mutation.removedNodes) === null || ref2 === void 0 ? void 0 : ref2[0];
                if (removedDivEl && removedDivEl === containerDivElRef) {
                    var ref4;
                    (ref4 = getAppSDK()) === null || ref4 === void 0 ? void 0 : ref4.sendMessageToApp({
                        type: "event:iframe_closed",
                        value: iframeTypeRef
                    });
                }
            }
        } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
        } finally{
            try {
                if (!_iteratorNormalCompletion && _iterator.return != null) {
                    _iterator.return();
                }
            } finally{
                if (_didIteratorError) {
                    throw _iteratorError;
                }
            }
        }
    });
    // Start observing the target node for configured mutations
    var bodyEl = document.querySelector("body");
    if (bodyEl) {
        observer.observe(bodyEl, {
            childList: true
        });
        setupPaymentHandler();
    }
};
var _obj1;
export var PageHandlersWhenComplete = (_obj1 = {}, _define_property(_obj1, Pages.PDP, function() {
    setupPDPHandler();
}), _define_property(_obj1, Pages.Checkout, function() {
    setupCheckoutHandler();
}), _obj1);
