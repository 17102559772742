import { CookieKeys, getClientCookie } from "./cookies";
import { capitalizeFirstLetter } from "./strings";
// formatted utm source to be stored in the cookies
export var formatUtmSourceValue = function(dataSource) {
    var formattedDataSource = dataSource;
    if (formattedDataSource === "meta") {
        formattedDataSource = "Facebook";
    }
    return encodeURIComponent("Inbound - ".concat(capitalizeFirstLetter(formattedDataSource)));
};
export var getB2BFormSource = function(formName) {
    var ref;
    return decodeURIComponent((ref = getClientCookie(CookieKeys.UTM_SOURCE)) !== null && ref !== void 0 ? ref : "") || "Inbound - ".concat(formName);
};
