import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import { compareVersions } from "compare-versions";
import { getCurrentPagePath, getCurrentPageType } from "../connectors/constants";
import { getGlobalObject } from "../helpers/globalObject";
import { isColorChangedFromTheSameVehicle } from "../helpers/pdp";
var originalNextJSRouterPush;
var originalNextJSRouterReplace;
// remove after release of version 1.47.x
var isDirectCheckoutConfirmationURL = function(url) {
    return url.includes("is_direct_checkout=true") && url.includes("checkout/confirmation");
};
export var getNextUrl = function(param) {
    var url = param.url, screenName = param.screenName, version = param.version;
    if (compareVersions(version, "1.47.0") < 0 && !url.includes(getCurrentPagePath(screenName))) {
        return url;
    }
    if (url && !url.includes("/mobile")) {
        var urlArr = url.split("/");
        if (urlArr.includes("de-DE") || url.includes("en-US")) {
            urlArr.splice(2, 0, "mobile");
        } else {
            urlArr.splice(1, 0, "mobile");
        }
        return urlArr.join("/");
    }
    return url;
};
export var getWebInternalPageType = function(params) {
    var ref, ref1, ref2;
    var url = typeof params === "string" ? params : params.pathname;
    var isHome = url.endsWith("/mobile") || url.endsWith("/mobile/") || url.endsWith("/mobile?") || url.endsWith("/mobile/?") || url.includes("/home");
    if (isHome) {
        return "home";
    }
    var isPDP = url.includes("pdp") && !url.includes("[vehicleId]");
    if (isPDP) {
        return "pdp";
    }
    var isThankYou = url.includes("thank_you");
    if (isThankYou) {
        return "thank_you";
    }
    var isOrderStatus = url.includes("order-status");
    if (isOrderStatus) {
        return "order_status";
    }
    var isCheckout = url.includes("checkout");
    if (isCheckout) {
        return "checkout";
    }
    var isContactDetails = url.includes("contactdetails");
    if (isContactDetails) {
        return "contactdetails";
    }
    var isChangePassword = url.includes("changepassword");
    if (isChangePassword) {
        return "changepassword";
    }
    var isCompanyDetails = url.includes("companydetails");
    if (isCompanyDetails) {
        return "companydetails";
    }
    var isContact = url.includes("contact");
    if (isContact) {
        return "contact";
    }
    var isMyAccount = url.includes("myaccount");
    if (isMyAccount) {
        return "myaccount";
    }
    var isVeriff = url.includes("veriff");
    if (isVeriff) {
        return "veriff";
    }
    var ref3 = _sliced_to_array(url.split("/").reverse() || [], 2), myCarDealId = ref3[0], myCarPagePath = ref3[1];
    var isMyCar = myCarDealId && myCarPagePath === "mycars";
    if (isMyCar) {
        return "my_car";
    }
    var ref4 = _sliced_to_array(url.split("/").reverse() || [], 2), returnPagePath = ref4[0], returnDealId = ref4[1];
    var isReturn = returnDealId && returnPagePath === "return";
    if (isReturn) {
        return "return";
    }
    var isError = url.includes("/404");
    if (isError) {
        return "error";
    }
    var ref5 = _sliced_to_array(((ref2 = (ref = url.split("?")) === null || ref === void 0 ? void 0 : (ref1 = ref[0]) === null || ref1 === void 0 ? void 0 : ref1.split("/")) === null || ref2 === void 0 ? void 0 : ref2.reverse()) || [], 2), servicePagePath = ref5[0], dealId = ref5[1];
    var isSchedule = dealId && servicePagePath === "schedule-service";
    if (isSchedule) {
        return "schedule_service";
    }
    var isReportDamage = dealId && servicePagePath === "report-damage";
    if (isReportDamage) {
        return "report_damage";
    }
    var isPLP = url.includes("subscribe");
    var isLanding = !isPLP && !url.includes("[vehicleId]") && !url.includes("home");
    if (isLanding) {
        return "landing";
    }
    if (isPLP) {
        return "plp";
    }
    return "unknown";
};
export var isDealsLanding = function(url) {
    var dealsRegex = /\/[(\w\-)]*deals/;
    if (dealsRegex.test(url) || url.includes("[...slug]") && url.includes("deals")) {
        return true;
    }
    if (url.includes("partnerships/paypal")) {
        return true;
    }
    return false;
};
export var isRedirectOnTheSameLanding = function(param) {
    var currentURL = param.currentURL, url = param.url;
    if (url.includes("[...slug]") || url.includes("[slug]") && url.includes("/mobile") || isDealsLanding(url)) {
        return true;
    }
    var singleSlashCurrentURL = currentURL.replace(/\/\//g, "/");
    if (url.endsWith("?")) {
        return singleSlashCurrentURL.includes(url.replace("?", ""));
    }
    return false;
};
var isLandingNavigationAllowed = function(params) {
    var url = typeof params === "string" ? params : params.pathname;
    return isRedirectOnTheSameLanding({
        currentURL: window.location.href,
        url: url
    });
};
export var isWebNavigationAllowed = function(params, props) {
    var type = getWebInternalPageType(params);
    if (type === "error") {
        return true;
    }
    // in case of PDP and in case of withWebPDPColorChange
    // we want to do color change web only(no native navigation in the app)
    if (type === "pdp" && props.withWebPDPColorChange && isColorChangedFromTheSameVehicle(params)) {
        return true;
    }
    if (getCurrentPageType(props.screenName) === "landing") {
        return isLandingNavigationAllowed(params);
    }
    if (props.allowSamePageWebNavigation && getCurrentPageType(props.screenName) === type) {
        return true;
    }
    return type === "unknown";
};
var getRedirectAwareLink = function(url) {
    return url.includes("loginredirect") ? "".concat(window.origin).concat(url) : url;
};
export var setupWebRouting = function(emitEvent, props) {
    var ref;
    var globalObject = getGlobalObject();
    if (!originalNextJSRouterPush && globalObject.next) {
        var ref1, ref2;
        originalNextJSRouterPush = globalObject === null || globalObject === void 0 ? void 0 : (ref1 = globalObject.next) === null || ref1 === void 0 ? void 0 : ref1.router.push;
        originalNextJSRouterReplace = globalObject === null || globalObject === void 0 ? void 0 : (ref2 = globalObject.next) === null || ref2 === void 0 ? void 0 : ref2.router.replace;
        globalObject.next.router.events.on("routeChangeStart", function(url) {
            emitEvent({
                type: "navigation",
                // remove ternary operator and just use "url" after release of version 1.47.x
                url: isDirectCheckoutConfirmationURL(url) ? url.replace("checkout/confirmation", "checkout/contact") : getRedirectAwareLink(url),
                page_type: url.includes("loginredirect") ? "pdf" : getWebInternalPageType(url)
            });
        });
        globalObject.next.router.push = function push(route, as, params) {
            var url = typeof route === "string" ? route : route.pathname;
            // we do not allow pdp screen, instead we send message to web
            if (!isWebNavigationAllowed(url, props)) {
                emitEvent({
                    type: "navigation",
                    // remove ternary operator and just use "url" after release of version 1.47.x
                    url: isDirectCheckoutConfirmationURL(url) ? url.replace("checkout/confirmation", "checkout/contact") : getRedirectAwareLink(url),
                    page_type: url.includes("loginredirect") ? "pdf" : getWebInternalPageType(url)
                });
                if (compareVersions(props.version, "1.47.0") < 0) {
                    return Promise.resolve();
                }
            }
            if (!url.includes("/mobile/")) {
                return (originalNextJSRouterPush === null || originalNextJSRouterPush === void 0 ? void 0 : originalNextJSRouterPush.bind(this))(getNextUrl({
                    url: url,
                    screenName: props.screenName,
                    version: props.version
                }), as ? getNextUrl({
                    url: as,
                    screenName: props.screenName,
                    version: props.version
                }) : as, params);
            }
            return (originalNextJSRouterPush === null || originalNextJSRouterPush === void 0 ? void 0 : originalNextJSRouterPush.bind(this))(url, as, params);
        };
        globalObject.next.router.replace = function replace(route, as, params) {
            var url = typeof route === "string" ? route : route.pathname;
            // we do not allow pdp screen, instead we send message to web
            if (!isWebNavigationAllowed(url, props)) {
                emitEvent({
                    type: "navigation",
                    // remove ternary operator and just use "url" after release of version 1.47.x
                    url: isDirectCheckoutConfirmationURL(url) ? url.replace("checkout/confirmation", "checkout/contact") : getRedirectAwareLink(url),
                    page_type: url.includes("loginredirect") ? "pdf" : getWebInternalPageType(url)
                });
                if (compareVersions(props.version, "1.47.0") < 0) {
                    return Promise.resolve();
                }
            }
            if (!url.includes("/mobile")) {
                return (originalNextJSRouterReplace === null || originalNextJSRouterReplace === void 0 ? void 0 : originalNextJSRouterReplace.bind(this))(getNextUrl({
                    url: url,
                    screenName: props.screenName,
                    version: props.version
                }), as, params);
            }
            return (originalNextJSRouterReplace === null || originalNextJSRouterReplace === void 0 ? void 0 : originalNextJSRouterReplace.bind(this))(url, as, params);
        };
    }
    return globalObject === null || globalObject === void 0 ? void 0 : (ref = globalObject.next) === null || ref === void 0 ? void 0 : ref.router;
};
