export var Pages = {
    PLP: "plp",
    PDP: "pdp",
    Checkout: "checkout",
    Home: "home",
    ThankYou: "thank_you",
    OrderStatus: "order_status",
    MyCars: "my_cars",
    MyCar: "my_car",
    Auth: "auth",
    Landing: "landing",
    ReportDamage: "report_damage",
    ScheduleService: "schedule_service",
    Return: "return"
};
var PagePathForScreen = {
    PLP: "subscribe",
    PDP: "pdp",
    Checkout: "checkout",
    Home: "",
    ThankYou: "thank_you",
    OrderStatus: "order_status",
    MyCars: "mycars",
    MyCar: "mycars/",
    Landing: "landing"
};
export var getCurrentPageType = function(screenName) {
    return Pages[screenName];
};
export var getCurrentPagePath = function(screenName) {
    return PagePathForScreen[screenName];
};
