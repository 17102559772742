export var CosmicModuleType;
(function(CosmicModuleType) {
    CosmicModuleType["WEB_MODULE_CAR_PROMO"] = "web-module-carpromos";
    CosmicModuleType["WEB_MODULE_VERTICAL_TABS"] = "web-module-verticaltabs";
    CosmicModuleType["WEB_MODULE_HERO"] = "web-module-heroes";
    CosmicModuleType["WEB_MODULE_HERO_2"] = "web-module-heroes-2";
    CosmicModuleType["WEB_MODULE_HERO_VIDEO"] = "web-module-hero-fullscreen-video";
    CosmicModuleType["WEB_MODULE_TEXT"] = "web-module-texts";
    CosmicModuleType["WEB_MODULE_FLIPPER"] = "web-module-flippers";
    CosmicModuleType["WEB_MODULE_SLIDER"] = "web-module-sliders";
    CosmicModuleType["WEB_MODULE_CUSTOMS"] = "web-module-customs";
    CosmicModuleType["WEB_MODULE_PROMO"] = "web-module-promos";
    CosmicModuleType["WEB_MODULE_DYNAMIC_PROMO"] = "web-module-dynamic-promos";
    CosmicModuleType["WEB_MODULE_STEPPER"] = "web-module-steppers";
    CosmicModuleType["WEB_MODULE_DATA_DASHBOARD"] = "web-module-data-tables";
    CosmicModuleType["WEB_MODULE_BRAND_SLIDER"] = "web-module-brandsliders";
    CosmicModuleType["WEB_MODULE_CUSTOM_BRAND_SLIDER"] = "web-module-custom-brandsliders";
    CosmicModuleType["WEB_MODULE_TRUSTPILOT"] = "web-module-trustpilots";
    CosmicModuleType["WEB_MODULE_ACCORDION"] = "web-module-accordions";
    CosmicModuleType["WEB_MODULE_ACCORDION_OLD"] = "faqs";
    CosmicModuleType["WEB_MODULE_COMPARISON"] = "web-module-comparison";
    CosmicModuleType["PRODUCT_SLIDER"] = "sliders";
    CosmicModuleType["WEB_MODULE_GALLERY"] = "web-module-galleries";
    CosmicModuleType["WEB_MODULE_VIDEOS"] = "web-module-videos";
    CosmicModuleType["WEB_MODULE_VERTICAL_LIST"] = "web-module-verticallists";
    CosmicModuleType["MENU_INTRO"] = "intro";
    CosmicModuleType["MENU_BENEFITS"] = "benefits";
    CosmicModuleType["MENU_POPULAR_BRANDS"] = "popular-brands";
    CosmicModuleType["MENU_POPULAR_MODELS"] = "popular-models";
    CosmicModuleType["MENU_LIST"] = "list";
    CosmicModuleType["MENU_CARD"] = "card";
    CosmicModuleType["WEB_MODULE_HTMLS"] = "web-module-htmls";
    CosmicModuleType["WEB_MODULE_FORMS"] = "web-module-forms";
    CosmicModuleType["WEB_MODULE_SUSTAINABILITY"] = "web-module-sustainability";
    CosmicModuleType["WEB_MODULE_CHOICE_PAIR"] = "web-module-choice-pair";
    CosmicModuleType["WEB_MODULE_HERO_STICKY_NAVS"] = "web-module-hero-sticky-navs";
    CosmicModuleType["WEB_MODULE_STEP_COUNTER"] = "web-module-steps-counters";
    CosmicModuleType["WEB_MODULE_EMAIL_COLLECTION_FORMS"] = "email-collection-forms";
    CosmicModuleType["MENU_B2B_INTRO"] = "b2b-intro";
    CosmicModuleType["MENU_B2B_PROGRAMS_LIST"] = "b2b-programs-list";
    CosmicModuleType["MENU_B2B_PROGRAMS_CARDS"] = "b2b-programs-cards";
    CosmicModuleType["WEB_MODULE_SUBSCRIPTION_SECTION"] = "web-module-subscription-section";
    CosmicModuleType["WEB_MODULE_BLURB"] = "web-module-blurb";
    CosmicModuleType["WEB_MODULE_LOYALTY"] = "web-module-loyalties";
    CosmicModuleType["WEB_MODULE_LOYALTY_EARNING_POINTS"] = "loyalty-earning-points";
    CosmicModuleType["WEB_MODULE_CAMPAIGN_CARD"] = "web-module-campaign-card";
    CosmicModuleType["WEB_MODULE_TESTIMONIALS"] = "web-module-testimonials";
    CosmicModuleType["WEB_MODULE_PROMO_BANNERS"] = "web-module-promo-banners";
    CosmicModuleType["WEB_MODULE_PRODUCT_GRID"] = "web-module-productgrid";
    CosmicModuleType["WEB_MODULE_CONTENT_EXPERIMENT"] = "web-module-content-experiment";
    CosmicModuleType["WEB_MODULE_MAP"] = "web-module-maps";
    CosmicModuleType["WEB_MODULE_TABLE_OF_CONTENTS"] = "web-module-table-of-contents";
    CosmicModuleType["WEB_MODULE_STICKY_CTA_PANEL"] = "web-module-sticky-cta-panel";
    CosmicModuleType["WEB_SELF_SERVICE_MODULES"] = "self-service-modules";
    CosmicModuleType["WEB_SELF_SERVICE_COMPONENTS"] = "self-service-components";
    CosmicModuleType["WEB_SELF_SERVICE_TEXTBLOCK"] = "textblock";
    CosmicModuleType["WEB_SELF_SERVICE_BANNERS"] = "banners";
    CosmicModuleType["WEB_SELF_SERVICE_FIELDS"] = "fields";
})(CosmicModuleType || (CosmicModuleType = {}));
export var ResponsiveImageSizes;
(function(ResponsiveImageSizes) {
    ResponsiveImageSizes["SMALL"] = "small";
    ResponsiveImageSizes["MEDIUM"] = "medium";
    ResponsiveImageSizes["LARGE"] = "large";
})(ResponsiveImageSizes || (ResponsiveImageSizes = {}));
