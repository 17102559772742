import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { MODAL_UNMOUNT_DELAY_MS, ModalHeaderVariant, ModalKey } from "./constants";
import { createModalContainer } from "./container";
import { createModalHooks } from "./hooks";
import { createModalStore } from "./store";
export function createModals(options) {
    var store = createModalStore();
    var hooks = createModalHooks(store);
    var ModalContainer = createModalContainer(hooks, options === null || options === void 0 ? void 0 : options.container);
    return _object_spread_props(_object_spread({}, hooks), {
        ModalContainer: ModalContainer
    });
}
var ref = createModals({
    container: {
        unmountDelay: MODAL_UNMOUNT_DELAY_MS
    }
}), ModalContainer = ref.ModalContainer, useOpenModal = ref.useOpenModal, useCloseModal = ref.useCloseModal, useCloseAllModals = ref.useCloseAllModals, useIsModalOpen = ref.useIsModalOpen, useIsAnyModalOpen = ref.useIsAnyModalOpen, useOnModalOpen = ref.useOnModalOpen, useOnModalClose = ref.useOnModalClose;
export { ModalKey, ModalHeaderVariant, ModalContainer, useOpenModal, useCloseModal, useCloseAllModals, useIsModalOpen, useIsAnyModalOpen, useOnModalOpen, useOnModalClose };
