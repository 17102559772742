import _define_property from "@swc/helpers/src/_define_property.mjs";
import { makeStyles } from "@material-ui/core";
export var useStyles = makeStyles(function(theme) {
    var _obj;
    return {
        root: (_obj = {
            minWidth: 300
        }, _define_property(_obj, theme.breakpoints.up("sm"), {
            minWidth: 400
        }), _define_property(_obj, "padding", theme.spacing(4, 3)), _define_property(_obj, "display", "flex"), _define_property(_obj, "flexDirection", "column"), _define_property(_obj, "gap", theme.spacing(4)), _define_property(_obj, "borderRadius", "4px"), _define_property(_obj, "height", "100%"), _obj),
        headerBlock: {
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(2)
        },
        howToContent: {
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(3)
        },
        blur: {
            filter: "blur(5px)"
        },
        iconBlock: {
            display: "flex"
        },
        icon: {
            width: 40,
            height: 40
        },
        benefitList: {
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(3)
        },
        plus: {
            marginTop: theme.spacing(2)
        },
        benefitItem: {
            display: "flex",
            gap: theme.spacing(1),
            alignItems: "center"
        },
        light: {
            fontWeight: 300
        }
    };
});
