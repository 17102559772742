import _extends from "@swc/helpers/src/_extends.mjs";
import config from "./config";
export var getCloudinaryImgUrl = function(url) {
    var _param = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    var options = _extends({}, _param);
    if (!url) return url;
    /*
    For some reason, CosmicJS is returning imgix urls instead of cdn urls sometimes.
    for this we need to replace imgix with cdn.
  */ if (url.includes("imgix.cosmicjs")) {
        url = url.replace("imgix.cosmicjs", "cdn.cosmicjs");
    }
    // Use original svg format and full quality for svg icons
    var fullListOptions = url.endsWith(".svg") || url.includes(".svg?") ? {
        q: 100,
        f: "svg"
    } : Object.assign({
        q: "auto",
        f: "auto",
        c: "limit",
        dpr: 2
    }, options);
    // Converts the fullListOptions map into a comma-separated options string.
    // e.g. {h: 200, w: 200, q: 'auto'} becomes h_200,w_200,q_auto.
    var paramsString = Object.entries(fullListOptions).map(function(option) {
        return "".concat(option[0], "_").concat(option[1]);
    }).join(",");
    return "".concat(config.CLOUDINARY.REMOTE_IMAGE_FETCH_URL).concat(paramsString, "/").concat(url);
};
// left 65% of the image (crop our the right 35%)
export var getCloudinaryImgCropLeft65 = function(url) {
    var paramsString = Object.entries({
        c: "crop",
        x: 0,
        y: 0,
        w: 0.65,
        h: 1
    }).map(function(option) {
        return "".concat(option[0], "_").concat(option[1]);
    }).join(",");
    return "".concat(config.CLOUDINARY.REMOTE_IMAGE_FETCH_URL).concat(paramsString, "/").concat(url);
};
export var getCloudinarySrcSet = function(url, widths) {
    var dpr = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : 2;
    var srcSet = widths.map(function(width) {
        return "".concat(getCloudinaryImgUrl(url, {
            w: width,
            dpr: dpr
        }), " ").concat(width, "w");
    });
    return srcSet.join(",\n");
};
export var getCloudinaryImgUrlWhiteSquare = function(url, size) {
    return getCloudinaryImgUrl(url, {
        w: size,
        dpr: 2,
        ar: "1:1",
        c: "lpad",
        b: "white"
    });
};
export var getCloudinaryImgUrlWhitePaddingAR = function(url, size, ar) {
    return getCloudinaryImgUrl(url, {
        w: size,
        dpr: 2,
        ar: ar,
        c: "lpad",
        b: "white"
    });
};
