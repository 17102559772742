import { useRouter } from "next/router";
import { useCallback } from "react";
import { CountryCode, DefaultLocale, FakeDefaultLocale, Locale } from "../types/localization";
import { setLocaleCookie } from "./cookies";
export function loadCurrentLocale(locale) {
    var messages = require("~/localization/compiled-locales/".concat(locale, ".json"));
    var region = locale.includes(CountryCode.US) ? CountryCode.US : CountryCode.DE;
    return {
        currency: region === CountryCode.US ? "$" : "€",
        messages: messages,
        locale: locale,
        region: region,
        isUSA: region === CountryCode.US
    };
}
export var getLocaleMetaData = function(locale) {
    // refactor function to support more regions
    var region = (locale === null || locale === void 0 ? void 0 : locale.includes(CountryCode.US)) ? CountryCode.US : CountryCode.DE;
    var localePrefix = locale !== FakeDefaultLocale ? "/".concat(locale) : "";
    return {
        currency: region === CountryCode.US ? "$" : "€",
        locale: locale === FakeDefaultLocale ? DefaultLocale : locale,
        region: region,
        isUSA: region === CountryCode.US,
        isDE: region === CountryCode.DE,
        localePrefix: localePrefix
    };
};
export function useLocaleChanger() {
    var router = useRouter();
    return useCallback(function(locale) {
        setLocaleCookie(locale);
        router.push("/", "/", {
            locale: locale
        });
    }, [
        router
    ]);
}
export var getLocaleFromContext = function(ctx) {
    return ctx.locale === FakeDefaultLocale ? DefaultLocale : ctx.locale || DefaultLocale;
};
export var useCurrentLocale = function() {
    var router = useRouter();
    return getLocaleMetaData(router.locale === FakeDefaultLocale ? DefaultLocale : router.locale || DefaultLocale);
};
/**
 * Enhances a given URL with the specified locale if it's a relative URL and not already localized.
 * @param url The URL to be enhanced with the locale.
 * @param locale The locale to add to the URL.
 * @returns The URL prefixed with the locale if it was a relative URL and not already localized.
 */ export function removeTrailingSlash(url) {
    return url.endsWith("/") ? url.slice(0, -1) : url;
}
export function localizeUrl(url, locale) {
    var hasHttpProtocol = url.startsWith("http");
    var samePageAnchorLink = url.startsWith("#");
    var supportedLocales = [
        Locale.ENGLISH_USA,
        Locale.ENGLISH_GERMANY,
        Locale.GERMAN_GERMANY, 
    ];
    var containsSupportedLocale = supportedLocales.some(function(sl) {
        return url.includes("/".concat(sl));
    });
    if (hasHttpProtocol || containsSupportedLocale || samePageAnchorLink) {
        return url;
    }
    var normalizedUrl = url.startsWith("/") ? url : "/".concat(url);
    return "/".concat(locale).concat(removeTrailingSlash(normalizedUrl));
}
