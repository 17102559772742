import { isServer } from "../helpers/webViewHelpers";
import { getGlobalObject } from "./helpers/globalObject";
import { createWebToAppSDK } from "./sdk";
export var run = function() {
    var /**
   * We expose onWebReadyToConnectToApp to garantee SDK back compability for older app versions
   *
   */ ref;
    var globalObject = getGlobalObject();
    globalObject === null || globalObject === void 0 ? void 0 : (ref = globalObject.onWebReadyToConnectToApp) === null || ref === void 0 ? void 0 : ref.call(globalObject, function(props) {
        var webToAppSDK = createWebToAppSDK(props);
        webToAppSDK.init();
        // we expose nativeAppSDK on window to allow native app to use it
        // it also used during e2e tests run
        globalObject.nativeAppSDK = webToAppSDK;
    });
};
if (!isServer()) {
    run();
}
