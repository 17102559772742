import _define_property from "@swc/helpers/src/_define_property.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _object_without_properties from "@swc/helpers/src/_object_without_properties.mjs";
import { errorMessages, TEST_EMAIL_SUFFIX } from "@finn/ua-constants";
import { B2B_FORM_SUBMISSION_EVENT, B2B_LEAD_GEN_FORM_SUBMISSION_EVENT, CHATBOT_SESSION_STARTED, CONTACT_US_FORM_SUBMISSION_EVENT, CONTACT_US_PAGE, ECCOMMERCE_EVENTS_PROPERTIES, interactionTrackingEvent, TRACKING_DESTINATIONS, trackingErrorHandler } from "@finn/ua-tracking";
import { getLongestTerm } from "@finn/ui-modules";
import { config, CountryCode, CURRENCIES, toString, trackEvent } from "@finn/ui-utils";
import debounce from "lodash/debounce";
import { getVehicleMonthlyPrice } from "./checkout";
import { kmOptions, mileageOptions } from "./pdp";
import { TrackingEventName } from "./tracking-types";
export * from "@finn/ua-tracking";
// no need to use this function on checkout flow after contact step
// as ajs_user_traits gets populated on contact submission, and we skip it anyway from the track function
export var skipTrackingTestEmail = function(email, cb) {
    var fallback = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : function() {};
    var hostName = window.location.hostname;
    var isProduction = hostName === "www.finn.auto" || hostName === "www.finn.com";
    if (!!email && (email === config.TRACKING_TEST_EMAIL || !isProduction || !email.includes(TEST_EMAIL_SUFFIX))) {
        cb();
    } else {
        // intentional console log to see if a track call was skipped because of a test email
        console.log("Test email detected. Skipping a tracking call");
        fallback();
    }
};
export var checkoutErrorEvent = function(message, step, error, statusCode, requestId) {
    interactionTrackingEvent(TrackingEventName.CHECKOUT_ERROR, {
        step: step,
        label: message || errorMessages.SERVER_ERROR,
        error: toString(error),
        statusCode: statusCode,
        requestId: requestId
    });
};
export var ecommerceTrackingEvent = function(eventName, properties) {
    var integrations = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, region = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : CountryCode.DE;
    var isUSA = region === CountryCode.US;
    var _eventName;
    var eventProperties = (_eventName = ECCOMMERCE_EVENTS_PROPERTIES[eventName]) !== null && _eventName !== void 0 ? _eventName : {};
    trackingErrorHandler(function() {
        trackEvent(eventName, _object_spread(_object_spread_props(_object_spread({}, properties), {
            currency: isUSA ? CURRENCIES.USD : CURRENCIES.EURO
        }), eventProperties), integrations);
    });
};
export var getEcommerceProduct = function(vehicle) {
    var productInfo = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    var position = productInfo.position, preDiscountPrice = productInfo.preDiscountPrice;
    var _term;
    var term = (_term = productInfo.term) !== null && _term !== void 0 ? _term : getLongestTerm(vehicle);
    var kmPackage = productInfo.kmPackage;
    // Get Selected monthly price
    var price = getVehicleMonthlyPrice(vehicle, term, kmPackage);
    // Handle optional fields
    var positionValue = position ? {
        position: position
    } : {
        position: 1
    };
    return _object_spread({
        product_id: vehicle.id,
        sku: vehicle.vehicle_identification_number,
        category: vehicle.cartype,
        name: "".concat(vehicle.brand.id, " ").concat(vehicle.model),
        brand: vehicle.brand.id,
        make: vehicle.brand.id,
        model: vehicle.model,
        variant: vehicle.color.specific,
        price: price,
        quantity: 1,
        url: "".concat(config.FINN_WEB_URL, "/pdp/").concat(vehicle.id),
        image_url: vehicle.picture.url,
        preDiscountPrice: preDiscountPrice
    }, positionValue);
};
export var referralModalTrackingEvent = function(eventName, properties) {
    trackingErrorHandler(function() {
        trackEvent(eventName, _object_spread({}, properties));
    });
};
export var triggerReferralTrackingEvent = function(param) {
    var eventName = param.eventName;
    referralModalTrackingEvent(eventName, null);
};
export var triggerReferralOptionEvent = function(param) {
    var eventName = param.eventName, channel = param.channel;
    referralModalTrackingEvent(eventName, {
        channel: channel
    });
};
export var triggerEcommerceOrderingTrackingEvent = function(_param) {
    var eventName = _param.eventName, vehicle = _param.vehicle, productInfo = _param.productInfo, coupon = _param.coupon, rest = _object_without_properties(_param, [
        "eventName",
        "vehicle",
        "productInfo",
        "coupon"
    ]);
    var ref;
    var ref1;
    ecommerceTrackingEvent(eventName, _object_spread(_object_spread_props(_object_spread({}, getEcommerceProduct(vehicle, productInfo), coupon && {
        coupon: coupon
    }), {
        productId: vehicle.id,
        offerType: vehicle.offer_type,
        coming_soon: vehicle.coming_soon,
        label: (ref1 = (ref = vehicle.product_label) === null || ref === void 0 ? void 0 : ref.label) !== null && ref1 !== void 0 ? ref1 : null,
        fuelType: vehicle.fuel
    }), rest));
};
export var triggerEcommerceCheckoutStepEvent = function(_param) {
    var eventName = _param.eventName, properties = _object_without_properties(_param, [
        "eventName"
    ]);
    ecommerceTrackingEvent(eventName, _object_spread_props(_object_spread({}, properties), {
        dealId: properties.checkout_id
    }));
};
// We need this to handle checkout complete for GTM as Segment destination
export var triggerEcommerceCheckoutCompleteEvent = function(_param) {
    var vehicles = _param.vehicles, productInfo = _param.productInfo, rest = _object_without_properties(_param, [
        "vehicles",
        "productInfo"
    ]);
    var products = vehicles.map(function(vehicle) {
        return getEcommerceProduct(vehicle, productInfo);
    });
    var price = rest.value;
    var tax = rest.tax;
    var revenue = price - tax;
    var properties = _object_spread_props(_object_spread({}, rest), {
        products: products,
        revenue: revenue,
        dealId: rest.order_id,
        tax: tax
    });
    ecommerceTrackingEvent(TrackingEventName.ORDER_COMPLETED, properties);
};
var ecommerceProductListView = function() {
    var impressions = {
        products: [],
        set: new Set(),
        listId: "",
        partnerDiscount: 0
    };
    var triggerProductListViewEvent = debounce(function() {
        ecommerceTrackingEvent(TrackingEventName.PRODUCT_LIST_VIEWED, {
            eventName: TrackingEventName.PRODUCT_LIST_VIEWED,
            products: impressions.products,
            listId: impressions.listId,
            category: impressions.listId,
            partnerDiscount: impressions.partnerDiscount
        }, _define_property({}, TRACKING_DESTINATIONS.FB, false));
    }, 1000);
    return function(param) {
        var vehicle = param.vehicle, list = param.list, _region = param.region, region = _region === void 0 ? CountryCode.DE : _region, _partnerDiscount = param.partnerDiscount, partnerDiscount = _partnerDiscount === void 0 ? 0 : _partnerDiscount;
        var isUSA = region === CountryCode.US;
        if (impressions.set.has(vehicle.id)) return;
        impressions.products.push(getEcommerceProduct(vehicle, {
            term: getLongestTerm(vehicle),
            kmPackage: isUSA ? mileageOptions[0] : kmOptions[0],
            position: impressions.products.length + 1
        }));
        impressions.set.add(vehicle.id);
        impressions.listId = list;
        impressions.partnerDiscount = partnerDiscount;
        triggerProductListViewEvent();
    };
};
export var triggerB2BFormSubmissionEvent = function(fleetSize, email) {
    interactionTrackingEvent(B2B_FORM_SUBMISSION_EVENT.name, {
        category: B2B_FORM_SUBMISSION_EVENT.category,
        action: B2B_FORM_SUBMISSION_EVENT.action,
        label: "Count of cars: ".concat(fleetSize),
        email: email,
        value: fleetSize
    });
};
export var triggerB2BLeadGenFormSubmissionEvent = function(fleetSize, email) {
    interactionTrackingEvent(B2B_LEAD_GEN_FORM_SUBMISSION_EVENT.name, {
        category: B2B_LEAD_GEN_FORM_SUBMISSION_EVENT.category,
        action: B2B_LEAD_GEN_FORM_SUBMISSION_EVENT.action,
        label: "Count of cars: ".concat(fleetSize),
        email: email,
        value: fleetSize
    });
};
export var triggerContactFormSubmissionEvent = function(email, requestSubject) {
    interactionTrackingEvent(CONTACT_US_FORM_SUBMISSION_EVENT.name, {
        category: CONTACT_US_FORM_SUBMISSION_EVENT.category,
        action: CONTACT_US_FORM_SUBMISSION_EVENT.action,
        label: "Contact Us Form Submitted",
        email: email,
        request_subject: requestSubject
    });
};
export var trackLogInBannerC2AClicked = function() {
    trackEvent(CONTACT_US_PAGE.LOGIN_BANNER_C2A_CLICKED, {});
};
export var trackRecommendedLinkClicked = function(linkText) {
    trackEvent(CONTACT_US_PAGE.RECOMMENDATION_LINK_CLICKED, {
        link_text: linkText
    });
};
export var trackContactFormChatbotSessionStarted = function(ultimateAiChatId) {
    trackEvent(CONTACT_US_PAGE.CONTACT_FORM_CHATBOT_SESSION_STARTED, {
        ultimateAiChatId: ultimateAiChatId
    });
};
export var trackChatbotSessionStarted = function(ultimateAiChatId) {
    trackEvent(CHATBOT_SESSION_STARTED, {
        ultimateAiChatId: ultimateAiChatId
    });
};
export var addNewProductListView = ecommerceProductListView();
export var trackFaqButtonClicked = function() {
    trackEvent(CONTACT_US_PAGE.FAQ_BUTTON_CLICKED, {});
};
export var trackContactUsButtonClicked = function() {
    trackEvent(CONTACT_US_PAGE.CONTACT_US_BUTTON_CLICKED, {});
};
