import { REFERRAL_VOUCHER_COOKIE_EXPIRATION } from '@finn/ua-constants';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ui-modules';
import {
  CookieKeys,
  getClientBooleanCookie,
  getClientCookie,
  setClientBooleanCookie,
} from '@finn/ui-utils';
import { useEffect } from 'react';

export const useReferralTracker = () => {
  useEffect(() => {
    const voucherCookieValue = getClientCookie(
      CookieKeys.REFERRAL_VOUCHER_CODE
    );
    const hasReferralVoucher = !!voucherCookieValue;
    const isReferralVoucherTracked = getClientBooleanCookie(
      CookieKeys.IS_REFERRAL_VOUCHER_TRACKED
    );

    if (hasReferralVoucher && !isReferralVoucherTracked) {
      setClientBooleanCookie(
        CookieKeys.IS_REFERRAL_VOUCHER_TRACKED,
        true,
        REFERRAL_VOUCHER_COOKIE_EXPIRATION
      );
      try {
        interactionTrackingEvent(TrackingEventName.REFERRAL_VOUCHER, {
          ...JSON.parse(decodeURIComponent(voucherCookieValue)),
        });
      } catch (error) {
        console.error('Error parsing or decoding referral voucher', error);
      }
    }
  }, []);
};
