import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _define_property from "@swc/helpers/src/_define_property.mjs";
import regeneratorRuntime from "/opt/build/repo/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { getLocaleMetaData, REQUEST_ID_HEADER_NAME } from "@finn/ui-utils";
import axios from "axios";
import Router from "next/router";
import { v4 as uuid } from "uuid";
import { getFirstResponseThatFits } from "./helpers";
var isBFFEndpoint = function(key) {
    var supportedBFFKeys = [
        "deals",
        "profile",
        "drivers",
        "companies",
        "deal/",
        "company/",
        "invoices",
        "order-status/",
        "handover/",
        "handover-metadata/",
        "return-status",
        "mileage_packages",
        "payment-methods",
        "schedule-service",
        "handover-metadata/",
        "return-metadata/",
        "handover/",
        "return/", 
    ];
    return supportedBFFKeys.some(function(supportedKey) {
        return key.startsWith(supportedKey) || supportedKey === key;
    });
};
export var dataFetcher = function(contactId, getEndpoint) {
    return function() {
        var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(key) {
            var region, isV2Version, paths, apiPaths, responsesPromises;
            return regeneratorRuntime.wrap(function _callee$(_ctx) {
                while(1)switch(_ctx.prev = _ctx.next){
                    case 0:
                        region = getLocaleMetaData((Router === null || Router === void 0 ? void 0 : Router.locale) || "").region;
                        isV2Version = key.startsWith("v2/");
                        paths = getEndpoint({
                            contactId: contactId
                        });
                        apiPaths = Array.isArray(paths) ? paths : [
                            paths
                        ];
                        responsesPromises = apiPaths.map(function(path) {
                            var controller = new AbortController();
                            var apiUrl = isV2Version ? "/api/self-service/api/v2/".concat(path) : "/".concat(region.toLocaleLowerCase(), "/api/self-service/api/v3/").concat(path);
                            // temporary hack till all deals migrated to use BFF
                            if (isBFFEndpoint(key)) {
                                apiUrl = "/".concat(path);
                            }
                            return {
                                request: axios(apiUrl, {
                                    headers: _define_property({}, REQUEST_ID_HEADER_NAME, uuid()),
                                    withCredentials: true
                                }).catch(function(error) {
                                    return error === null || error === void 0 ? void 0 : error.response;
                                }),
                                cancel: function() {
                                    return controller === null || controller === void 0 ? void 0 : controller.abort();
                                }
                            };
                        });
                        return _ctx.abrupt("return", getFirstResponseThatFits(responsesPromises));
                    case 6:
                    case "end":
                        return _ctx.stop();
                }
            }, _callee);
        }));
        return function(key) {
            return _ref.apply(this, arguments);
        };
    }();
};
