import Parser from "html-react-parser";
// Parser method throw an error when the content not a string
// This function jus to catch the error to prevent the broken pages
export var parseToHtml = function(htmlContent) {
    try {
        return Parser(htmlContent);
    } catch (e) {
        return "";
    }
};
export var getAnchorTarget = function(href) {
    return href.startsWith("http") ? "_blank" : "_self";
};
var SCROLL_TOP_OFFSET = 30;
export var scrollToTarget = function(target) {
    if (!target) return;
    var targetPosition = target.getBoundingClientRect().top;
    var offsetPosition = targetPosition + window.scrollY - SCROLL_TOP_OFFSET;
    window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
    });
};
export var scrollToTopWithoutAnimation = function() {
    window.scrollTo({
        top: 0,
        behavior: "auto"
    });
};
export var targetElementTopAboveViewport = function(target) {
    if (!target) {
        return null;
    }
    var targetTop = target.getBoundingClientRect().top;
    if (targetTop < 0) {
        return true;
    }
    return false;
};
export var targetElementTopBeyondThreshold = function(target) {
    var thresholdPercentage = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 50;
    if (!target) {
        return null;
    }
    var targetTop = target.getBoundingClientRect().top;
    var windowHeight = window.innerHeight;
    var targetTopPercentage = targetTop * 100 / windowHeight;
    // if thresholdPercentage is 50, then targetTopPercentage needs to be
    // greater than 50 to be beyond the threshold which will trigger the auto scroll
    return targetTopPercentage > thresholdPercentage;
};
