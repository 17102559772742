import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import { useCurrentLocale } from "@finn/ui-utils";
import { useModuleDefaults } from "../services/content/helpers";
// TODO refactor to support proper SSR
export var useCosmicModule = function(slug) {
    var locale = useCurrentLocale().locale;
    var ref = _sliced_to_array(useModuleDefaults(slug, locale), 1), content = ref[0];
    // we have no idea what type is in cosmic
    // so it is any (actually it is JSON)
    return content === null || content === void 0 ? void 0 : content.metadata;
};
