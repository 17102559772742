import _instanceof from "@swc/helpers/src/_instanceof.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
export function toString(value) {
    if (typeof value === "string") {
        return value;
    }
    try {
        var ref;
        if (typeof value === "object") {
            if (_instanceof(value, Map)) {
                return JSON.stringify(_to_consumable_array(value.entries()));
            }
            if (_instanceof(value, Set)) {
                return JSON.stringify(_to_consumable_array(value));
            }
            if (_instanceof(value, Error)) {
                return value === null || value === void 0 ? void 0 : value.message;
            }
            return JSON.stringify(value);
        }
        return (value === null || value === void 0 ? void 0 : (ref = value.toString) === null || ref === void 0 ? void 0 : ref.call(value)) || value || "";
    } catch (e) {
        return "exception while converting to string";
    }
}
