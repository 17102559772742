var CAR_REGISTRATION_STATUS;
(function(CAR_REGISTRATION_STATUS) {
    CAR_REGISTRATION_STATUS["PAPERS_RECEIVED"] = "papers_received";
    CAR_REGISTRATION_STATUS["IN_REGISTRATION"] = "in_registration";
    CAR_REGISTRATION_STATUS["REGISTERED"] = "registered";
    CAR_REGISTRATION_STATUS["DEREGISTERED"] = "deregistered";
    CAR_REGISTRATION_STATUS["LOCKED"] = "locked";
    CAR_REGISTRATION_STATUS[// US statuses
    "NOT_REGISTERED"] = "not_registered";
})(CAR_REGISTRATION_STATUS || (CAR_REGISTRATION_STATUS = {}));
var FUEL_TYPES;
(function(FUEL_TYPES) {
    FUEL_TYPES["PETROL"] = "petrol";
    FUEL_TYPES["DIESEL"] = "diesel";
    FUEL_TYPES["ELECTRIC"] = "electric";
    FUEL_TYPES["HYBRID_DIESEL"] = "hybrid_diesel";
    FUEL_TYPES["HYBRID_PETROL"] = "hybrid_petrol";
    FUEL_TYPES["HYBRID_PLUG_IN"] = "hybrid_plug_in";
})(FUEL_TYPES || (FUEL_TYPES = {}));
export { };
