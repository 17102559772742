import _define_property from "@swc/helpers/src/_define_property.mjs";
import { TrackingEventName } from "./types";
export var B2B_FORM_SUBMISSION_EVENT = {
    name: TrackingEventName.B2B_FORM_SUBMISSION,
    category: "Conversion",
    action: "B2B Submission"
};
export var B2B_LEAD_GEN_FORM_SUBMISSION_EVENT = {
    name: TrackingEventName.B2B_LEAD_GEN_FORM_SUBMISSION,
    category: "Conversion",
    action: "B2B Ad Campaign Form Submission"
};
export var CONTACT_US_FORM_SUBMISSION_EVENT = {
    name: TrackingEventName.CONTACT_US_FORM_SUBMISSION,
    category: "Conversion",
    action: "Contact Form Submission"
};
export var NAVIGATION_TRACKING_EVENTS = {
    MAIN: "main",
    CTA: "cta",
    TEASER: "teaser",
    LIST_ITEM: "listitem"
};
export var FILTER_EVENTS = {
    name: "Filter",
    category: "PLP Filters",
    action: {
        UI_INTERACTION: "UI Interaction",
        RESET_FILTER: "Reset Filter",
        SET_FILTER: "Set Filter"
    },
    label: {
        OPEN_MOBILE_DIALOG: "Open Mobile Dialog",
        CLOSE_MOBILE_DIALOG: "Close Mobile Dialog",
        CLOSE_FILTER_DIALOG: "Close Filter Dialog",
        RESET_FILTER_DIALOG: "Reset Filter Dialog",
        SUBMIT_FILTER_DIALOG: "Submit Filter Dialog",
        OPEN_FILTER_DROPDOWN: "Open filter dropdown",
        CLOSE_FILTER_DROPDOWN: "Close filter dropdown",
        ALL_FILTER: "All Filters",
        BRAND: "Brand",
        AVAILABILITY: "Availability",
        MODEL: "Model",
        OTHER: "Other",
        PRICE: "Price",
        COMING_SOON: "Coming Soon"
    }
};
export var USER_ACCOUNT_EVENTS = {
    name: "UserAccount",
    category: "User Accounts",
    action: {
        AUTHENTICATED: "Authenticated",
        USER_DATA: "User Data",
        ERROR: "Error",
        ACCOUNT_INTERACTION: "Account Interaction",
        ACCOUNT_MANAGEMENT: "Account Management"
    },
    label: {
        MAIL_PASSWORD: "Mail & Password",
        PREFILLED_CONTACT: "Prefilled Contact",
        EMAIL_ALREADY_EXIST: "Email Address is Existing",
        ICON_CLICKED: "Icon Clicked",
        LOGGED_OUT_CLICKED: "Logged Out Clicked",
        POPUP_TAB_CLICKED: "Pop-up Tab Clicked",
        MY_ORDER_CLICKED: "My Order Clicked",
        MAIL_VERIFIED: "Verifies Mail",
        REQUEST_MAIL_VERIFICATION: "Request Verification Email",
        CREATE_ACCOUNT: "Create Account",
        UPLOADS_DRIVERS_LICENSE: "Uploads Drivers License",
        NAVITEM_CLICKED: "NavItem Clicked",
        SUPPORT_CLICKED: "Support Clicked"
    }
};
export var DRIVER_MGMT_EVENTS = {
    name: "additionalDriver",
    category: "Additional Driver",
    action: {
        ADD_DRIVER: "Add Driver"
    },
    label: {
        SUCCESSFUL_COMPLETION_RELATIVE_TYPE_1: "Successful Completion: Relatives with same residence",
        SUCCESSFUL_COMPLETION_RELATIVE_TYPE_2: "Successful Completion: Relatives with other residence",
        SUCCESSFUL_COMPLETION_EMPLOYEE: "Successful Completion: Employee",
        SUCCESSFUL_COMPLETION_OTHER: "Successful Completion: Other",
        BIRTHDAY_ERROR: "Birthday Error"
    }
};
export var CHECKOUT_EVENTS = {
    category: "Checkout Interaction",
    action: {
        EDIT_ORDER: "Edit Order",
        NAVIGATE_BACK: "Navigate Back"
    }
};
export var REFERRAL_WIDGET_EVENTS = {
    category: "Referral Widget Interaction",
    action: {
        CLICK_REFERRAL: "Click Referral"
    },
    label: "customer_portal_referral_widget"
};
export var DOCUMENT_WIDGET_EVENTS = {
    category: "Document Widget Interaction",
    action: {
        DOWNLOAD_DOCUMENT: "Download Document",
        DOCUMENT_VIEWED: "Document Viewed"
    }
};
export var TRACKING_DESTINATIONS = {
    FB: "Facebook Pixel"
};
var _obj;
export var ECCOMMERCE_EVENTS_PROPERTIES = (_obj = {}, _define_property(_obj, TrackingEventName.PRODUCT_VIEWED, {
    category: "Conversion",
    action: "PDP View"
}), _define_property(_obj, TrackingEventName.PRODUCT_ADDED, {
    category: "Conversion",
    action: "Add to Cart"
}), _define_property(_obj, TrackingEventName.CHECKOUT_LEAD, {
    category: "Conversion",
    action: "Lead",
    label: "Checkout"
}), _define_property(_obj, TrackingEventName.ORDER_COMPLETED, {
    category: "Conversion",
    action: "Purchase"
}), _define_property(_obj, TrackingEventName.B2B_FORM_SUBMISSION, {
    category: "Conversion",
    action: "B2B Submission"
}), _define_property(_obj, TrackingEventName.PRODUCT_CLICKED, {
    category: "Conversion",
    action: "Product Click"
}), _define_property(_obj, TrackingEventName.PRODUCT_LIST_VIEWED, {
    category: "Conversion",
    action: "Product Impression"
}), _define_property(_obj, TrackingEventName.CHECKOUT_STEP_VIEWED, {
    category: "Enhanced Ecommerce",
    action: "Checkout Step"
}), _define_property(_obj, TrackingEventName.CHECKOUT_STARTED, {
    eventCategory: "Conversion",
    eventAction: "Enter checkout",
    eventLabel: "SALES"
}), _obj);
export var HELP_CENTER_EVENTS = {
    HELP_CENTER_ICON_CLICKED: "Helpcenter Icon Clicked"
};
export var SOURCE = {
    MENU: "menu"
};
export var CHECKOUT_CUSTOMER_SUPPORT = {
    SUPPORT_NAVBAR_CLICKED: "Checkout Support Nav Bar Clicked",
    SUPPORT_CLICKED: "Checkout Support Clicked",
    SUPPORT_MODAL_OPENED: "Checkout Support Modal Opened",
    SUPPORT_PHONE_CLICKED: "Checkout Support Phone Clicked",
    SUPPORT_EMAIL_CLICKED: "Checkout Support Email Clicked"
};
export var LOYALTY_VOUCHER_EVENTS = {
    LOYALTY_VOUCHER_REQUESTED: "Loyalty Voucher Requested"
};
export var CONTACT_US_PAGE = {
    LOGIN_BANNER_C2A_CLICKED: "Login Banner C2A Clicked",
    RECOMMENDATION_LINK_CLICKED: "Recommendation Link Clicked",
    CONTACT_FORM_CHATBOT_SESSION_STARTED: "Contact Form Chatbot Session Started",
    FAQ_BUTTON_CLICKED: "FAQ Button Clicked",
    CONTACT_US_BUTTON_CLICKED: "Contact Us Button Clicked"
};
export var CHATBOT_SESSION_STARTED = "Chatbot Session Started";
