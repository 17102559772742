import { useRouter } from "next/router";
import NProgress from "nprogress";
import { useCallback, useEffect } from "react";
NProgress.configure({
    showSpinner: false
});
var useNavigationProgress = function() {
    var router = useRouter();
    var startProgress = useCallback(function() {
        var step = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 0;
        NProgress.start();
        NProgress.set(step);
    }, []);
    var endProgress = useCallback(function() {
        NProgress.done();
    }, []);
    useEffect(function() {
        router.events.on("routeChangeStart", startProgress);
        router.events.on("routeChangeComplete", endProgress);
        router.events.on("routeChangeError", endProgress);
        return function() {
            router.events.off("routeChangeStart", startProgress);
            router.events.off("routeChangeComplete", endProgress);
            router.events.off("routeChangeError", endProgress);
        };
    }, [
        router.events
    ]);
    return {
        startProgress: startProgress,
        endProgress: endProgress
    };
};
export default useNavigationProgress;
