import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import { hashStr, identifyAnonymousUserEvent, identifyEvent, isFullStoryLoaded, useSession } from "@finn/ui-utils";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { getHubspotDealInfo } from "./hubspot";
export var useUserIdentifierGeneralPages = function() {
    var ref;
    var router = useRouter();
    var ref1 = _sliced_to_array(useSession(), 2), session = ref1[0], loading = ref1[1];
    useEffect(function() {
        // session changes from undefined to null causing a duplicate
        if (session === undefined && loading) return;
        // router.asPath changes causing re-render and duplicate, need to check router.isReady
        if (!router.isReady) return;
        // Check if the user is logged in and override the userId with the new logged-in user
        if (session === null || session === void 0 ? void 0 : session.user) {
            var userId = session.user.hs_contact_id;
            if (userId) {
                identifyEvent(String(userId), {
                    name: session.user.name,
                    email: session.user.email,
                    loggedIn: !!session
                });
            } else {
                identifyAnonymousUserEvent({
                    name: session.user.name,
                    email: session.user.email,
                    loggedIn: !!session
                });
            }
            if (isFullStoryLoaded() && !!userId) {
                window.FS.identify(String(userId));
            }
            return;
        }
        identifyAnonymousUserEvent({
            loggedIn: !!session
        });
        // Check if it's deal related page, if so then do nothing
        // Basically we try to see the URL if it's contain contactId which only exist on deal related pages (self-service, Checkout)
        var dealInfo = getHubspotDealInfo(router);
        if (dealInfo.contactId) {
            return;
        }
        // Will use it as the user id from auth, since our auth doesn't return a user ID
        var hashedEmail = "";
        if (session === null || session === void 0 ? void 0 : session.user) {
            hashedEmail = hashStr(session.user.email);
        }
        // Add User ID to FullStory session recording
        if (isFullStoryLoaded() && !!hashedEmail) {
            window.FS.identify(hashedEmail);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        session === null || session === void 0 ? void 0 : (ref = session.user) === null || ref === void 0 ? void 0 : ref.email,
        loading,
        router.isReady
    ]);
};
