export var getComponentDisplayName = function(element) {
    if (!element) {
        return "";
    }
    var node = element;
    var type = node.type;
    if (typeof type === "string") {
        return type;
    }
    try {
        if (type && "displayName" in type) {
            return type === null || type === void 0 ? void 0 : type.displayName;
        }
        if (type && "name" in type) {
            return type === null || type === void 0 ? void 0 : type.name;
        }
    } catch (e) {
        return "";
    }
    return "";
};
