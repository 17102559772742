import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import { CookieKeys, getClientBooleanCookie, getClientJSONCookie, setClientJSONCookie } from "@finn/ui-utils/lib/cookies";
import { isServer } from "@finn/ui-utils/lib/server";
import { getSession as getSessionNext, signOut, useSession as useSessionNext } from "next-auth/client";
import { useEffect, useState } from "react";
export var SOCIAL_LOGIN_PROVIDER;
(function(SOCIAL_LOGIN_PROVIDER) {
    SOCIAL_LOGIN_PROVIDER["GOOGLE"] = "google";
    SOCIAL_LOGIN_PROVIDER["APPLE"] = "apple";
})(SOCIAL_LOGIN_PROVIDER || (SOCIAL_LOGIN_PROVIDER = {}));
export var getSession = function(options) {
    var session = getSessionNext(options);
    return session;
};
export var useSession = function() {
    var ref = _sliced_to_array(useSessionNext(), 2), session = ref[0], loading = ref[1];
    if (!isServer()) {
        var signOutInProgress = getClientBooleanCookie(CookieKeys.SIGNOUT_IN_PROGRESS);
        if (signOutInProgress) {
            signOut({
                redirect: false
            });
            return [
                null,
                false
            ];
        }
    }
    return [
        session,
        loading
    ];
};
export var useSessionInCookie = function() {
    var ref;
    var ref1 = useState(undefined), savedSession = ref1[0], setSavedSession = ref1[1];
    var ref2 = _sliced_to_array(useSession(), 1), sessionNext = ref2[0];
    var savedSessionEmail = savedSession === null || savedSession === void 0 ? void 0 : (ref = savedSession.user) === null || ref === void 0 ? void 0 : ref.email;
    // replace session in cookie and state with the new one from next-auth
    useEffect(function() {
        var ref;
        var sessionNextEmail = sessionNext === null || sessionNext === void 0 ? void 0 : (ref = sessionNext.user) === null || ref === void 0 ? void 0 : ref.email;
        if (sessionNextEmail && sessionNextEmail !== savedSessionEmail) {
            setClientJSONCookie(CookieKeys.SESSION_DATA, sessionNext);
            setSavedSession(sessionNext);
        }
    }, [
        savedSessionEmail,
        sessionNext
    ]);
    // put the value in cookie into the state
    useEffect(function() {
        var ref, ref1;
        var sessionInCookie = getClientJSONCookie(CookieKeys.SESSION_DATA);
        var userEmail = sessionInCookie === null || sessionInCookie === void 0 ? void 0 : (ref = sessionInCookie.user) === null || ref === void 0 ? void 0 : ref.email;
        if (userEmail && userEmail !== (savedSession === null || savedSession === void 0 ? void 0 : (ref1 = savedSession.user) === null || ref1 === void 0 ? void 0 : ref1.email)) {
            setSavedSession(sessionInCookie);
        } else if (savedSession === undefined) {
            setSavedSession(null);
        }
    }, [
        savedSession
    ]);
    return sessionNext === undefined ? savedSession : sessionNext;
};
export var getFinnSessionId = function() {
    var requestId = new Date().getTime().toString(36) + Math.random().toString(36).substr(2, 12);
    return requestId;
};
