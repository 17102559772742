import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import { Locale } from "../types/localization";
export var SUPPORTED_LOCALES = {
    de: [
        "DE"
    ],
    en: [
        "DE",
        "US"
    ]
};
export var getCorrectLocale = function(locale) {
    locale = locale || Locale.GERMAN_GERMANY;
    var ref = _sliced_to_array(locale.split("-"), 2), lang = ref[0], region = ref[1];
    if (!lang) {
        lang = "de";
    }
    if (lang in SUPPORTED_LOCALES) {
        if (!SUPPORTED_LOCALES[lang].includes(region)) {
            region = SUPPORTED_LOCALES[lang][0];
        }
    } else {
        lang = "de";
        region = "DE";
    }
    return {
        lang: lang,
        region: region
    };
};
