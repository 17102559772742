import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import regeneratorRuntime from "/opt/build/repo/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import "../../sdk";
import { UserAccountContext, UserAccountModal } from "@finn/ua-auth";
import { useOnModalClose, useOnModalOpen, useOpenModal } from "@finn/ua-modals";
import { captureException, isServer, traceabilityHeadersBrowser, useSession } from "@finn/ui-utils";
import axios from "axios";
import { useRouter } from "next/router";
import { signIn, signOut as nextAuthSignOut } from "next-auth/client";
import { useContext, useEffect, useRef } from "react";
import { getAppSDK } from "../../helpers/mobileApp";
import { savePushToken } from "../../helpers/savePushToken";
import { adjustLinkTargetsforWebView } from "../../helpers/webViewHelpers";
// TODO extract callSignIn and similar to separate package
// for now we need to copy this code from UA to avoid cycle that causes JS errors
var callSignIn = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(provider, options) {
        var appSDK, data;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    appSDK = getAppSDK();
                    _ctx.prev = 1;
                    _ctx.next = 4;
                    return signIn(provider, _object_spread({
                        redirect: false
                    }, appSDK ? {
                        appVersion: appSDK.getTrackingProps().app_version
                    } : {}, options));
                case 4:
                    data = _ctx.sent;
                    data.ok = data.ok && !data.error;
                    return _ctx.abrupt("return", data);
                case 9:
                    _ctx.prev = 9;
                    _ctx.t0 = _ctx["catch"](1);
                    captureException(_ctx.t0);
                    return _ctx.abrupt("return", {
                        status: 500,
                        ok: false,
                        error: "server_error",
                        url: null
                    });
                case 13:
                case "end":
                    return _ctx.stop();
            }
        }, _callee, null, [
            [
                1,
                9
            ]
        ]);
    }));
    return function callSignIn(provider, options) {
        return _ref.apply(this, arguments);
    };
}();
var BASE_URL = "".concat(!isServer() ? window.origin : process.env.NEXTAUTH_URL || "", "/api/auth");
var callCustomEndpoint = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(endpoint, payload) {
        var status, data, result, ref, ref1, err;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    status = 500;
                    data = null;
                    _ctx.prev = 2;
                    _ctx.next = 5;
                    return axios.post("".concat(BASE_URL, "/custom/").concat(endpoint), payload, {
                        headers: traceabilityHeadersBrowser()
                    });
                case 5:
                    result = _ctx.sent;
                    status = result.status;
                    data = result.data;
                    _ctx.next = 16;
                    break;
                case 10:
                    _ctx.prev = 10;
                    _ctx.t0 = _ctx["catch"](2);
                    ;
                    err = _ctx.t0;
                    captureException(_ctx.t0);
                    return _ctx.abrupt("return", {
                        status: status,
                        ok: false,
                        error: err.response.status === 404 && ((ref = err.response) === null || ref === void 0 ? void 0 : (ref1 = ref.data) === null || ref1 === void 0 ? void 0 : ref1.message) || "server_error",
                        url: null
                    });
                case 16:
                    return _ctx.abrupt("return", {
                        status: status,
                        ok: status === 200 && data.success,
                        error: data.message,
                        url: null
                    });
                case 17:
                case "end":
                    return _ctx.stop();
            }
        }, _callee, null, [
            [
                2,
                10
            ]
        ]);
    }));
    return function callCustomEndpoint(endpoint, payload) {
        return _ref.apply(this, arguments);
    };
}();
var login = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(email, password) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", callSignIn("login", {
                        email: email,
                        password: password
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function login(email, password) {
        return _ref.apply(this, arguments);
    };
}();
var loginWithCode = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(code) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", callSignIn("loginWithCode", {
                        code: code
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function loginWithCode(code) {
        return _ref.apply(this, arguments);
    };
}();
var loginWithToken = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(token) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", callSignIn("loginWithToken", {
                        token: token
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function loginWithToken(token) {
        return _ref.apply(this, arguments);
    };
}();
var signOut = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee() {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", nextAuthSignOut({
                        redirect: false
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function signOut() {
        return _ref.apply(this, arguments);
    };
}();
var refreshCode = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee() {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", callCustomEndpoint("refreshCode", {}));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function refreshCode() {
        return _ref.apply(this, arguments);
    };
}();
var verifyCode = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(code) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", callCustomEndpoint("verifyCode", {
                        code: code
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function verifyCode(code) {
        return _ref.apply(this, arguments);
    };
}();
export var MobileAppBindings = function() {
    var ref = _sliced_to_array(useSession(), 2), session = ref[0], isSessionLoading = ref[1];
    var ref1 = useContext(UserAccountContext), modalStatus = ref1.modalStatus, setModalStatus = ref1.setModalStatus;
    var isModalOpenRef = useRef(false);
    var modalName = (modalStatus === null || modalStatus === void 0 ? void 0 : modalStatus.isModalOpen) && (modalStatus === null || modalStatus === void 0 ? void 0 : modalStatus.modalType);
    var router = useRouter();
    var openModal = useOpenModal();
    useEffect(function() {
        var ref;
        // on android app links with target _blank will open in a new browser window
        // which is not desired behaviour, as we want to have native navigation
        // in order to avoid this we are changing target for all links on a page
        if ((ref = navigator.userAgent.toLowerCase()) === null || ref === void 0 ? void 0 : ref.includes("android")) {
            adjustLinkTargetsforWebView();
        }
        var appSDK = getAppSDK();
        if (appSDK) {
            appSDK.signIn = login;
            appSDK.signOut = _async_to_generator(regeneratorRuntime.mark(function _callee() {
                return regeneratorRuntime.wrap(function _callee$(_ctx) {
                    while(1)switch(_ctx.prev = _ctx.next){
                        case 0:
                            _ctx.next = 2;
                            return signOut === null || signOut === void 0 ? void 0 : signOut();
                        case 2:
                            appSDK.sendMessageToApp({
                                type: "event:signout_successful"
                            });
                        case 3:
                        case "end":
                            return _ctx.stop();
                    }
                }, _callee);
            }));
            appSDK.signInWithToken = function() {
                var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(token, responseRequestId) {
                    var res;
                    return regeneratorRuntime.wrap(function _callee$(_ctx) {
                        while(1)switch(_ctx.prev = _ctx.next){
                            case 0:
                                if (!(token !== "token" && token !== "emptyToken")) {
                                    _ctx.next = 5;
                                    break;
                                }
                                _ctx.next = 3;
                                return loginWithToken === null || loginWithToken === void 0 ? void 0 : loginWithToken(token);
                            case 3:
                                res = _ctx.sent;
                                if (responseRequestId) {
                                    appSDK.sendMessageToApp({
                                        requestId: responseRequestId,
                                        type: "event:login_with_token",
                                        value: res.ok
                                    });
                                }
                            case 5:
                            case "end":
                                return _ctx.stop();
                        }
                    }, _callee);
                }));
                return function(token, responseRequestId) {
                    return _ref.apply(this, arguments);
                };
            }();
            appSDK.savePushToken = savePushToken;
            appSDK === null || appSDK === void 0 ? void 0 : appSDK.sendMessageToApp({
                type: "event:sdk_initialized"
            });
        }
    // we want to trigger this hook on each route change
    // to send new initialized event
    }, [
        router.asPath
    ]);
    useEffect(function() {
        var appSDK = getAppSDK();
        if (!appSDK) {
            return;
        }
        appSDK.userSession = session;
        appSDK.openModal = function(key) {
            return openModal === null || openModal === void 0 ? void 0 : openModal(key);
        };
        appSDK.showVerifyEmailModal = function() {
            return setModalStatus({
                isModalOpen: true,
                modalType: UserAccountModal.EMAIL_VERIFICATION
            });
        };
        appSDK.verifyEmail = function() {
            var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(verificationCode) {
                var result;
                return regeneratorRuntime.wrap(function _callee$(_ctx) {
                    while(1)switch(_ctx.prev = _ctx.next){
                        case 0:
                            _ctx.next = 2;
                            return verifyCode(verificationCode);
                        case 2:
                            result = _ctx.sent;
                            if (!result.ok) {
                                _ctx.next = 9;
                                break;
                            }
                            _ctx.next = 6;
                            return loginWithCode === null || loginWithCode === void 0 ? void 0 : loginWithCode(verificationCode);
                        case 6:
                            _ctx.next = 8;
                            return refreshCode();
                        case 8:
                            setModalStatus({
                                isModalOpen: true,
                                modalType: UserAccountModal.EMAIL_VERIFIED_SUCCESSFULLY
                            });
                        case 9:
                        case "end":
                            return _ctx.stop();
                    }
                }, _callee);
            }));
            return function(verificationCode) {
                return _ref.apply(this, arguments);
            };
        }();
    }, [
        session,
        setModalStatus,
        openModal
    ]);
    useEffect(function() {
        if (!isSessionLoading) {
            var ref;
            (ref = getAppSDK()) === null || ref === void 0 ? void 0 : ref.sendMessageToApp({
                type: "event:user_authenticated",
                value: session
            });
        }
    }, [
        session,
        isSessionLoading
    ]);
    useOnModalOpen(function(modalKey) {
        var ref, ref1;
        (ref = getAppSDK()) === null || ref === void 0 ? void 0 : (ref1 = ref.sendMessageToApp) === null || ref1 === void 0 ? void 0 : ref1.call(ref, {
            type: "event:open_modal",
            value: modalKey
        });
        isModalOpenRef.current = true;
    });
    useOnModalClose(function(modalKey) {
        var ref, ref1;
        (ref = getAppSDK()) === null || ref === void 0 ? void 0 : (ref1 = ref.sendMessageToApp) === null || ref1 === void 0 ? void 0 : ref1.call(ref, {
            type: "event:close_modal",
            value: modalKey
        });
        isModalOpenRef.current = false;
    });
    useEffect(function() {
        var appSDK = getAppSDK();
        if (!appSDK) {
            return;
        }
        if (modalName && !isModalOpenRef.current) {
            var ref;
            (ref = appSDK.sendMessageToApp) === null || ref === void 0 ? void 0 : ref.call(appSDK, {
                type: "event:open_modal",
                value: modalName
            });
            isModalOpenRef.current = true;
        } else if (isModalOpenRef.current) {
            var ref1;
            (ref1 = appSDK.sendMessageToApp) === null || ref1 === void 0 ? void 0 : ref1.call(appSDK, {
                type: "event:close_modal"
            });
            isModalOpenRef.current = false;
        }
    }, [
        modalName
    ]);
    return null;
};
