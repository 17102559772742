import { useFormattedPrice } from "@finn/ui-utils";
import { useMemo } from "react";
var TERMS = [
    24,
    18,
    13,
    12,
    6,
    1
];
var TAX_RATE_GERMANY = 0.19;
export var usePreDiscountRawValue = function(vehicle, price, term) {
    var isForBusiness = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : false, isDownPayment = arguments.length > 4 && arguments[4] !== void 0 ? arguments[4] : true;
    var preDiscount = useMemo(function() {
        var b2bMultiplier = isForBusiness ? 1 + TAX_RATE_GERMANY : 1;
        var availablePrices = isDownPayment ? vehicle.downpayment_prices.available_price_list : vehicle.price.available_price_list;
        if (!availablePrices) {
            return undefined;
        }
        if (term !== undefined && term !== 0) {
            return Math.round(availablePrices["b2c_".concat(term, "_old")] / b2bMultiplier);
        }
        var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
        try {
            for(var _iterator = TERMS[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
                var currentTerm = _step.value;
                var oldPrice = availablePrices["b2c_".concat(currentTerm, "_old")];
                if (oldPrice) {
                    return Math.round(oldPrice / b2bMultiplier);
                }
            }
        } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
        } finally{
            try {
                if (!_iteratorNormalCompletion && _iterator.return != null) {
                    _iterator.return();
                }
            } finally{
                if (_didIteratorError) {
                    throw _iteratorError;
                }
            }
        }
        return undefined;
    }, [
        isForBusiness,
        isDownPayment,
        vehicle.downpayment_prices.available_price_list,
        vehicle.price.available_price_list,
        term, 
    ]);
    return preDiscount > price ? preDiscount : undefined;
};
export var usePreDiscount = function(param) {
    var vehicle = param.vehicle, price = param.price, term = param.term, _isForBusiness = param.isForBusiness, isForBusiness = _isForBusiness === void 0 ? false : _isForBusiness, _isDownPayment = param.isDownPayment, isDownPayment = _isDownPayment === void 0 ? true : _isDownPayment;
    var discount = usePreDiscountRawValue(vehicle, price, term, isForBusiness, isDownPayment);
    var formattedDiscount = useFormattedPrice(discount, 0);
    if (discount === undefined || price !== undefined && discount <= price) {
        return undefined;
    }
    return formattedDiscount;
};
