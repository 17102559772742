import { getAppSDK } from "./mobileApp";
export var isServer = function() {
    return "object" === "undefined";
};
export var getRequestMetadataForWebViewApp = function() {
    var ref, ref1;
    if (isServer()) {
        return {};
    }
    var trackingProps = (ref = getAppSDK()) === null || ref === void 0 ? void 0 : (ref1 = ref.getTrackingProps) === null || ref1 === void 0 ? void 0 : ref1.call(ref);
    if (!(trackingProps === null || trackingProps === void 0 ? void 0 : trackingProps.app_platform)) {
        return {};
    }
    var appHeaders = {
        "X-Version-Tag": trackingProps.app_version,
        "X-Platform-Tag": trackingProps.app_platform
    };
    var appQueryParams = {
        utm_source: "app-".concat(trackingProps.app_platform),
        app_version: trackingProps.app_version
    };
    return {
        appHeaders: appHeaders,
        appQueryParams: appQueryParams
    };
};
export var adjustLinkTargetsforWebView = function() {
    document.querySelectorAll("a").forEach(function(tag) {
        tag.target = "";
    });
};
