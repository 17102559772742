"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
var _objectSpread = require("@swc/helpers/lib/_object_spread.js").default;
var _objectSpreadProps = require("@swc/helpers/lib/_object_spread_props.js").default;
// Source https://www.figma.com/design/KUvHhOhlCXF8FbspinF2Z6/Components?node-id=3748-707&t=zpG5z0AoXoOrfh6k-4
// last sync: 08.09.2024
// This file is JS as we import it in tailwind.config.js
/** @type {import('tailwindcss').Config} */ // we set font with default line height for each size
var fontSize = {
    xs: [
        "0.75rem",
        "1rem"
    ],
    sm: [
        "0.875rem",
        "1.125rem"
    ],
    base: [
        "1rem",
        "1.375rem"
    ],
    lg: [
        "1.5rem",
        "2rem"
    ],
    xl: [
        "1.75rem",
        "2.25rem"
    ],
    "2xl": [
        "2rem",
        "2.5rem"
    ],
    "3xl": [
        "2.25rem",
        "2.75rem"
    ],
    "4xl": [
        "2.5rem",
        "3rem"
    ],
    "5xl": [
        "3rem",
        "3.5rem"
    ],
    "6xl": [
        "3.5rem",
        "4rem"
    ]
};
var fontWeight = {
    light: 300,
    regular: 400,
    medium: 500,
    semibold: 600
};
var fontFamily = {
    suisse: [
        "Suisse Intl",
        "sans-serif"
    ],
    inter: [
        "Inter",
        "sans-serif"
    ]
};
var verticalTrimDefaults = {
    content: '""',
    display: "table"
};
var body16 = {
    fontSize: fontSize.base[0],
    lineHeight: fontSize.base[1],
    fontFamily: fontFamily.inter.join(", "),
    "&:before": _objectSpread({
        marginBottom: "-0.3em"
    }, verticalTrimDefaults),
    "&:after": _objectSpread({
        marginTop: "-0.3255em"
    }, verticalTrimDefaults)
};
var body14 = {
    fontSize: fontSize.sm[0],
    lineHeight: fontSize.sm[1],
    fontFamily: fontFamily.inter.join(", "),
    "&:before": _objectSpread({
        marginBottom: "-0.282em"
    }, verticalTrimDefaults),
    "&:after": _objectSpread({
        marginTop: "-0.29em"
    }, verticalTrimDefaults)
};
var body12 = {
    fontSize: fontSize.xs[0],
    lineHeight: fontSize.xs[1],
    fontFamily: fontFamily.inter.join(", "),
    "&:before": _objectSpread({
        marginBottom: "-0.282em"
    }, verticalTrimDefaults),
    "&:after": _objectSpread({
        marginTop: "-0.302em"
    }, verticalTrimDefaults)
};
// https://seek-oss.github.io/capsize/ + manual adjustments for broken configs
var typography = function() {
    return {
        // web titles
        ".web-t1-semibold": {
            fontSize: fontSize["6xl"][0],
            lineHeight: fontSize["6xl"][1],
            fontWeight: fontWeight.semibold,
            fontFamily: fontFamily.suisse.join(", "),
            "&:before": _objectSpread({
                marginBottom: "-0.1829em"
            }, verticalTrimDefaults),
            "&:after": _objectSpread({
                marginTop: "-0.2279em"
            }, verticalTrimDefaults)
        },
        ".web-t2-semibold": {
            fontSize: fontSize["5xl"][0],
            lineHeight: fontSize["5xl"][1],
            fontWeight: fontWeight.semibold,
            fontFamily: fontFamily.suisse.join(", "),
            "&:before": _objectSpread({
                marginBottom: "-0.1976em"
            }, verticalTrimDefaults),
            "&:after": _objectSpread({
                marginTop: "-0.24em"
            }, verticalTrimDefaults)
        },
        ".web-t3-semibold": {
            fontSize: fontSize["4xl"][0],
            lineHeight: fontSize["4xl"][1],
            fontWeight: fontWeight.semibold,
            fontFamily: fontFamily.suisse.join(", "),
            "&:before": _objectSpread({
                marginBottom: "-0.225em"
            }, verticalTrimDefaults),
            "&:after": _objectSpread({
                marginTop: "-0.25em"
            }, verticalTrimDefaults)
        },
        ".web-t3-light": {
            fontSize: fontSize["4xl"][0],
            lineHeight: fontSize["4xl"][1],
            fontWeight: fontWeight.light,
            fontFamily: fontFamily.suisse.join(", "),
            "&:before": _objectSpread({
                marginBottom: "-0.225em"
            }, verticalTrimDefaults),
            "&:after": _objectSpread({
                marginTop: "-0.25em"
            }, verticalTrimDefaults)
        },
        ".web-t4-semibold": {
            fontSize: fontSize["2xl"][0],
            lineHeight: fontSize["2xl"][1],
            fontWeight: fontWeight.semibold,
            fontFamily: fontFamily.suisse.join(", "),
            "&:before": _objectSpread({
                marginBottom: "-0.248em"
            }, verticalTrimDefaults),
            "&:after": _objectSpread({
                marginTop: "-0.2835em"
            }, verticalTrimDefaults)
        },
        ".web-t4-light": {
            fontSize: fontSize["2xl"][0],
            lineHeight: fontSize["2xl"][1],
            fontWeight: fontWeight.light,
            fontFamily: fontFamily.suisse.join(", "),
            "&:before": _objectSpread({
                marginBottom: "-0.248em"
            }, verticalTrimDefaults),
            "&:after": _objectSpread({
                marginTop: "-0.2835em"
            }, verticalTrimDefaults)
        },
        // mobile titles
        ".mobile-t1-semibold": {
            fontSize: fontSize["4xl"][0],
            lineHeight: fontSize["4xl"][1],
            fontWeight: fontWeight.semibold,
            fontFamily: fontFamily.suisse.join(", "),
            "&:before": _objectSpread({
                marginBottom: "-0.228em"
            }, verticalTrimDefaults),
            "&:after": _objectSpread({
                marginTop: "-0.247em"
            }, verticalTrimDefaults)
        },
        ".mobile-t2-semibold": {
            fontSize: fontSize["3xl"][0],
            lineHeight: fontSize["3xl"][1],
            fontWeight: fontWeight.semibold,
            fontFamily: fontFamily.suisse.join(", "),
            "&:before": _objectSpread({
                marginBottom: "-0.22em"
            }, verticalTrimDefaults),
            "&:after": _objectSpread({
                marginTop: "-0.28em"
            }, verticalTrimDefaults)
        },
        ".mobile-t3-semibold": {
            fontSize: fontSize["2xl"][0],
            lineHeight: fontSize["2xl"][1],
            fontWeight: fontWeight.semibold,
            fontFamily: fontFamily.suisse.join(", "),
            "&:before": _objectSpread({
                marginBottom: "-0.24em"
            }, verticalTrimDefaults),
            "&:after": _objectSpread({
                marginTop: "-0.2912em"
            }, verticalTrimDefaults)
        },
        ".mobile-t3-light": {
            fontSize: fontSize["2xl"][0],
            lineHeight: fontSize["2xl"][1],
            fontWeight: fontWeight.light,
            fontFamily: fontFamily.suisse.join(", "),
            "&:before": _objectSpread({
                marginBottom: "-0.24em"
            }, verticalTrimDefaults),
            "&:after": _objectSpread({
                marginTop: "-0.2912em"
            }, verticalTrimDefaults)
        },
        ".mobile-t4-semibold": {
            fontSize: fontSize.xl[0],
            lineHeight: fontSize.xl[1],
            fontWeight: fontWeight.semibold,
            fontFamily: fontFamily.suisse.join(", "),
            "&:before": _objectSpread({
                marginBottom: "-0.249em"
            }, verticalTrimDefaults),
            "&:after": _objectSpread({
                marginTop: "-0.3227em"
            }, verticalTrimDefaults)
        },
        ".mobile-t4-light": {
            fontSize: fontSize.xl[0],
            lineHeight: fontSize.xl[1],
            fontWeight: fontWeight.light,
            fontFamily: fontFamily.suisse.join(", "),
            "&:before": _objectSpread({
                marginBottom: "-0.249em"
            }, verticalTrimDefaults),
            "&:after": _objectSpread({
                marginTop: "-0.3227em"
            }, verticalTrimDefaults)
        },
        // global titles
        ".global-t5-semibold": {
            fontSize: fontSize.lg[0],
            lineHeight: fontSize.lg[1],
            fontWeight: fontWeight.semibold,
            fontFamily: fontFamily.suisse.join(", "),
            "&:before": _objectSpread({
                marginBottom: "-0.289em"
            }, verticalTrimDefaults),
            "&:after": _objectSpread({
                marginTop: "-0.3366em"
            }, verticalTrimDefaults)
        },
        ".global-t5-light": {
            fontSize: fontSize.lg[0],
            lineHeight: fontSize.lg[1],
            fontWeight: fontWeight.light,
            fontFamily: fontFamily.suisse.join(", "),
            "&:before": _objectSpread({
                marginBottom: "-0.289em"
            }, verticalTrimDefaults),
            "&:after": _objectSpread({
                marginTop: "-0.3366em"
            }, verticalTrimDefaults)
        },
        ".global-t6-semibold": {
            fontSize: fontSize.base[0],
            // it had custom different line height
            lineHeight: "1.5rem",
            fontWeight: fontWeight.semibold,
            fontFamily: fontFamily.suisse.join(", "),
            "&:before": _objectSpread({
                marginBottom: "-0.35em"
            }, verticalTrimDefaults),
            "&:after": _objectSpread({
                marginTop: "-0.4em"
            }, verticalTrimDefaults)
        },
        // large body
        ".body-16-light": _objectSpreadProps(_objectSpread({}, body16), {
            fontWeight: fontWeight.light
        }),
        ".body-16-regular": _objectSpreadProps(_objectSpread({}, body16), {
            fontWeight: fontWeight.regular
        }),
        ".body-16-medium": _objectSpreadProps(_objectSpread({}, body16), {
            fontWeight: fontWeight.medium
        }),
        ".body-16-semibold": _objectSpreadProps(_objectSpread({}, body16), {
            fontWeight: fontWeight.semibold
        }),
        // medium body
        ".body-14-light": _objectSpreadProps(_objectSpread({}, body14), {
            fontWeight: fontWeight.light
        }),
        ".body-14-regular": _objectSpreadProps(_objectSpread({}, body14), {
            fontWeight: fontWeight.regular
        }),
        ".body-14-medium": _objectSpreadProps(_objectSpread({}, body14), {
            fontWeight: fontWeight.medium
        }),
        ".body-14-semibold": _objectSpreadProps(_objectSpread({}, body14), {
            fontWeight: fontWeight.semibold
        }),
        // small body
        ".body-12-light": _objectSpreadProps(_objectSpread({}, body12), {
            fontWeight: fontWeight.light
        }),
        ".body-12-regular": _objectSpreadProps(_objectSpread({}, body12), {
            fontWeight: fontWeight.regular
        }),
        ".body-12-medium": _objectSpreadProps(_objectSpread({}, body12), {
            fontWeight: fontWeight.medium
        }),
        ".body-12-semibold": _objectSpreadProps(_objectSpread({}, body12), {
            fontWeight: fontWeight.semibold
        }),
        // links
        ".link-16": _objectSpreadProps(_objectSpread({}, body16), {
            fontWeight: fontWeight.semibold,
            textDecoration: "underline"
        }),
        ".link-14": _objectSpreadProps(_objectSpread({}, body14), {
            fontWeight: fontWeight.semibold,
            textDecoration: "underline"
        }),
        ".link-12": _objectSpreadProps(_objectSpread({}, body12), {
            fontWeight: fontWeight.semibold,
            textDecoration: "underline"
        }),
        // buttons
        ".button-16": _objectSpreadProps(_objectSpread({}, body16), {
            fontWeight: fontWeight.semibold
        }),
        ".button-14": _objectSpreadProps(_objectSpread({}, body14), {
            fontWeight: fontWeight.semibold
        }),
        ".button-12": _objectSpreadProps(_objectSpread({}, body12), {
            fontWeight: fontWeight.semibold
        })
    };
};
module.exports = {
    fontSize: fontSize,
    fontWeight: fontWeight,
    fontFamily: fontFamily,
    typography: typography
};
