import { DefaultLocale, Locale } from "@finn/ui-utils";
import trim from "lodash/trim";
var extractLocale = function(splitPath) {
    var locale = DefaultLocale;
    var firstPath = splitPath[0];
    if (firstPath === Locale.ENGLISH_USA || firstPath === Locale.GERMAN_GERMANY) {
        locale = firstPath;
        splitPath.splice(0, 1);
    }
    return locale;
};
// avoiding using URL / window.URL as we need to support both browser and node
export var parsePathAndLocale = function(url) {
    if (typeof url !== "string") return null;
    var urlWithoutQuery = trim(url).split("?")[0];
    var splitURL = urlWithoutQuery.split("/").filter(function(item) {
        return Boolean(item);
    });
    if (url.startsWith("http")) {
        var splitPath = splitURL.slice(2);
        var locale = extractLocale(splitPath);
        return {
            locale: locale,
            path: trim(splitPath.join("/"), "/")
        };
    } else if (url.startsWith("www") || url.startsWith("finn.")) {
        var splitPath1 = splitURL.slice(1);
        var locale1 = extractLocale(splitPath1);
        return {
            locale: locale1,
            path: trim(splitPath1.join("/"), "/")
        };
    } else {
        var splitPath2 = splitURL;
        var locale2 = extractLocale(splitPath2);
        return {
            locale: locale2,
            path: trim(splitPath2.join("/"), "/")
        };
    }
};
