var DISABLED = "disabled";
var getVariant = function(buckets, isSearchBot) {
    var index = isSearchBot ? 0 : Math.floor(Math.random() * buckets.length);
    return buckets[index];
};
/**
 * Internal method for setting & updating the current users feature values
 * @param allFeatures all the features enabled for this application, grouped by locale
 * @param currentLocale the current locale
 * @param getCookie method to use to get existing customer value
 * @param userAgent identifying the current browser
 * @returns all current bucket values
 */ export var computeFeatureValues = function(allFeatures, currentLocale, getCookie, userAgent) {
    var updatedBuckets = {};
    var bucketValues = {};
    var featuresForLocale = allFeatures[currentLocale] || {};
    var isSearchBot = userAgent ? /bot|googlebot|crawler|spider|robot|crawling/i.test(userAgent) : false;
    var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
    try {
        for(var _iterator = Object.keys(featuresForLocale)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
            var name = _step.value;
            var rawBucketValue = getCookie("".concat(currentLocale, "_").concat(name));
            var _name = featuresForLocale[name], variants = _name.buckets, enabled = _name.enabled;
            var hasValidCookie = variants.includes(String(rawBucketValue));
            var bucketValue = hasValidCookie && rawBucketValue ? rawBucketValue : getVariant(variants, isSearchBot);
            // Set the value 'disabled' before a test is started
            if (!enabled && !rawBucketValue) {
                bucketValue = DISABLED;
                hasValidCookie = true;
            }
            bucketValues[name] = bucketValue;
            if (!hasValidCookie && bucketValue) {
                updatedBuckets[name] = bucketValue;
            }
        }
    } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
    } finally{
        try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
            }
        } finally{
            if (_didIteratorError) {
                throw _iteratorError;
            }
        }
    }
    return {
        updatedBuckets: updatedBuckets,
        bucketValues: bucketValues
    };
};
