import { CountryCode, getFormattedNumber } from "@finn/ui-utils";
// TODO extract product related things to separate lib
export var convertKwToPs = function(powerInKw) {
    return Math.round(powerInKw * 1.35962);
};
export var convertKwToHP = function(powerInKw) {
    return Math.round(powerInKw * 1.34102);
};
export var getPowerLabel = function(intl, powerInKw, region, isElectric) {
    var isUSA = region === CountryCode.US;
    var label = "";
    if (isUSA) {
        var powerInHP = convertKwToHP(powerInKw);
        label = isElectric ? "".concat(getFormattedNumber(intl, powerInKw), " kW (").concat(getFormattedNumber(intl, powerInHP), " hp)") : "".concat(powerInHP, " hp");
    } else {
        var powerInPs = convertKwToPs(powerInKw);
        label = "".concat(getFormattedNumber(intl, powerInKw), " kW (").concat(getFormattedNumber(intl, powerInPs), " PS)");
    }
    return label;
};
