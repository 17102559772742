import { CookieKeys, getClientBooleanCookie, setClientBooleanCookie } from "@finn/ui-utils";
import { useReadyValue } from "../app/utils";
var EXPIRATION_DAYS = 30;
export var setForBusinessCookie = function(value) {
    return setClientBooleanCookie(CookieKeys.IS_FOR_BUSINESS, value, EXPIRATION_DAYS);
};
export var getForBusinessCookie = function() {
    return getClientBooleanCookie(CookieKeys.IS_FOR_BUSINESS);
};
export var useForBusinessCookie = function() {
    return useReadyValue(getForBusinessCookie(), false);
};
