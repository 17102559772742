import _define_property from "@swc/helpers/src/_define_property.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { devtools } from "zustand/middleware";
import { createStore } from "zustand/vanilla";
var FINN_UA_MODALS_DEVTOOLS_KEY = "@finn/ua-modals";
export function createModalStore() {
    return createStore()(devtools(function(set, get) {
        return {
            openModalKeys: {},
            openModal: function openModal(modalKey) {
                var openModalKeys = _object_spread_props(_object_spread({}, get().openModalKeys), _define_property({}, modalKey, true));
                set(function() {
                    return {
                        openModalKeys: openModalKeys
                    };
                }, false, "".concat(FINN_UA_MODALS_DEVTOOLS_KEY, "/openModal"));
            },
            closeModal: function closeModal(modalKey) {
                var openModalKeys = _object_spread({}, get().openModalKeys);
                delete openModalKeys[modalKey];
                set(function() {
                    return {
                        openModalKeys: openModalKeys
                    };
                }, false, "".concat(FINN_UA_MODALS_DEVTOOLS_KEY, "/closeModal"));
            },
            closeAllModals: function closeAllModals() {
                set(function() {
                    return {
                        openModalKeys: {}
                    };
                }, false, "".concat(FINN_UA_MODALS_DEVTOOLS_KEY, "/closeAllModals"));
            }
        };
    }, {
        name: FINN_UA_MODALS_DEVTOOLS_KEY
    }));
}
