import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import { getGlobalObject } from "../helpers/globalObject";
var CHECK_COOKIR_BANNER_TIMER = 20;
var setupTrackingPermissions = function() {
    var denyAllConsents = function(intervalId) {
        var globalObject = getGlobalObject();
        if (globalObject === null || globalObject === void 0 ? void 0 : globalObject.UC_UI) {
            var ref;
            globalObject === null || globalObject === void 0 ? void 0 : (ref = globalObject.UC_UI) === null || ref === void 0 ? void 0 : ref.denyAndCloseCcpa();
            clearInterval(intervalId);
        }
    };
    // we try to deny instatly
    denyAllConsents();
    // we will try to deny with interval to wait till appears
    var intervalId = setInterval(function() {
        denyAllConsents(intervalId);
    }, CHECK_COOKIR_BANNER_TIMER);
};
export var setupAnalytics = function(emitEvent) {
    setupTrackingPermissions();
    var globalObject = getGlobalObject();
    // web relays on UC_Integrations for some conditions
    // but by default we disable them, so we need to put some override
    globalObject.UC_Integrations = {};
    Object.defineProperties(globalObject, {
        analytics: {
            writable: true,
            value: {
                track: function(event, properties, options) {
                    emitEvent({
                        type: "event:track",
                        event: event,
                        properties: properties,
                        options: options
                    });
                },
                page: function(path, properties, options) {
                    emitEvent({
                        type: "event:page",
                        path: path,
                        properties: properties,
                        options: options
                    });
                },
                group: function(groupId, properties, options) {
                    emitEvent({
                        type: "event:group",
                        groupId: groupId,
                        properties: properties,
                        options: options
                    });
                },
                identify: function() {
                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                        args[_key] = arguments[_key];
                    }
                    var ref, ref1;
                    var data = {
                        userId: "",
                        properties: {},
                        options: undefined
                    };
                    if (typeof args[0] === "string") {
                        var _args = _sliced_to_array(args, 3), userId = _args[0], properties = _args[1], options = _args[2];
                        data.userId = userId;
                        data.properties = properties;
                        data.options = options;
                    } else {
                        var _args1 = _sliced_to_array(args, 2), properties1 = _args1[0], options1 = _args1[1];
                        data.properties = properties1;
                        data.options = options1;
                    }
                    emitEvent(_object_spread_props(_object_spread({
                        type: "event:identify"
                    }, data), {
                        properties: _object_spread_props(_object_spread({}, data.properties), {
                            app_version: (ref1 = (ref = globalObject.nativeAppSDK) === null || ref === void 0 ? void 0 : ref.getTrackingProps()) === null || ref1 === void 0 ? void 0 : ref1.app_version
                        })
                    }));
                },
                load: function() {
                    emitEvent({
                        type: "event:load"
                    });
                }
            }
        }
    });
};
