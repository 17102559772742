import { twoDecimalPlaces } from "@finn/ui-utils";
import get from "lodash/get";
import { getKmPackagePriceWithoutVat } from "./pdp";
export var CheckoutStepOrder;
(function(CheckoutStepOrder) {
    CheckoutStepOrder[CheckoutStepOrder["contact"] = 1] = "contact";
    CheckoutStepOrder[CheckoutStepOrder["payment"] = 2] = "payment";
    CheckoutStepOrder[CheckoutStepOrder["confirmation"] = 3] = "confirmation";
    CheckoutStepOrder[CheckoutStepOrder["thankYou"] = 4] = "thankYou";
})(CheckoutStepOrder || (CheckoutStepOrder = {}));
export var getVehicleMonthlyPrice = function(vehicle, term, kmPackage, isForBusiness, isDownPayment) {
    var priceKey = isDownPayment ? "downpayment_prices" : "price";
    var clientTypeStr = isForBusiness ? "b2b" : "b2c";
    var price = get(vehicle, "".concat(priceKey, ".available_price_list.").concat(clientTypeStr, "_").concat(term)) || get(vehicle, "".concat(priceKey, ".").concat(clientTypeStr, "_").concat(term), 0);
    var kmPackagePrice = get(vehicle, "price.extra_".concat(kmPackage), 0);
    var total = price + getKmPackagePriceWithoutVat(kmPackagePrice, !!isForBusiness);
    return twoDecimalPlaces(total);
};
