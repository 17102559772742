import { captureMessage, trackEvent } from "@finn/ui-utils";
export var trackingErrorHandler = function(fn) {
    try {
        fn();
    } catch (err) {
        captureMessage(err);
    }
};
export var interactionTrackingEvent = function(eventName, eventProperties // Used to be `TrackingEventProperties`, but it enforces GA event structure, which is not relevant for tracking in Segment
) {
    trackingErrorHandler(function() {
        trackEvent(eventName, eventProperties);
    });
};
export var navigationTrackingEvent = function(param) {
    var eventName = param.eventName, link = param.link, type = param.type;
    interactionTrackingEvent(eventName, {
        link: link,
        type: type
    });
};
