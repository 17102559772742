import { ContractTermType } from "../types/finance";
export var getContractTermType = function(term) {
    return term === 1 ? ContractTermType.OPEN : ContractTermType.FIXED;
};
export var getLabeledTerm = function(term, intl) {
    var label = term === 1 ? "general.termLabelOne" : "general.termLabelOther";
    return intl.formatMessage({
        id: label
    }, {
        duration: term
    });
};
export var getTermLabel = function(term, flexTermLabel, intl) {
    var isFixedTerm = getContractTermType(term) === ContractTermType.FIXED;
    var termLabel = isFixedTerm ? getLabeledTerm(term, intl) : String(flexTermLabel || term);
    return termLabel;
};
