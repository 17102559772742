"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
var _objectSpread = require("@swc/helpers/lib/_object_spread.js").default;
var _objectSpreadProps = require("@swc/helpers/lib/_object_spread_props.js").default;
var ref = require("uuid"), uuid = ref.v4;
var normalizeCosmicJSON = function(json, fieldsToDelete) {
    // we use a stack to keep track of the nodes we need to visit
    var stack = [
        {
            node: json,
            parent: null,
            nodeKeyInParent: null
        }
    ];
    var res = {
        modules: {},
        pages: {}
    };
    // We visit each node in JSON with DFS
    // We delete the fieldsToDelete from the nodes
    // We replace modules used on pagea and in modules with their ids
    while(stack.length > 0){
        var ref = stack.pop() || {
            node: null,
            parent: null,
            nodeKeyInParent: null
        }, node = ref.node, parent = ref.parent, nodeKeyInParent = ref.nodeKeyInParent;
        if (typeof node === "object" && node !== null) {
            if (!Array.isArray(node)) {
                for(var key in node){
                    // we delete fieldsToDelete from the node
                    // but only for cosmic objects, not for custom json objects
                    if (fieldsToDelete.includes(key) && node.id) {
                        delete node[key];
                    }
                    // we delete cosmic level title from root node of module only
                    // which we determing by checking for other system props
                    if ((node === null || node === void 0 ? void 0 : node.slug) && (node === null || node === void 0 ? void 0 : node.id) && (node === null || node === void 0 ? void 0 : node.locale)) {
                        delete node.title;
                    }
                }
            }
            if ("id" in node && "slug" in node) {
                if (node.type === "self-service-pages" && typeof node.id === "string") {
                    res.pages[node.id] = node;
                } else if (typeof node.id === "string") {
                    res.modules[node.id] = _objectSpreadProps(_objectSpread({}, node), {
                        nodeKeyInParent: nodeKeyInParent !== null && nodeKeyInParent !== void 0 ? nodeKeyInParent : null
                    });
                    if (parent && typeof nodeKeyInParent !== "undefined" && typeof nodeKeyInParent !== "object") {
                        parent[nodeKeyInParent] = node.id;
                    }
                }
            }
            if (Array.isArray(node)) {
                var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
                try {
                    for(var _iterator = node[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
                        var element = _step.value;
                        stack.push({
                            node: element,
                            parent: node,
                            nodeKeyInParent: node.indexOf(element)
                        });
                    }
                } catch (err) {
                    _didIteratorError = true;
                    _iteratorError = err;
                } finally{
                    try {
                        if (!_iteratorNormalCompletion && _iterator.return != null) {
                            _iterator.return();
                        }
                    } finally{
                        if (_didIteratorError) {
                            throw _iteratorError;
                        }
                    }
                }
            } else {
                for(var key1 in node){
                    stack.push({
                        node: node[key1],
                        parent: node,
                        nodeKeyInParent: key1
                    });
                }
            }
        }
    }
    return res;
};
var setModuleType = function(module1) {
    if (!(module1 === null || module1 === void 0 ? void 0 : module1.type)) {
        return;
    }
    module1.type = module1.type === "self-service-modules" || module1.type === "self-service-components" ? module1 === null || module1 === void 0 ? void 0 : module1.slug : module1.type;
};
// we revert normalization back, replacing ids with modules
var denormalizeCosmicJSON = function(data) {
    var stack = Object.values(data);
    while(stack.length > 0){
        var node = stack.pop();
        if (typeof node === "object" && node !== null) {
            if (Array.isArray(node)) {
                for(var i = 0; i < node.length; i++){
                    var _$module = typeof node[i] === "string" ? data === null || data === void 0 ? void 0 : data.modules[node[i]] : null;
                    if (_$module) {
                        setModuleType(_$module);
                        node[i] = _$module;
                        stack.push(node[i]);
                    } else if (node && typeof node[i] !== "string") {
                        stack.push(node[i]);
                    }
                    if (!node[i].type && typeof node[i] === "object" && node[i]) {
                        node[i].id = uuid();
                        if (node[i].fields) {
                            node[i].type = "fields";
                            node[i].slug = "fields";
                            node[i].metadata = {
                                fields: node[i].fields
                            };
                        }
                        if (node[i].text) {
                            node[i].type = "textblock";
                            node[i].slug = "textblock";
                            node[i].metadata = {
                                text: node[i].text
                            };
                        }
                    }
                }
            } else {
                for(var key in node){
                    var _$module1 = typeof node[key] === "string" ? data === null || data === void 0 ? void 0 : data.modules[node[key]] : null;
                    if (key !== "id" && _$module1) {
                        setModuleType(_$module1);
                        node[key] = _$module1;
                        if (key === "data") {
                            Object.assign(node, _$module1, node.config);
                            Object.assign(node, {
                                // this is hack, old hack, so we use any
                                metadata: _objectSpread({}, node === null || node === void 0 ? void 0 : node.metadata, node.config)
                            });
                            // very dirty hack, we need to remove this
                            // done for backcompability, as on nested level sometimes we read
                            // from metadata, sometimes from item direct
                            Object.assign(node, node.metadata);
                        }
                        stack.push(node[key]);
                    } else if (node && typeof node[key] !== "string") {
                        stack.push(node[key]);
                    }
                }
            }
        }
    }
    return data;
};
module.exports = {
    normalizeCosmicJSON: normalizeCosmicJSON,
    denormalizeCosmicJSON: denormalizeCosmicJSON
};
