import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import { getGlobalObject } from "../helpers/globalObject";
var MonitoredKeys = {
    location: true
};
var createSetItemProxy = function(setItemFn, emitEvent) {
    return new Proxy(setItemFn, {
        apply: function(target, thisArg, args) {
            var _args = _sliced_to_array(args, 2), key = _args[0], value = _args[1];
            if (MonitoredKeys[key]) {
                emitEvent({
                    type: "event:storage_set_item",
                    payload: {
                        key: key,
                        value: value
                    }
                });
            }
            return target.apply(thisArg, args);
        }
    });
};
var proxyHandler = function(emitEvent) {
    return {
        get: function get(target, prop) {
            if (typeof target[prop] !== "function") {
                return target[prop];
            }
            if (prop === "setItem") {
                var setItemFn = target[prop];
                return createSetItemProxy(setItemFn, emitEvent).bind(target);
            }
            return target[prop].bind(target);
        }
    };
};
var hydrateLocalStorage = function(localState) {
    var ref;
    var globalObject = getGlobalObject();
    var state;
    try {
        state = localState ? JSON.parse(localState) : [];
    } catch (_) {
        state = [];
    }
    (ref = state.forEach) === null || ref === void 0 ? void 0 : ref.call(state, function(param) {
        var key = param.key, value = param.value;
        return globalObject === null || globalObject === void 0 ? void 0 : globalObject.localStorage.setItem(key, value);
    });
};
export var setupStorage = function(emitEvent, localState) {
    var globalObject = getGlobalObject();
    Object.defineProperty(globalObject, "localStorage", {
        value: new Proxy(globalObject.localStorage, proxyHandler(emitEvent)),
        writable: true
    });
    hydrateLocalStorage(localState);
};
