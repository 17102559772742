import { useMediaQuery } from "@material-ui/core";
export function useIsMobileSmall() {
    return useMediaQuery(function(theme) {
        return theme.breakpoints.down(333);
    });
}
export function useIsMobile() {
    return useMediaQuery(function(theme) {
        return theme.breakpoints.down("xs");
    });
}
export function useIsMobileAndTablet() {
    return useMediaQuery(function(theme) {
        return theme.breakpoints.down("sm");
    });
}
export function useIsTablet() {
    return useMediaQuery(function(theme) {
        return theme.breakpoints.down(900);
    });
}
