import { useMemo } from "react";
import { useRemoteData } from "./useRemoteData";
var isActiveDeal = function(deal) {
    return [
        "ACTIVE",
        "CREATED"
    ].includes(deal.state);
};
// we use function as useRemoteData may pass some params for us
var dealsEndpoint = function() {
    return "portal/api/bff/deals";
};
export var useDeals = function() {
    var ref = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {
        shouldFetch: true
    }, _shouldFetch = ref.shouldFetch, shouldFetch = _shouldFetch === void 0 ? true : _shouldFetch;
    var ref1 = useRemoteData(shouldFetch ? "deals" : null, dealsEndpoint, []), data = ref1.data, status = ref1.status;
    return useMemo(function() {
        return {
            deals: data,
            dealsStatus: status,
            activeDeals: (data === null || data === void 0 ? void 0 : data.filter(isActiveDeal)) || []
        };
    }, [
        data,
        status
    ]);
};
