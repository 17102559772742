import pick from "lodash/pick";
import { TrackingEventName } from "../types";
var getPropertyKeys = function(event) {
    switch(event){
        case TrackingEventName.FORM_FIELD_ENTERED:
            {
                return [
                    "checkoutStep"
                ];
            }
        case TrackingEventName.COUPON_ENTERED:
        case TrackingEventName.COUPON_REMOVED:
        case TrackingEventName.COUPON_APPLIED:
        case TrackingEventName.COUPON_DENIED:
        case TrackingEventName.MODAL_OPENED:
        case TrackingEventName.MODAL_CLOSED:
        case TrackingEventName.ACCORDION_CLICKED:
        case TrackingEventName.CHECKOUT_STEP_VIEWED:
        case TrackingEventName.CHECKOUT_STEP_COMPLETED:
        case TrackingEventName.DELIVERY_DATE_SELECTED:
        case TrackingEventName.DELIVERY_DATE_SAVED:
        case TrackingEventName.LEAD_CREATED:
        case TrackingEventName.POSITIVE_LEAD:
        case TrackingEventName.CHECKOUT_LEAD:
        case TrackingEventName.CHECKOUT_STARTED:
        case TrackingEventName.PAYMENT_METHOD_SELECTED:
        case TrackingEventName.STRIPE_EXTERNAL_PROVIDER_REDIRECTED:
        case TrackingEventName.PAYMENT_INFO_ENTERED:
        case TrackingEventName.PAYMENT_PROCESSING_ERROR:
        case TrackingEventName.SWAP_OPTION_VIEWED:
        case TrackingEventName.SWAP_DATE_AUTO_SELECTED_FOR_RETENTION:
        case TrackingEventName.CTA_CLICKED:
            {
                return [
                    "checkoutStep",
                    "email",
                    "vehicleId",
                    "term",
                    "kilometerPackage",
                    "monthlyPrice",
                    "mileagePackageFee",
                    "additionalMileageFee",
                    "vehicleBrand",
                    "vehicleCartype",
                    "vehicleModel",
                    "originalAmount",
                    "amount",
                    "deliveryDate",
                    "totalPrice",
                    "customer_type",
                    "contactId",
                    "dealId",
                    "recurrentPaymentMethod",
                    "isApplicableForJobAuto",
                    "jobauto_id",
                    "jobauto_domain",
                    "directCheckout",
                    "skippedPaymentMethod",
                    "deposit",
                    "depositFactor",
                    "discount",
                    "dealInfo",
                    "paymentInfo",
                    "confirmationInfo",
                    "delivery_date",
                    "swapable_deal_id",
                    "swapable_deal_swap_date",
                    "downPaymentAmount",
                    "monthlyPriceWithoutDownpayment",
                    "source_flow",
                    "delivery_confidence_level", 
                ];
            }
        default:
            {
                return [];
            }
    }
};
export var getEventProperties = function(event, properties) {
    var propertyKeys = getPropertyKeys(event);
    return pick(properties, propertyKeys) || {};
};
