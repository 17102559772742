import { useEffect } from "react";
import { useAppStore } from "./store";
/** Sets `isReady` after hydration is over */ export function useSetAppReady() {
    var setReady = useAppStore().setReady;
    useEffect(function() {
        setReady();
    // Should fire only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}
/** Returns `true` if it's client side and the hydration is over */ export function useIsAppReady() {
    return useAppStore().isReady;
}
/** Returns the first value if the app is ready (hydration has finished) or a right one if not (e.g. SSR/SSG) */ export function useReadyValue(value, defaultValue) {
    var isReady = useAppStore().isReady;
    if (isReady) {
        return value;
    } else {
        return defaultValue;
    }
}
