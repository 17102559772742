import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@finn/design-system/atoms/select';
import { TrackingEventName, useTrackingStore } from '@finn/ua-tracking';
import { cn, OptionType, parseToHtml } from '@finn/ui-utils';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

type SelectFieldProps = {
  name: string;
  placeholder: string;
  options: OptionType<string | number>[];
  disabled?: boolean;
  className?: string;
};

export const LegacySelectField = ({
  name,
  placeholder,
  options,
  disabled,
  className,
  onChange,
}: SelectFieldProps & { onChange?: (value: string) => void }) => {
  const formik = useFormikContext<unknown>();
  const i18n = useIntl();

  const track = useTrackingStore((state) => state.track);
  const value = get(formik.values, name) || '';

  const errorMessage = get(formik.errors, name) as string;
  const touched = !!get(formik.touched, name);
  const error = touched && !!errorMessage;
  const localizedErrorMessage = error
    ? parseToHtml(i18n.formatMessage({ id: errorMessage }))
    : '';

  const handleChange = (newValue: string) => {
    formik.setFieldValue(name, newValue);
    formik.validateField(name);
    track(TrackingEventName.FORM_FIELD_ENTERED, {
      fieldName: name,
      once: true,
    });

    onChange?.(newValue);
  };

  return (
    <div>
      <Select value={value} onValueChange={handleChange}>
        <SelectTrigger
          disabled={disabled}
          error={Boolean(error)}
          className={cn('w-full', className)}
        >
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent>
          {options.map((option, index) => (
            <SelectItem key={index} value={String(option.value)}>
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      {error ? (
        <p className="body-12-regular text-red mt-1">{localizedErrorMessage}</p>
      ) : null}
    </div>
  );
};

export const SelectField = ({
  name,
  placeholder,
  options,
  disabled,
  className,
}: SelectFieldProps) => {
  const i18n = useIntl();
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const errorMessageId = !isEmpty(errors[name]) && errors[name].message;

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Select value={field.value} onValueChange={field.onChange}>
            <SelectTrigger
              disabled={disabled}
              error={Boolean(errorMessageId)}
              className={cn('w-full', className)}
            >
              <SelectValue placeholder={placeholder} />
            </SelectTrigger>
            <SelectContent>
              {options.map((option, index) => (
                <SelectItem key={index} value={String(option.value)}>
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        )}
      />
      {errorMessageId ? (
        <p className="body-16-regular text-red mt-1">
          {i18n.formatMessage({
            id: errorMessageId,
          })}
        </p>
      ) : null}
    </>
  );
};
